import * as React from "react";
import {
    Box,
    Button,
    Checkbox,
    Fab,
    FormControl,
    FormControlLabel,
    FormGroup,
    InputLabel,
    OutlinedInput
} from "@mui/material";
import Stack from "@mui/material/Stack";
import SaveIcon from "@mui/icons-material/Save";
import {SliderPicker, TwitterPicker} from "react-color";
import basicColors from "../../colors";
import {styled} from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import FormLabel from "@mui/material/FormLabel";
import {useParams} from "react-router-dom";
import {useCookies} from "react-cookie";
import AxiosGet from "../../AxiosGet";
import hasRole from "../../ContextMenu/hasRole";
import AxiosPut from "../../AxiosPut";
import useAppNavigate from "../../App/useAppNavigate";

const StyledInput = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    borderColor: theme.palette.grey[400],
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
    '&:hover': {
        borderColor: theme.palette.grey[800],
    }
}));

export default function Edit() {
    const { identifier, playerIdentifier } = useParams();
    const [cookies, setCookie, removeCookie] = useCookies(['accessToken']);
    const [profile] = React.useState(JSON.parse(localStorage.getItem('profile')));
    const [league, setLeague] = React.useState({});
    const [players, setPlayers] = React.useState([]);
    const [player, setPlayer] = React.useState({});
    const [isConnected, setIsConnected] = React.useState(false);
    const [name, setName] = React.useState('');
    const [nameError, setNameError] = React.useState(false);
    const [color, setColor] = React.useState('');
    const [roles, setRoles] = React.useState({ROLE_USER: true, ROLE_ADMIN: false, ROLE_GAME_MODER: false});
    const navigate = useAppNavigate();

    const fetchLeague = () => {
        AxiosGet('/leagues/' + identifier, cookies.accessToken).then((response) => {
            setLeague(response.data);
            setPlayers(response.data.players);
            let playerToEdit = response.data.players.find((player) => player.identifier === playerIdentifier);
            if (playerToEdit === undefined || playerToEdit.active !== true) {
                navigate('/leagues/' + identifier + '/players');

                return;
            }

            setName(playerToEdit.name);
            setColor(playerToEdit.hexColor);
            setIsConnected(playerToEdit.mateIdentifier !== null);

            let processedRoles = roles;
            if (playerToEdit.mateIdentifier !== null) {
                let playerMate = response.data.mates.find((mate) => mate.identifier === playerToEdit.mateIdentifier);
                playerMate.roles.map((role) => {
                    processedRoles = {...processedRoles, [role]: true}
                })
            }

            setRoles(processedRoles);
            window.dispatchEvent(new Event('contentLoaded'));
        }).catch((error) => {
            if (error.response.status === 401) {
                removeCookie('accessToken', {path: '/'});
            }
        });
    }
    React.useEffect(() => {
        fetchLeague();
    }, [identifier])

    const handleNameChange = (event) => {
        setName(event.target.value);
    }

    const handleColorChange = (color) => {
        setColor(color.hex);
    }

    const handleRolesChange = (event) => {
        event.preventDefault();
        setRoles({...roles, [event.target.value]: !roles[event.target.value]});
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (name === '') {
            setNameError(true);

            return;
        }

        let payload;
        payload = {
            leagueIdentifier: identifier,
            name: name,
            color: color,
            roles: Object.entries(roles).filter((role) => role[1] === true).map((role) => role[0])
        }
        let response;
        window.dispatchEvent(new Event('contentLoading'));
        response = await AxiosPut('/players/' + playerIdentifier, cookies.accessToken, payload)
            .catch((error) => {
                if (error.response.status === 401) {
                    removeCookie('accessToken', {path: '/'});
                }
            });
        if (response.status === 200) {
            navigate('/leagues/' + identifier + '/players');
        }
    }

    return <Box>
        <h1>Edytuj gracza</h1>
        <form onSubmit={handleSubmit}>
            <Stack spacing={2}>
                <FormControl fullWidth error={nameError}>
                    <InputLabel htmlFor="outlined-adornment-amount">Nazwa gracza</InputLabel>
                    <OutlinedInput
                        id={'outlined-adornment-amount'}
                        value={name}
                        label={'Nazwa gracza'}
                        onChange={handleNameChange}
                    />
                </FormControl>
                <StyledInput elevation={0} variant={'outlined'}>
                    <TwitterPicker
                        color={color}
                        width={'100%'}
                        onChangeComplete={handleColorChange}
                        triangle={'hide'}
                        colors={basicColors()}
                        styles={{'default': {
                            card: {boxShadow: '0 0 0 0px #F0F0F0',},
                            body: {padding: '0px 0px 9px 0px'},
                        }}}
                    />
                    <SliderPicker
                        color={color}
                        onChange={handleColorChange}
                        onChangeComplete={handleColorChange}
                    />
                </StyledInput>
                { isConnected && hasRole(profile.userIdentifier, league.mates, 'ROLE_ADMIN') ?
                    <FormGroup>
                        <FormLabel component={'legend'}>Uprawnienia</FormLabel>
                        <FormControlLabel
                            control={<Checkbox />}
                            label={'Administrator'}
                            value={'ROLE_ADMIN'}
                            checked={roles.ROLE_ADMIN}
                            onChange={handleRolesChange}
                        />
                        <FormControlLabel
                            control={<Checkbox />}
                            label={'Moderator gier'}
                            value={'ROLE_GAME_MODER'}
                            checked={roles.ROLE_GAME_MODER}
                            onChange={handleRolesChange}
                        />
                        <FormControlLabel
                            disabled
                            control={<Checkbox defaultChecked={roles.ROLE_USER} />}
                            label={'Gracz'}
                            value={'ROLE_USER'}
                        />
                    </FormGroup> : ''
                }
                <Button
                    type={'submit'}
                    variant={'outlined'}
                    sx={{display: { xs: 'none', sm: 'none', md: 'flex' },}}
                    size={'large'}
                    startIcon={<SaveIcon />}
                >
                    Zapisz gracza
                </Button>
            </Stack>
            <Fab type={'submit'} color={'primary'} aria-label={'add'} sx={{
                margin: 0,
                top: 'auto',
                right: 20,
                bottom: 80,
                left: 'auto',
                position: 'fixed',
                display: { sm: 'flex', md: 'none' },
            }}>
                <SaveIcon />
            </Fab>
        </form>
    </Box>
}