import * as React from "react";
import {Divider as BaseDivider, Typography, useTheme} from "@mui/material";

export default function Divider(props) {
    const theme = useTheme();

    return <BaseDivider sx={{mt: 3, color: theme.palette.grey[500]}}>
        <Typography variant={'caption'} display={'inline'} sx={{color: theme.palette.grey[700]}}>
        {props.children}
        </Typography>
    </BaseDivider>
}