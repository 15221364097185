import * as React from "react";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import {Button, OutlinedInput, Typography} from "@mui/material";
import {useParams} from "react-router-dom";
import {useCookies} from "react-cookie";
import AxiosPost from "../AxiosPost";
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Header from "./GetOne/Header";

export default function Invite() {
    let { identifier } = useParams();
    const [cookies, setCookie, removeCookie] = useCookies(['accessToken']);
    const [expiresAt, setExpiresAt] = React.useState(3);
    const [generatedLink, setGeneratedLink] = React.useState('');
    const [isGenerated, setIsGenerated] = React.useState(false);
    const [league] = React.useState(
        JSON.parse(localStorage.getItem('leagues')).find((league) => league.identifier === identifier)
    );
    const [activeSeason, setActiveSeason] = React.useState(
        league.seasons.find((season) => season.active === true)?.identifier ?? ''
    );

    const handleExpiresAtChange = (event) => {
        setExpiresAt(event.target.value);
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        let date = new Date();
        switch (expiresAt) {
            case 1:
                date.setHours(date.getHours() + 6);
                break;
            case 2:
                date.setHours(date.getHours() + 12);
                break;
            case 3:
                date.setDate(date.getDate() + 1);
                break;
            case 4:
                date.setDate(date.getDate() + 7);
                break;
            case 5:
                date.setDate(date.getDate() + 30);
                break;
        }

        let dateString = date.toISOString().split('T')[0] + ' ' + date.toISOString().split('T')[1].substring(0,8);

        let payload = {
            type: 'LEAGUE',
            targetIdentifier: identifier,
            expiresAt: dateString,
        }

        let response = await AxiosPost('/invite', cookies.accessToken, payload).catch((error) => {
            if (error.response.status === 401) {
                removeCookie('accessToken', {path: '/'});
            }
        });
        if (response.status === 200) {
            setIsGenerated(true);
            setGeneratedLink(window.location.origin + '/invite/' + response.data.inviteString);
        }
    }

    const copyLink = () => {
        navigator.clipboard.writeText(generatedLink);
    }

    React.useEffect(() => {
        window.dispatchEvent(new Event('contentLoaded'));
    })

    return <Box sx={{position: 'relative'}}>
        <Header identifier={identifier} league={league} activeSeason={activeSeason} setActiveSeason={setActiveSeason} />
        <Typography sx={{fontSize: '1.25rem', fontWeight: 'bold'}}>Zaproś graczy</Typography>
        { isGenerated ?
            <>
                <Stack spacing={2}>
                    <FormControl fullWidth>
                        <InputLabel>Link</InputLabel>
                        <OutlinedInput
                            id="invite-link"
                            label="Link"
                            value={generatedLink}
                        />
                    </FormControl>
                    <Button
                        variant={'outlined'}
                        size={'large'}
                        startIcon={<ContentCopyIcon />}
                        onClick={copyLink}
                    >Kopiuj</Button>
                </Stack>
            </>
            :
            <>
                <h4>Wygeneruj link z zaproszeniem do ligi.</h4>
                <form onSubmit={handleSubmit}>
                    <Stack spacing={2}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Wygaśnie po</InputLabel>
                            <Select
                                labelId={'demo-simple-select-label'}
                                id={'demo-simple-select'}
                                label={'Wygaśnie po'}
                                value={expiresAt}
                                onChange={handleExpiresAtChange}
                            >
                                <MenuItem value={1}>6 godzin</MenuItem>
                                <MenuItem value={2}>12 godzin</MenuItem>
                                <MenuItem value={3}>1 dzień</MenuItem>
                                <MenuItem value={4}>7 dni</MenuItem>
                                <MenuItem value={5}>30 dni</MenuItem>
                            </Select>
                        </FormControl>
                        <Button
                            type={'submit'}
                            variant={'outlined'}
                            size={'large'}
                            startIcon={<GroupAddIcon />}
                        >Wygeneruj</Button>
                    </Stack>
                </form>
            </>
        }

    </Box>
}