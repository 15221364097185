import * as React from 'react';
import Stack from "@mui/material/Stack";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import {Button, Fab, Typography} from "@mui/material";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import Person4Icon from "@mui/icons-material/Person4";
import FormLabel from "@mui/material/FormLabel";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import Attributes from "./Attributes";
import SaveIcon from "@mui/icons-material/Save";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import AxiosPost from "../../AxiosPost";
import AxiosGet from "../../AxiosGet";
import {useCookies} from "react-cookie";

export default function Duel(props) {
    const [cookies, setCookie, removeCookie] = useCookies(['accessToken']);
    const [profile] = React.useState(JSON.parse(localStorage.getItem('profile')));
    const [simulationPlayerOne, setSimulationPlayerOne] = React.useState([]);
    const [simulationPlayerTwo, setSimulationPlayerTwo] = React.useState([]);
    const [playerOne, setPlayerOne] = React.useState('');
    const [playerOneError, setPlayerOneError] = React.useState(false);
    const [playerTwo, setPlayerTwo] = React.useState('');
    const [playerTwoError, setPlayerTwoError] = React.useState(false);
    const [result, setResult] = React.useState('');
    const [resultError, setResultError] = React.useState(false);
    const [attributesPayload, setAttributesPayload] = React.useState([]);
    const [attributesErrors, setAttributesErrors] = React.useState([]);

    const handlePlayerOneChange = (event) => {
        setPlayerOne(event.target.value);
        setPlayerOneError(false);
        setSimulationPlayerOne('');
        setSimulationPlayerTwo('');
    };

    const handlePlayerTwoChange = (event) => {
        setPlayerTwo(event.target.value);
        setPlayerTwoError(false);
        setSimulationPlayerOne('');
        setSimulationPlayerTwo('');
    };

    const handleWinnerChange = (event) => {
        setResult(event.target.value);
        setResultError(false);
    };

    const mapPlayers = (selectedPlayer) => {
        if (props.league.players !== undefined && props.league.players.length > 0) {
            return props.league.players.sort((a, b) => {
                const nameA = a.name.toUpperCase();
                const nameB = b.name.toUpperCase();
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }

                return 0;
            }).filter((player) => player.active === true)
                .filter((player) => player.identifier !== selectedPlayer)
                .map((player) => {
                    return <MenuItem value={player.identifier} key={player.identifier}>{player.name}</MenuItem>;
                })
        }

        return [<MenuItem value={'Brak-graczy'} key={'brak-graczy'}>Brak graczy</MenuItem>]
    }

    const assignMe = () => {
        if (!isAssignable()) {
            return;
        }

        let mate = props.league.mates.find((mate) => mate.userIdentifier === profile.userIdentifier);
        let player = props.league.players.find(
            (player) => player.mateIdentifier === mate.identifier
        );

        setPlayerOne(player.identifier);
    }

    const isAssignable = () => {
        if (props.league.mates === undefined) {
            return false;
        }

        let mate = props.league.mates.find((mate) => mate.userIdentifier === profile.userIdentifier);
        if (mate === undefined) {
            return false;
        }

        return props.league.players.find(
            (player) => player.mateIdentifier === mate.identifier
        ) !== undefined;
    }

    let mappedPlayersOne;
    mappedPlayersOne = mapPlayers(playerTwo);
    let mappedPlayersTwo;
    mappedPlayersTwo = mapPlayers(playerOne);

    React.useEffect(() => {
        if (playerOne !== '' && playerTwo !== '' && playerOne !== playerTwo) {
            AxiosGet(
                '/games/simulate',
                cookies.accessToken,
                {
                    leagueIdentifier: props.league.identifier,
                    seasonIdentifier: props.selectedSeason,
                    playerOneIdentifier: playerOne,
                    playerTwoIdentifier: playerTwo,
                }
            )
                .then((response) => {
                    if (props.league.tieable) {
                        setSimulationPlayerOne(<div>
                            <Typography sx={{ml: 1}} display={'inline'} variant={'caption'}>Predykcje:</Typography>
                            <Typography sx={{color: 'success.main', ml: 1}} display={'inline'} variant={'caption'}>Wygrana: {response.data.playerOne.win}</Typography>
                            <Typography sx={{color: 'info.main', ml: 1}} display={'inline'} variant={'caption'}>Remis: {response.data.playerOne.tie}</Typography>
                            <Typography sx={{color: 'error.main', ml: 1}} display={'inline'} variant={'caption'}>Przegrana: {response.data.playerOne.lose}</Typography>
                        </div>);
                        setSimulationPlayerTwo(<div>
                            <Typography sx={{ml: 1}} display={'inline'} variant={'caption'}>Predykcje:</Typography>
                            <Typography sx={{color: 'success.main', ml: 1}} display={'inline'} variant={'caption'}>Wygrana: {response.data.playerTwo.win}</Typography>
                            <Typography sx={{color: 'info.main', ml: 1}} display={'inline'} variant={'caption'}>Remis: {response.data.playerTwo.tie}</Typography>
                            <Typography sx={{color: 'error.main', ml: 1}} display={'inline'} variant={'caption'}>Przegrana: {response.data.playerTwo.lose}</Typography>
                        </div>);

                        return;
                    }

                    setSimulationPlayerOne(<div>
                        <Typography sx={{ml: 1}} display={'inline'} variant={'caption'}>Predykcje:</Typography>
                        <Typography sx={{color: 'success.main', ml: 1}} display={'inline'} variant={'caption'}>Wygrana: {response.data.playerOne.win}</Typography>
                        <Typography sx={{color: 'error.main', ml: 1}} display={'inline'} variant={'caption'}>Przegrana: {response.data.playerOne.lose}</Typography>
                    </div>);
                    setSimulationPlayerTwo(<div>
                        <Typography sx={{ml: 1}} display={'inline'} variant={'caption'}>Predykcje:</Typography>
                        <Typography sx={{color: 'success.main', ml: 1}} display={'inline'} variant={'caption'}>Wygrana: {response.data.playerTwo.win}</Typography>
                        <Typography sx={{color: 'error.main', ml: 1}} display={'inline'} variant={'caption'}>Przegrana: {response.data.playerTwo.lose}</Typography>
                    </div>);
                });
        }
    }, [playerOne, playerTwo]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (playerOne === '') {
            setPlayerOneError(true);
        }
        if (playerOne !== '') {
            setPlayerOneError(false);
        }
        if (playerTwo === '') {
            setPlayerTwoError(true);
        }
        if (playerTwo !== '') {
            setPlayerTwoError(false);
        }
        if (playerOne === playerTwo) {
            setPlayerOneError(true);
            setPlayerTwoError(true);
        }
        if (result === '') {
            setResultError(true);
        }

        attributesPayload.forEach(attribute => {
            let newAttributesErrors = [];
            if (attribute.values.length === 0) {
                let newAttributeError = attributesErrors.find(attributeError => attributeError.identifier === attribute.identifier);
                newAttributeError.error = true;
                newAttributesErrors = attributesErrors.filter(attributeError => attributeError.identifier !== attribute.identifier);
                newAttributesErrors.push(newAttributeError);
            } else {
                let newAttributeError = attributesErrors.find(attributeError => attributeError.identifier === attribute.identifier);
                newAttributeError.error = false;
                newAttributesErrors = attributesErrors.filter(attributeError => attributeError.identifier !== attribute.identifier);
                newAttributesErrors.push(newAttributeError);
            }

            setAttributesErrors(newAttributesErrors);
        });

        if (playerOne === '' || playerTwo === '' || playerOne === playerTwo || result === '' || attributesErrors.filter(attributeError => attributeError.error === true).length > 0) {
            return;
        }

        let payload = {
            leagueIdentifier: props.league.identifier,
            seasonIdentifier: props.selectedSeason,
            players: [
                {
                    identifier: playerOne,
                    place: 1,
                },
                {
                    identifier: playerTwo,
                    place: 2,
                },
            ],
            result: result,
            type: 'DUEL',
            leagueAttributes: attributesPayload,
        };

        let response;
        response = await AxiosPost('/games', cookies.accessToken, payload);
        if (response.data.status === 'ok') {
            props.setSuccess(props.divRef.current)
            setPlayerOne('');
            setPlayerTwo('');
            setResult('');
            let emptyAttributesPayload = attributesPayload.map(attribute => {
                return {
                    identifier: attribute.identifier,
                    values: [],
                }
            })

            setAttributesPayload(emptyAttributesPayload);
        }
    };

    React.useEffect(() => {
        setPlayerOne('');
        setPlayerTwo('');
    }, [props.selectedLeague])

    return <form onSubmit={handleSubmit}>
        <Stack spacing={2} mt={2}>
            <div style={{display: 'flex'}}>
                <FormControl fullWidth error={playerOneError}>
                    <InputLabel id="demo-simple-select-label3">Gracz 1</InputLabel>
                    <Select
                        labelId="demo-simple-select-label3"
                        id="demo-simple-select3"
                        value={playerOne}
                        label="Gracz 1"
                        onChange={handlePlayerOneChange}
                    >
                        {mappedPlayersOne}
                    </Select>
                </FormControl>
                {
                    isAssignable() ?
                        <Button variant={'contained'} sx={{ml: 1}} onClick={() => assignMe()}>
                            <KeyboardArrowLeftIcon  sx={{mr: -1}} /><Person4Icon />
                        </Button> :
                        ''
                }
            </div>
        </Stack>
        {playerOne !== '' && playerTwo !== '' ? simulationPlayerOne : ''}
        <Stack spacing={2}>
            <FormControl fullWidth error={playerTwoError} sx={{mt: 2}}>
                <InputLabel id="demo-simple-select-label">Gracz 2</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={playerTwo}
                    label="Gracz 2"
                    onChange={handlePlayerTwoChange}
                >
                    {mappedPlayersTwo}
                </Select>
            </FormControl>
        </Stack>
        {playerOne !== '' && playerTwo !== '' ? simulationPlayerTwo : ''}
        <Stack spacing={2}>
            <FormControl error={resultError} sx={{mt: 2}}>
                <FormLabel id="demo-row-radio-buttons-group-label">Wygrał</FormLabel>
                <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={result}
                    onChange={handleWinnerChange}
                >
                    <FormControlLabel value="PLAYER_ONE" control={<Radio />} label="Gracz 1" />
                    <FormControlLabel value="PLAYER_TWO" control={<Radio />} label="Gracz 2" />
                    {props.league.tieable ? <FormControlLabel value="TIE" control={<Radio />} label="Remis" /> : ''}
                </RadioGroup>
            </FormControl>
            <Attributes
                selectedLeague={props.league.identifier}
                attributes={props.league.attributes}
                attributesPayload={attributesPayload}
                setAttributesPayload={setAttributesPayload}
                attributesErrors={attributesErrors}
                setAttributesErrors={setAttributesErrors}
            />
            <Button
                type={'submit'}
                variant={'outlined'}
                sx={{display: { xs: 'none', sm: 'none', md: 'flex' },}}
                size={'large'}
                disabled={props.selectedSeason === ''}
                startIcon={<SaveIcon />}
            >Dodaj grę</Button>
        </Stack>
        <Box sx={{ '& > :not(style)': { m: 1 } }}>
            <Fab color="primary" aria-label="add" sx={{
                margin: 0,
                top: 'auto',
                right: 20,
                bottom: 80,
                left: 'auto',
                position: 'fixed',
                display: { sm: 'flex', md: 'none' },
            }} variant="outlined" type="submit" disabled={props.selectedSeason === ''}>
                <SaveIcon />
            </Fab>
            <Fab size="medium" color="primary" aria-label="add" sx={{
                margin: 0,
                top: 'auto',
                right: 20,
                bottom: 160,
                left: 'auto',
                position: 'fixed',
                display: { sm: 'flex', md: 'none' },
            }} variant="outlined" disabled={props.selectedSeason === ''}>
                <QrCodeScannerIcon />
            </Fab>
        </Box>
    </form>
}