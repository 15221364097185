import * as React from "react";
import {Box, ButtonBase, Tooltip, Typography, useTheme} from "@mui/material";
import {alpha} from "@mui/material/styles";
import Collapse from '@mui/material/Collapse';
import moment from "moment";
import 'moment-timezone';

export default function BaseElement(props) {
    const theme = useTheme();
    const timestamp = moment(props.timestamp, 'YYYY-MM-DD HH:mm:ss').utc(true).tz(Intl.DateTimeFormat().resolvedOptions().timeZone);

    return <ButtonBase
        component={Box}
        sx={{
            display: 'block',
            mt: 0.5,
            mb: 0.5,
            p: 0.5,
            clipPath: 'inset(0 -100vmax)',
            ...(props.isSeen === false && {
                backgroundColor: alpha(theme.palette.primary.main, 0.1),
                boxShadow: '0 0 0 100vmax ' + alpha(theme.palette.primary.main, 0.1),
            }),
            cursor: 'default'
        }}
    >
        <Collapse in={true} timeout={'auto'} appear={!props.isSeen}>
            <Box sx={{display: 'flex', alignItems: 'center'}} alignItems={'bottom'}>
                <Tooltip title={timestamp.format('HH:mm:ss HH.MM.YYYY')} placement={'top'} enterDelay={500} arrow>
                    <Typography variant={'caption'} display={'inline'} sx={{color: theme.palette.grey[700], marginRight: 1, lineHeight: 1}}>
                        {timestamp.format('HH:mm:ss')}
                    </Typography>
                </Tooltip>
                <Typography variant={'inherit'} display={'inline'} sx={{color: theme.palette.primary.main, marginRight: 1}}>
                    {props.name}
                </Typography>
                {props.icon}
            </Box>
            <Typography
                variant={'subtitle1'}
                gutterBottom={props.title !== undefined}
                sx={{fontWeight: 'medium'}}
            >
                {props.title}
            </Typography>
            <Typography variant={'body1'} component={Box}>
                {props.children}
            </Typography>
        </Collapse>
    </ButtonBase>
}