import * as React from 'react';
import {Line} from "react-chartjs-2";
import {Box, Slider} from "@mui/material";
import convertHex from "../../../convertHex";
import moment from "moment";

export default function Daily(props) {
    const [profile] = React.useState(JSON.parse(localStorage.getItem('profile')));
    const [dailyChartRange, setDailyChartRange] = React.useState([0, 10]);
    const [dailyChartRangeMax, setDailyChartRangeMax] = React.useState(0);
    const [dailyChartRangeMin, setDailyChartRangeMin] = React.useState(10);
    const [playersIdentifiers, setPlayersIdentifiers] = React.useState([]);
    const [dailyPoints, setDailyPoints] = React.useState([]);
    const [dailyChartDataset, setDailyChartDataset] = React.useState([]);
    const [dailyLabels, setDailyLabels] = React.useState([]);
    const [dailyLabelsFormatted, setDailyLabelsFormatted] = React.useState([]);
    const [marks, setMarks] = React.useState([]);
    const minDistance = 3;

    const handleChange = (event, newValue, activeThumb) => {
        if (!Array.isArray(newValue)) {
            return;
        }

        if (activeThumb === 0) {
            setDailyChartRange([Math.min(newValue[0], dailyChartRange[1] - minDistance), dailyChartRange[1]]);
        } else {
            setDailyChartRange([dailyChartRange[0], Math.max(newValue[1], dailyChartRange[0] + minDistance)]);
        }
    };

    React.useEffect(() => {
        if (props.seasonChartStatistics.daily === undefined) {
            return;
        }

        setPlayersIdentifiers([...new Set(props.seasonChartStatistics.games.map(item => item.playerIdentifier))])
        setDailyPoints([...new Set(props.seasonChartStatistics.daily.map(item => item.points))]);
        setDailyChartDataset(playersIdentifiers.map(playerIdentifier => {
            let filteredData = props.seasonChartStatistics.daily.filter(seasonChartStatistic => seasonChartStatistic.playerIdentifier === playerIdentifier);
            let filteredPlayer = props.league.players.find(player => player.identifier === playerIdentifier);
            if (filteredPlayer === undefined || filteredPlayer.active === false) {
                return false;
            }

            let filteredMate = props.league.mates.find(mate => mate.identifier === filteredPlayer.mateIdentifier);

            return {
                label: filteredPlayer.name,
                data: filteredData.map(x => x.points).slice(dailyChartRange[0]),
                borderColor: convertHex(filteredPlayer.hexColor),
                backgroundColor: convertHex(filteredPlayer.hexColor, 0.5),
                borderWidth: filteredMate !== undefined && filteredMate.userIdentifier === profile.userIdentifier ? 4 : 2,
                pointRadius: function (context) {
                    let equalsPrevValue = false;
                    let equalsNextValue = false;
                    var index = context.dataIndex;
                    var value = context.dataset.data[index];
                    if (index > 0 && filteredData[index-1].points === value) {
                        equalsPrevValue = true;
                    }
                    if (index+1 < filteredData.length && filteredData[index+1].points === value) {
                        equalsNextValue = true;
                    }
                    return equalsPrevValue && equalsNextValue ? 0 :
                        filteredMate !== undefined && filteredMate.userIdentifier === profile.userIdentifier ? 3 : 2;
                },
                pointHoverRadius: 6,
            }
        }).filter((rankingRow) => rankingRow !== false))
        setDailyLabels([...new Set(props.seasonChartStatistics.daily.map(item => item.createdAt))])
    }, [props.league, props.seasonChartStatistics, dailyChartRange]);

    let dailyOptions = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: false,
                text: '',
            },
        },
        scales: {
            y: {
                display: true,
                min: 900,
                max: 1100
            },
            x: {
                ticks: {
                    maxRotation: 0,
                    minRotation: 0
                }
            }
        },
        maintainAspectRatio: false,
    };

    dailyOptions.scales.y.max = dailyPoints.length > 0 ? Math.ceil(Math.max(...dailyPoints)/10) * 10: 1100;
    dailyOptions.scales.y.min = dailyPoints.length > 0 ? Math.floor(Math.min(...dailyPoints)/10) * 10: 900;

    React.useEffect(() => {
        if (dailyLabels.length > 0 && dailyChartRangeMax !== dailyLabels.length - 1) {
            setMarks(dailyLabels.map((dailyLabel, index) => {
                return {
                    value: index,
                    label: dailyLabel,
                }
            }));
            setDailyChartRangeMin(0);
            setDailyChartRangeMax(dailyLabels.length - 1);
            setDailyChartRange([0, dailyLabels.length - 1]);
        }

        if (dailyLabels.every((dailyLabel, index, dailyLabels) => dailyLabel.substring(0, 4) === dailyLabels[0].substring(0, 4))) {
            setDailyLabelsFormatted(dailyLabels.map((dailyLabel) =>
                moment(dailyLabel, 'YYYY-MM-DD')
                    .utc(true)
                    .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
                    .format('DD.MM')
            ))
        } else {
            setDailyLabelsFormatted(dailyLabels.map((dailyLabel) =>
                moment(dailyLabel, 'YYYY-MM-DD')
                    .utc(true)
                    .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
                    .format('DD.MM.YYYY')
            ))
        }
    }, [dailyLabels])

    let dailyData = {
        labels: dailyLabelsFormatted.slice(dailyChartRange[0], dailyChartRange[1] + 1),
        datasets: dailyChartDataset,
    };

    function valueLabelFormat(value) {
        let mark = marks.find(mark => mark.value === value);
        if (mark === undefined) {
            return '';
        }

        return moment(mark.label, 'YYYY-MM-DD')
            .utc(true)
            .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
            .format('DD.MM.YYYY');
    }

    return <Box>
        <Box style={{minHeight: '400px'}}>
            <Line options={dailyOptions} data={dailyData} />
        </Box>
        <Box ml={5} mr={5}>
            <Slider
                value={dailyChartRange}
                onChange={handleChange}
                valueLabelDisplay={'auto'}
                getAriaValueText={valueLabelFormat}
                valueLabelFormat={valueLabelFormat}
                disableSwap
                min={dailyChartRangeMin}
                max={dailyChartRangeMax}
                disabled={dailyChartRangeMax < minDistance}
            />
        </Box>
    </Box>
}