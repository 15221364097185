import * as React from "react";
import Box from "@mui/material/Box";
import {DataGrid, gridClasses} from "@mui/x-data-grid";
import {
    Alert, AlertTitle,
    Button,
    Popover, Typography, useTheme
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AxiosDelete from "../../AxiosDelete";
import {useCookies} from "react-cookie";
import ConfirmationDialog from "../../App/ConfirmationDialog";
import hasRole from "../../ContextMenu/hasRole";
import {alpha} from "@mui/material/styles";
import moment from "moment/moment";
import Identicon from "../Identicon";

export default function GamesHistory(props) {
    const [profile] = React.useState(JSON.parse(localStorage.getItem('profile')));
    const [cookies, setCookie, removeCookie] = useCookies(['accessToken']);
    const [gameToDelete, setGameToDelete] = React.useState('');
    const [deleteAttempt, setDeleteAttempt] = React.useState(false);
    const [deleteLoading, setDeleteLoading] = React.useState(false);
    const [success, setSuccess] = React.useState(null);
    const divRef = React.useRef();
    const theme = useTheme();

    const handleDelete = (identifier) => {
        setGameToDelete(gamesHistoryRows.find((game) => game.identifier === identifier));
        setDeleteAttempt(true);
    }

    const gamesColumns = [
        { field: 'id', headerName: '', width: 50, hide: true, align: 'center'},
        { field: 'playerOneName', headerName: 'Gracz 1', minWidth: 100, flex: 1, hideable: false, renderCell: (params) =>
                <Identicon imagesDir={params.row.playerOneImagesDir} width={16} height={16} mr={0.5}>{params.row.playerOneName}</Identicon>
        },
        { field: 'playerTwoName', headerName: 'Gracz 2', minWidth: 100, flex: 1, hideable: false, renderCell: (params) =>
                <Identicon imagesDir={params.row.playerTwoImagesDir} width={16} height={16} mr={0.5}>{params.row.playerTwoName}</Identicon>
        },
        { field: 'scoreChange', headerName: 'Punkty', width: 70, hideable: false, align: 'center'},
        { field: 'createdAt', headerName: 'Data', width: 155, hideable: false},
    ];

    if (hasRole(profile.userIdentifier, props.league.mates, 'ROLE_ADMIN') && props.currentSeasonData !== null && props.currentSeasonData.active === true) {
        gamesColumns.push({ field: 'actions', headerName: 'Akcje', width: 70, hideable: false, align: 'center', renderCell: (params) =>
            <Button
                color={'primary'}
                aria-label={'delete game'}
                component={'label'}
                onClick={() => handleDelete(params.row.identifier)}
            >
                <DeleteIcon />
            </Button>
        });
    }

    let gamesHistoryRows = [];
    if (props.currentSeasonData !== null && props.currentSeasonData.games.length > 0) {
        gamesHistoryRows = props.currentSeasonData.games.sort(function(a, b) {
            var dateA = new Date(a.createdAt),
                dateB = new Date(b.createdAt);
            if (dateA < dateB) {
                return -1;
            }

            if (dateA > dateB) {
                return 1;
            }

            return 0;
        }).map((game, index) => {
            let filteredPlayerOne = props.league.players.find((player) => {
                return player.identifier === game.playerOneIdentifier;
            });

            if (filteredPlayerOne === undefined) {
                return false;
            }
            let filteredPlayerOneMate = props.league.mates.find(mate => mate.identifier === filteredPlayerOne.mateIdentifier);

            let filteredPlayerTwo = props.league.players.find((player) => {
                return player.identifier === game.playerTwoIdentifier;
            });

            if (filteredPlayerTwo === undefined) {
                return false;
            }
            let filteredPlayerTwoMate = props.league.mates.find(mate => mate.identifier === filteredPlayerTwo.mateIdentifier);

            return {
                id: index+1,
                identifier: game.identifier,
                playerOneIdentifier: game.playerOneIdentifier,
                profileOneIdentifier: filteredPlayerOneMate !== undefined ? filteredPlayerOneMate.userIdentifier : null,
                playerOneName: filteredPlayerOne.name,
                playerOneImagesDir: filteredPlayerOne.imagesDir,
                playerTwoIdentifier: game.playerTwoIdentifier,
                profileTwoIdentifier: filteredPlayerTwoMate !== undefined ? filteredPlayerTwoMate.userIdentifier : null,
                playerTwoName: filteredPlayerTwo.name,
                playerTwoImagesDir: filteredPlayerTwo.imagesDir,
                result: game.result,
                scoreChange: game.scoreChange,
                createdAt: moment(game.createdAt, 'YYYY-MM-DD HH:mm:ss')
                    .utc(true)
                    .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
                    .format('HH:mm DD.MM.YYYY'),
            }
        }).filter((rankingRow) => rankingRow !== false);
    }

    const handleClose = () => {
        setDeleteAttempt(false);
    }

    const handleContinueDeleting = async (event) => {
        event.preventDefault();
        setDeleteLoading(true);
        let response;
        response = await AxiosDelete('/games/' + event.target.value, cookies.accessToken).catch((error) => {
            if (error.response.status === 401) {
                removeCookie('accessToken', {path: '/'});
            }

            setDeleteLoading(false);
        });

        if (response.status === 204) {
            props.fetchLeague();
            setDeleteAttempt(false);
            setDeleteLoading(false);
            setSuccess(divRef.current);
        }
    }

    return <Box mb={1} ref={divRef}>
        <Typography sx={{fontSize: '1.25rem', fontWeight: 'bold'}}>Historia gier</Typography>
        <ConfirmationDialog
            open={deleteAttempt}
            onClose={handleClose}
            title={'Usuwanie gry'}
            content={'Czy napewno usunąć grę numer ' + gameToDelete.id + ', ' + gameToDelete.playerOneName + ' vs ' + gameToDelete.playerTwoName + '?'}
            loading={deleteLoading}
            onConfirm={handleContinueDeleting}
            value={gameToDelete.identifier}
        />
        <Popover
            anchorEl={success}
            anchorOrigin={{
                vertical: 'center',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'center',
                horizontal: 'center',
            }}
            open={Boolean(success)}
            onClose={() => {setSuccess(null)}}
        >
            <Alert onClose={() => {setSuccess(null)}} severity="success">
                <AlertTitle>Sukces!</AlertTitle>
                Mecz został usunięty.
            </Alert>
        </Popover>
        <div style={{ height: 315, width: '100%' }}>
            <div style={{ display: 'flex', height: '100%' }}>
                <Box
                    sx={{
                        width: '100%',
                        '& .you': {
                            fontWeight: 'bold',
                        },
                        '& .even': {
                            backgroundColor: (theme) => theme.palette.grey[100],
                        },
                        '& .loser': {
                            backgroundColor: 'rgb(239,83,80,0.2)',
                            color: 'rgb(198,40,40)',
                        },
                        '& .winner': {
                            backgroundColor: 'rgb(76,175,80,0.2)',
                            color: 'rgb(27,94,32)',
                        },
                        '& .tie': {
                            backgroundColor: 'rgba(3,169,244,0.2)',
                            color: 'rgb(1,87,155)',
                        },
                        [`& .${gridClasses.row}`]: {
                            '&:hover, &.Mui-hovered': {
                                backgroundColor: alpha(theme.palette.grey[500], 0.2),
                                '@media (hover: none)': {
                                    backgroundColor: 'transparent',
                                },
                            }
                        }
                    }}
                >
                    <DataGrid
                        rows={gamesHistoryRows}
                        columns={gamesColumns}
                        pageSize={5}
                        rowsPerPageOptions={[5]}
                        initialState={{
                            sorting: {
                                sortModel: [{ field: 'id', sort: 'desc' }],
                            },
                        }}
                        disableSelectionOnClick={true}
                        rowHeight={36}
                        getCellClassName={(params) => {
                            if (params.row.result === 'TIE' && (params.field === 'playerOneName' || params.field === 'playerTwoName')) {
                                return 'tie'
                            }

                            if (params.field === 'playerOneName') {
                                return params.row.result === 'PLAYER_ONE' ? 'winner' : 'loser';
                            }

                            if (params.field === 'playerTwoName') {
                                return params.row.result === 'PLAYER_TWO' ? 'winner' : 'loser';
                            }
                        }}
                        getRowClassName={(params) => {
                            let classModificator = '';
                            if (params.row.profileOneIdentifier === profile.userIdentifier || params.row.profileTwoIdentifier === profile.userIdentifier ) {
                                classModificator += ' you';
                            }

                            if (params.row.id%2 === 0) {
                                classModificator += ' even';
                            }

                            return classModificator;
                        }}
                        disableColumnMenu={true}
                    />
                </Box>
            </div>
        </div>
    </Box>
}