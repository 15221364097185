import * as React from 'react';
import {Box, Button, Fab, FormControl, InputLabel, OutlinedInput} from "@mui/material";
import Stack from "@mui/material/Stack";
import SaveIcon from "@mui/icons-material/Save";
import AxiosPut from "../../AxiosPut";
import {useCookies} from "react-cookie";
import useAppNavigate from "../../App/useAppNavigate";

export default function ChangePassword() {
    const [cookies, setCookie, removeCookie] = useCookies(['accessToken']);
    const [oldPassword, setOldPassword] = React.useState('');
    const [oldPasswordError, setOldPasswordError] = React.useState(false);
    const [newPassword, setNewPassword] = React.useState('');
    const [newPasswordError, setNewPasswordError] = React.useState(false);
    const [newPasswordRepeated, setNewPasswordRepeated] = React.useState('');
    const [newPasswordRepeatedError, setNewPasswordRepeatedError] = React.useState(false);
    const navigate = useAppNavigate();

    const handleChangeOldPassword = (event) => {
        setOldPassword(event.target.value);
        setOldPasswordError(false);
    }

    const handleChangeNewPassword = (event) => {
        setNewPassword(event.target.value);
        setNewPasswordError(false);
        if (newPasswordRepeated !== '') {
            setNewPasswordRepeatedError(false);
        }
    }

    const handleChangeNewPasswordRepeated = (event) => {
        setNewPasswordRepeated(event.target.value);
        setNewPasswordRepeatedError(false);
        if (newPassword !== '') {
            setNewPasswordError(false);
        }
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (oldPassword === '') {
            setOldPasswordError(true);
        }

        if (newPassword !== newPasswordRepeated || newPassword === '' || newPasswordRepeated === '') {
            setNewPasswordError(true);
            setNewPasswordRepeatedError(true);
        }

        if (oldPassword === newPassword && newPassword === newPasswordRepeated) {
            setOldPasswordError(true);
            setNewPasswordError(true);
            setNewPasswordRepeatedError(true);
        }

        if (oldPasswordError || newPasswordError || newPasswordRepeatedError) {
            return;
        }

        let payload = {
            oldPassword: oldPassword,
            newPassword: newPassword,
            newPasswordRepeated: newPasswordRepeated,
        }

        window.dispatchEvent(new Event('contentLoading'));
        AxiosPut('/password', cookies.accessToken, payload)
            .then((response) => {
                navigate('/profile');
            }).catch((error) => {
                if (error.response.status === 401) {
                    removeCookie('accessToken', {path: '/'});

                    return;
                }

                if (error.response.status === 400 && Array.isArray(error.response.data)) {
                    error.response.data.map((responseError) => {
                        setNewPasswordError(responseError.message.includes('newPassword'));
                        setNewPasswordRepeatedError(responseError.message.includes('newPasswordRepeated'));
                    })
                    window.dispatchEvent(new Event('contentLoaded'));

                    return;
                }

                if (error.response.status === 400) {
                    setOldPasswordError(error.response.data.message.includes('Invalid password'));
                    setNewPasswordError(error.response.data.message.includes('New password does not match'));
                    setNewPasswordRepeatedError(error.response.data.message.includes('New password does not match'));
                    window.dispatchEvent(new Event('contentLoaded'));
                }
            });
    }

    React.useEffect(() => {
        window.dispatchEvent(new Event('contentLoaded'));
    })

    return <Box>
        <h1>Profil - zmiana hasła</h1>
        <form onSubmit={handleSubmit}>
            <Stack spacing={2}>
                <FormControl fullWidth error={oldPasswordError}>
                    <InputLabel htmlFor="old-password">Stare hasło</InputLabel>
                    <OutlinedInput
                        id="old-password"
                        value={oldPassword}
                        type={'password'}
                        label="Stare hasło"
                        onChange={handleChangeOldPassword}
                    />
                </FormControl>
                <FormControl fullWidth error={newPasswordError}>
                    <InputLabel htmlFor="new-password">Nowe hasło</InputLabel>
                    <OutlinedInput
                        id="new-password"
                        value={newPassword}
                        type={'password'}
                        label="Nowe hasło"
                        onChange={handleChangeNewPassword}
                    />
                </FormControl>
                <FormControl fullWidth error={newPasswordRepeatedError}>
                    <InputLabel htmlFor="new-password-repeated">Powtórz hasło</InputLabel>
                    <OutlinedInput
                        id="new-password-repeated"
                        value={newPasswordRepeated}
                        type={'password'}
                        label="Powtórz hasło"
                        onChange={handleChangeNewPasswordRepeated}
                    />
                </FormControl>
                <Button
                    type={'submit'}
                    variant={'outlined'}
                    sx={{display: { xs: 'none', sm: 'none', md: 'flex' },}}
                    size={'large'}
                    startIcon={<SaveIcon />}
                >Zmień hasło</Button>
            </Stack>
            <Fab type={'submit'} color={'primary'} aria-label={'add'} sx={{
                margin: 0,
                top: 'auto',
                right: 20,
                bottom: 80,
                left: 'auto',
                position: 'fixed',
                display: { sm: 'flex', md: 'none' },
            }}>
                <SaveIcon />
            </Fab>
        </form>
    </Box>
}