import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {Alert, AlertTitle, Popover} from "@mui/material";
import {useCookies} from "react-cookie";
import AxiosGet from "../AxiosGet";
import useAppNavigate from "../App/useAppNavigate";
import Duel from "./Create/Duel";
import FreeForAll from "./Create/FreeForAll";

export default function Create() {
    const [cookies, setCookie, removeCookie] = useCookies(['accessToken']);
    const [profile] = React.useState(JSON.parse(localStorage.getItem('profile')));
    const [currentLeague, setCurrentLeague] = React.useState(null);
    const [league, setLeague] = React.useState({attributes: []});
    const [leagues, setLeagues] = React.useState([]);
    const [selectedLeague, setSelectedLeague] = React.useState('');
    const [selectedSeason, setSelectedSeason] = React.useState('');
    const [success, setSuccess] = React.useState(null);

    const [loaded, setLoaded] = React.useState({
        'leagues': false,
        'league': false,
    });

    const divRef = React.useRef();

    const changeLoadedState = (newState) => {
        setLoaded(prevState => (
            {...prevState, ...newState}
        ))
    }

    React.useEffect(() => {
        if (Object.values(loaded).every(item => item === true)) {
            window.dispatchEvent(new Event('contentLoaded'));

            return;
        }

        window.dispatchEvent(new Event('contentLoading'));
    }, [loaded]);

    const navigate = useAppNavigate();
    const mapLeagues = () => {
        if (leagues.length > 0) {
            return leagues.sort((a, b) => {
                const nameA = a.name.toUpperCase();
                const nameB = b.name.toUpperCase();
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }

                return 0;
            }).map((leaguesLeague) => {
                let mate = leaguesLeague.mates.find((mate) => mate.userIdentifier === profile.userIdentifier);
                if (!(mate.roles.includes('ROLE_ADMIN') || mate.roles.includes('ROLE_GAME_MODER'))) {
                    return;
                }

                return <MenuItem value={leaguesLeague.identifier} key={leaguesLeague.identifier}>{leaguesLeague.name}</MenuItem>;
            })
        }

        return [<MenuItem value={''} key={''}>Brak lig</MenuItem>]
    }

    if (localStorage.getItem('currentLeague') !== null && currentLeague !== localStorage.getItem('currentLeague')) {
        setCurrentLeague(localStorage.getItem('currentLeague'));
    }

    React.useEffect(() => {
        AxiosGet('/leagues', cookies.accessToken)
            .then((response) => {
                if (response.data.length === 0) {
                    navigate('/leagues/create');

                    return;
                }

                setLeagues(response.data);
                changeLoadedState({leagues: true});

                return response.data;
            })
            .then((leagues) => {
                if (leagues.length > 0 && currentLeague === null) {
                    changeLoadedState({leagues: true});

                    return;
                }

                leagues.map((league) => {
                    let mate = league.mates.find((mate) => mate.userIdentifier === profile.userIdentifier);
                    if (league.identifier === currentLeague && (mate.roles.includes('ROLE_ADMIN') || mate.roles.includes('ROLE_GAME_MODER'))) {
                        setSelectedLeague(currentLeague);
                    }
                })
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    removeCookie('accessToken', {path: '/'});
                }
            });
    }, []);

    React.useEffect(() => {
        if (selectedLeague !== '') {
            AxiosGet('/leagues/' + selectedLeague, cookies.accessToken)
                .then((response) => {
                    setLeague(response.data);
                    changeLoadedState({league: true});
                    if (response.data.seasons.length > 0) {
                        let activeSeason = response.data.seasons.find(x => x.active === true)
                        if (activeSeason !== undefined) {
                            setSelectedSeason(activeSeason.identifier);

                            return;
                        }

                    }

                    setSelectedSeason('');
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        removeCookie('accessToken', {path: '/'});
                    }
                });
        }

        if (selectedLeague === '') {
            changeLoadedState({league: true});
        }
    }, [selectedLeague])

    let mappedLeagues;
    mappedLeagues = mapLeagues();

    const handleLeagueChange = (event) => {
        changeLoadedState({league: false});
        setSelectedLeague(event.target.value);
    };

    return (
        <Box sx={{ minWidth: 120}} ref={divRef}>
            <Popover
                anchorEl={success}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'center',
                }}
                open={Boolean(success)}
                onClose={() => {setSuccess(null)}}
            >
                <Alert onClose={() => {setSuccess(null)}} severity="success">
                    <AlertTitle>Sukces!</AlertTitle>
                    Mecz został dodany.
                </Alert>
            </Popover>
            <h1>Dodaj grę</h1>
            <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label1">Liga</InputLabel>
                <Select
                    labelId="demo-simple-select-label1"
                    id="demo-simple-select1"
                    label="Liga"
                    value={selectedLeague}
                    onChange={handleLeagueChange}
                >
                    {mappedLeagues}
                </Select>
            </FormControl>
            <Duel
                divRef={divRef}
                league={league}
                selectedLeague={selectedLeague}
                selectedSeason={selectedSeason}
                setSuccess={setSuccess}
            />
            {/*<FreeForAll*/}
            {/*    league={league}*/}
            {/*/>*/}
        </Box>
    );
}