import * as React from "react";
import Grid from "@mui/material/Unstable_Grid2";
import {Typography} from "@mui/material";

export default function StatsItem(props) {
    return <>
        <Grid xs={4} md={3} lg={2}>
            <Typography>{props.label}:</Typography>
        </Grid>
        <Grid xs={8} md={9} lg={10}>
            <Typography sx={{display: 'flex', alignItems: 'center', flexWrap: 'wrap',}}>{props.value}</Typography>
        </Grid>
    </>
}
