import * as React from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import LoadingButton from "@mui/lab/LoadingButton";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

export default function ConfirmationDialog(props) {
    return <Dialog
        open={props.open}
        onClose={props.onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle id="alert-dialog-title">
            {props.title}
        </DialogTitle>
        <DialogContent>
            <DialogContentText id="alert-dialog-description">{props.content}</DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={props.onClose} startIcon={<CancelIcon />}>Anuluj</Button>
            <LoadingButton
                loading={props.loading}
                loadingPosition={'start'}
                startIcon={<CheckCircleIcon />}
                onClick={props.onConfirm}
                autoFocus
                value={props.value}
            >
                Kontynuuj
            </LoadingButton>
        </DialogActions>
    </Dialog>
}