import * as React from "react";
import HomeIcon from "@mui/icons-material/Home";
import BaseElement from "../BaseElement";
import {Typography} from "@mui/material";

export default function Demo(props) {
    return <BaseElement
        timestamp={props.feedEntry.publishedAt}
        name={process.env.REACT_APP_NAME}
        title={'Witaj na koncie demonstracyjnym!'}
        icon={<HomeIcon color={'info'} fontSize={'small'} />}
        isSeen={props.feedEntry.isSeen}
    >
        <p>Cieszymy się, że zdecydowałeś/aś się zobaczyć możliwości naszej aplikacji <Typography component={'span'} noWrap>{process.env.REACT_APP_NAME}</Typography>.
            Nasza platforma wykorzystuje najnowsze technologie, takie jak PHP, Symfony, Python, FastAPI, JS, React, MUI, Websocket, RabbitMQ, PostgreSQL oraz Docker. Dzięki prostemu interfejsowi <Typography component={'span'} noWrap>i narzędziom,</Typography> będziesz miał dostęp <Typography component={'span'} noWrap>do dokładnych</Typography> statystyk swoich rozgrywek <Typography component={'span'} noWrap>w prywatnej</Typography> lidze.</p>

        <p>Na koncie została dodana liga demonstracyjna bilarda, <Typography component={'span'} noWrap>w której</Typography> skonfigurowano dwa dodatkowe parametry <Typography component={'span'} noWrap>w postaci</Typography> rodzaju zakończenia gry oraz ilości fauli każdego <Typography component={'span'} noWrap>z graczy.</Typography>
            Aktualnie funkcjonalność dodatkowych parametrów <Typography component={'span'} noWrap>w ligach</Typography> nie jest jeszcze <Typography component={'span'} noWrap>w pełni dostępna,</Typography> pracujemy nad wdrożeniem.</p>

        Z poważaniem,<br />Zespół {process.env.REACT_APP_NAME}.</BaseElement>
}