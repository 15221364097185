import axios from "axios";

export default function AxiosPut(endpoint, accessToken, payload = {}) {
    let requestConfig;
    requestConfig = {
        headers: {
            'Content-Type': 'application/json',
            'X-AUTH-TOKEN': accessToken
        }
    };

    return axios.put(process.env.REACT_APP_AISO_API_URL + endpoint, payload, requestConfig);
}