import * as React from "react";
import {default as BaseContextMenu} from "../../App/ContextMenu";
import MenuItem from "@mui/material/MenuItem";
import {Link, ListItemText} from "@mui/material";
import PeopleIcon from "@mui/icons-material/People";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import EventBusyIcon from "@mui/icons-material/EventBusy";
import AxiosPost from "../../AxiosPost";
import AxiosPut from "../../AxiosPut";
import {useCookies} from "react-cookie";
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import LogoutIcon from '@mui/icons-material/Logout';
import hasRole from "../../ContextMenu/hasRole";
import ConfirmationDialog from "../../App/ConfirmationDialog";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import useAppNavigate from "../../App/useAppNavigate";
import SettingsIcon from '@mui/icons-material/Settings';

export default function ContextMenu(props) {
    const [cookies, setCookie, removeCookie] = useCookies(['accessToken']);
    const [contextMenu, setContextMenu] = React.useState(null);
    const [seasonCloseDialog, setSeasonCloseDialog] = React.useState(false);
    const [leaveLeagueDialog, setLeaveLeagueDialog] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const navigate = useAppNavigate();

    const handleClose = () => {
        setContextMenu(null);
    }

    const handleContextMenu = (event) => {
        if (contextMenu === null) {
            setContextMenu(event.currentTarget);

            return;
        }

        setContextMenu(null);
    }

    const handleCloseSeasonDialog = () => {
        setSeasonCloseDialog(!seasonCloseDialog);
        setContextMenu(null);
    }

    const handleLeaveLeagueDialog = () => {
        setLeaveLeagueDialog(!leaveLeagueDialog);
        setContextMenu(null);
    }

    const handleOpenNewSeason = () => {
        window.dispatchEvent(new Event('contentLoading'));
        AxiosPost('/seasons/create', cookies.accessToken, {leagueIdentifier: props.leagueIdentifier}).then((response) => {
            props.setActiveSeason(response.data.seasonIdentifier);
            window.dispatchEvent(new Event('contentLoaded'));
        }).catch((error) => {
            if (error.response.status === 401) {
                removeCookie('accessToken', {path: '/'});
            }
        });
        setContextMenu(null);
    }

    const handleCloseSeason = () => {
        setLoading(true);
        AxiosPut('/seasons/' + props.activeSeason + '/close', cookies.accessToken, {leagueIdentifier: props.leagueIdentifier})
            .then((response) => {
                props.setActiveSeason('')
                if (response.status === 200) {
                    setSeasonCloseDialog(false);
                    setLoading(false);
                }
            }).catch((error) => {
            if (error.response.status === 401) {
                removeCookie('accessToken', {path: '/'});
            }
        });
    }

    const handleLeaveLeague = () => {
        setLoading(true);
        AxiosPut('/leagues/' + props.leagueIdentifier + '/leave', cookies.accessToken)
            .then((response) => {
                setLoading(false);
                setLeaveLeagueDialog(false);
                window.location.href = '/leagues'
            }).catch((error) => {
            if (error.response.status === 401) {
                removeCookie('accessToken', {path: '/'});
            }
        });
        setSeasonCloseDialog(false);
    }

    return <>
        <BaseContextMenu
            contextMenu={contextMenu}
            handleClose={handleClose}
            handleContextMenu={handleContextMenu}
        >
            {
                hasRole(props.userIdentifier, props.mates, 'ROLE_ADMIN') ?
                        <MenuItem component={Link} onClick={() => {
                            handleClose();
                            navigate('/leagues/' + props.leagueIdentifier + '/players/create');
                        }}>
                            <PersonAddAlt1Icon />
                            <ListItemText>&nbsp;Dodaj gracza</ListItemText>
                        </MenuItem>
                    : ''
            }
            {
                hasRole(props.userIdentifier, props.mates, 'ROLE_ADMIN') ?
                        <MenuItem component={Link} onClick={() => {
                            handleClose();
                            navigate('/leagues/' + props.leagueIdentifier + '/invite');
                        }}>
                            <GroupAddIcon />
                            <ListItemText>&nbsp;Zaproś graczy</ListItemText>
                        </MenuItem>
                    : ''
            }
            {
                hasRole(props.userIdentifier, props.mates, 'ROLE_ADMIN') ?
                        <MenuItem component={Link} onClick={() => {
                            handleClose();
                            navigate('/leagues/' + props.leagueIdentifier + '/players');
                        }}>
                            <PeopleIcon />
                            <ListItemText>&nbsp;Gracze</ListItemText>
                        </MenuItem>
                    : ''
            }
            {
                props.seasons && props.seasons.length > 1 ?
                        <MenuItem component={Link} href={'/leagues/' + props.leagueIdentifier + '/seasons'}>
                            <CalendarMonthIcon />
                            <ListItemText>&nbsp;Poprzednie sezony</ListItemText>
                        </MenuItem>
                    : ''
            }
            {
                hasRole(props.userIdentifier, props.mates, 'ROLE_ADMIN') ?
                    <MenuItem component={Link} onClick={() => {
                        handleClose();
                        navigate('/leagues/' + props.leagueIdentifier + '/edit');
                    }}>
                        <SettingsIcon />
                        <ListItemText>&nbsp;Ustawienia</ListItemText>
                    </MenuItem>
                    : ''
            }
            {
                hasRole(props.userIdentifier, props.mates, 'ROLE_ADMIN') ?
                        props.activeSeason === ''
                            ? <MenuItem onClick={handleOpenNewSeason}><EventAvailableIcon /><ListItemText>&nbsp;Rozpocznij sezon</ListItemText></MenuItem>
                            : <MenuItem onClick={handleCloseSeasonDialog}><EventBusyIcon /><ListItemText>&nbsp;Zakończ sezon</ListItemText></MenuItem>
                    : ''
            }
            <MenuItem onClick={handleLeaveLeagueDialog} component={Link}>
                <LogoutIcon />
                <ListItemText>&nbsp;Opuść ligę</ListItemText>
            </MenuItem>
        </BaseContextMenu>
        <ConfirmationDialog
            open={seasonCloseDialog}
            onClose={handleCloseSeasonDialog}
            title={'Kończenie sezonu'}
            content={'Zamknięcie sezonu spowoduje zaprzestanie naliczania punktów do dotychczasowych statystyk.'}
            loading={loading}
            onConfirm={handleCloseSeason}
            value={''}
        />
        <ConfirmationDialog
            open={leaveLeagueDialog}
            onClose={handleLeaveLeagueDialog}
            title={'Opuszczanie ligi'}
            content={'Czy na pewno chcesz opuścić ligę?'}
            loading={loading}
            onConfirm={handleLeaveLeague}
            value={''}
        />
    </>
}