import * as React from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import ImageNotSupportedIcon from '@mui/icons-material/ImageNotSupported';
import {CircularProgress} from "@mui/material";

export default function Identicon(props) {
    const [onLoad, setOnLoad] = React.useState(true);
    const [loadError, setLoadError] = React.useState(false);

    return (
        <Box
            sx={[props.sx, {
                display: 'flex',
                '& > :not(style)': {
                    ml: props.ml,
                    mr: props.mr,
                    width: props.width,
                    height: props.height,
                },
            }]}
            onClick={props.onClick}
        >
            <Paper variant="outlined" sx={{overflow: 'hidden'}}>
                {props.imagesDir !== undefined && loadError === false ?
                    <img
                        src={process.env.REACT_APP_API_URL + props.imagesDir + 'icon.svg'}
                        width={props.width}
                        height={props.height}
                        alt={props.name}
                        onLoad={() => setOnLoad(false)}
                        onError={()=> setLoadError(true)}
                    /> : loadError === false && onLoad === true ?
                        <CircularProgress size={props.width} /> : <ImageNotSupportedIcon sx={{fontSize: props.width}} color={'disabled'}/>
                }
            </Paper>
            {props.children}
        </Box>
    );
}