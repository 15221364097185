import * as React from "react";
import Container from "@mui/material/Container";
import FormControl from "@mui/material/FormControl";
import {Box, TextField, Typography} from "@mui/material";
import Stack from "@mui/material/Stack";
import {useCookies} from "react-cookie";
import axios from "axios";
import AxiosGet from "./AxiosGet";
import LoginIcon from '@mui/icons-material/Login';
import LoadingButton from "@mui/lab/LoadingButton";
import useAppNavigate from "./App/useAppNavigate";

export default function Login() {
    const [cookies, setCookie, removeCookie] = useCookies(['accessToken']);
    const [username, setUsername] = React.useState('');
    const [fieldError, setFieldError] = React.useState(false);
    const [password, setPassword] = React.useState('');
    const [isLoading, setIsLoading] = React.useState(false);
    const navigate = useAppNavigate();

    const handleLoginChange = (event) => {
        setUsername(event.target.value);
    };

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };

    const clearLocalStorageIfNotSameProfile = (fetchedProfile) => {
        if (localStorage.getItem('profile') === JSON.stringify(fetchedProfile)) {
            return;
        }

        localStorage.removeItem('leagues');
        localStorage.removeItem('currentLeague');
    }

    const redirectToLeagues = (rooms) => {
        if (rooms.length > 0 || window.location.href.includes('/invite/')) {
            return;
        }

        navigate('/leagues');
    }

    const redirectToLeagueIfPinned = () => {
        let currentLeague = localStorage.getItem('currentLeague');
        if (currentLeague === null) {
            return;
        }
        navigate('/leagues/' + currentLeague);
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (username === '' || password === '') {
            setFieldError(true);

            return;
        }
        let payload = {
            username: username,
            password: password,
        }
        setFieldError(false);

        let loginResponse;
        setIsLoading(true);
        loginResponse = await axios.post(process.env.REACT_APP_API_URL + '/login', payload).catch(function (error) {
            if (error.response) {
                setIsLoading(false);
                setFieldError(true);
            }
        });

        if (loginResponse !== undefined && loginResponse.data) {
            let profile = {
                userIdentifier: loginResponse.data.userIdentifier,
                userName: loginResponse.data.username,
                userEmail: loginResponse.data.userEmail,
                fabsHints: true,
            };
            clearLocalStorageIfNotSameProfile(profile);
            localStorage.setItem('profile', JSON.stringify(profile));
            AxiosGet('/leagues', loginResponse.data.token).then((response) => {
                localStorage.setItem('leagues', JSON.stringify(response.data));
                window.dispatchEvent(new Event('leaguesChanged'));
                setCookie(
                    'accessToken',
                    loginResponse.data.token,
                    {path: '/', maxAge: Math.ceil((Date.parse(loginResponse.data.expiresAt) - Date.now())/1000)}
                );
                redirectToLeagueIfPinned();
                redirectToLeagues(response.data);
            }).catch((error) => {
                if (error.response.status === 401) {
                    removeCookie('accessToken', {path: '/'});
                }
            });
        }
    }

    return (
        <div>
            <form onSubmit={handleSubmit}>
                <Box sx={{display: 'flex', alignItems: 'center', height: '90vh'}}>
                    <Container maxWidth={'md'} sx={{padding: 0, marginTop: 10}}>
                        <Container maxWidth={'xs'} sx={{}}>
                            <h1 align={'center'}>{process.env.REACT_APP_NAME}</h1>
                            <Typography gutterBottom mb={2}>
                                {process.env.REACT_APP_NAME} to aplikacja do rejestrowania wyników <Typography component={'span'} noWrap>i statystyk</Typography> prywatnych lig <Typography component={'span'} noWrap>w dowolnej</Typography> dyscyplinie sportowej, planszowej czy <Typography component={'span'} noWrap>e-sportowej.</Typography>
                            </Typography>
                            <Stack spacing={2}>
                                <FormControl fullWidth>
                                    <TextField
                                        id={'username'}
                                        label={'E-mail'}
                                        variant={'outlined'}
                                        error={fieldError}
                                        onChange={handleLoginChange}
                                    />
                                </FormControl>
                                <FormControl fullWidth>
                                    <TextField
                                        id={'password'}
                                        label={'Password'}
                                        type={'password'}
                                        variant={'outlined'}
                                        error={fieldError}
                                        onChange={handlePasswordChange}
                                    />
                                </FormControl>
                            </Stack>
                            <LoadingButton
                                loading={isLoading}
                                loadingPosition={'start'}
                                startIcon={<LoginIcon />}
                                type={'submit'}
                                variant={'outlined'}
                                size={'large'}
                                fullWidth
                                onClick={handleSubmit}
                                sx={{marginTop: 2, marginBottom: 2}}
                            >
                                Zaloguj
                            </LoadingButton>
                        </Container>
                    </Container>
                </Box>
            </form>
        </div>
    );
}