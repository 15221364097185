export default function hasRole(identifier, mates, role) {
    if (mates === undefined) {
        return false;
    }

    let userMate = mates.find(mate =>
        mate.userIdentifier === identifier
    )

    let hasRole = false;
    try {
        hasRole = userMate.roles.includes(role)
    } catch (error) {

    }

    return hasRole;
}
