import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Unstable_Grid2';
import {useParams} from "react-router-dom";
import {useCookies} from "react-cookie";
import AxiosGet from "../AxiosGet";
import {
    Button,
    FormControl,
    InputLabel,
    OutlinedInput, Typography,
} from "@mui/material";
import AxiosPost from "../AxiosPost";
import basicColors from "../colors";
import AxiosDelete from "../AxiosDelete";
import LinkIcon from '@mui/icons-material/Link';
import ActionsMenu from "./Players/ActionsMenu";
import ConfirmationDialog from "../App/ConfirmationDialog";
import Header from "./GetOne/Header";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

export default function Players() {
    let { identifier } = useParams();
    const [cookies, setCookie, removeCookie] = useCookies(['accessToken']);
    const [profile] = React.useState(JSON.parse(localStorage.getItem('profile')));
    const [league, setLeague] = React.useState({});
    const [colors] = React.useState(basicColors());
    const [players, setPlayers] = React.useState([]);
    const [formName, setFormName] = React.useState('');
    const [formNameError, setFormNameError] = React.useState(false);
    const [deletePlayerDialog, setDeletePlayerDialog] = React.useState(false);
    const [playerToDelete, setPlayerToDelete] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const [activeSeason, setActiveSeason] = React.useState('');
    const fetchLeague = () => {
        AxiosGet('/leagues/' + identifier, cookies.accessToken).then((response) => {
            setLeague(response.data);
            setPlayers(response.data.players);
            let activeSeason = response.data.seasons.find(season => season.active === true)
            if (activeSeason === undefined) {
                setActiveSeason('');
                window.dispatchEvent(new Event('contentLoaded'));

                return;
            }

            setActiveSeason(activeSeason.identifier);
            window.dispatchEvent(new Event('contentLoaded'));
        }).catch((error) => {
            if (error.response.status === 401) {
                removeCookie('accessToken', {path: '/'});
            }
        });
    }
    const fetchLeaguePlayers = () => {
        AxiosGet('/leagues/' + identifier + '/players', cookies.accessToken).then((response) => {
            setPlayers(response.data);
        }).catch((error) => {
            if (error.response.status === 401) {
                removeCookie('accessToken', {path: '/'});
            }
        });
    }

    React.useEffect(() => {
        fetchLeague();
    }, []);

    const handleNameChange = (event) => {
        setFormName(event.target.value);
        setFormNameError(false);
    }

    const handleDelete = (identifier) => {
        setPlayerToDelete(players.find((player) => player.identifier === identifier));
        setDeletePlayerDialog(true);
    }

    let mappedPlayers = [];
    mappedPlayers = players.filter((player) => player.active === true).map((player) => {
        return <Grid container spacing={1} key={player.identifier} alignItems={'baseline'}>
            <Grid xs={5}>
                <Item>{player.name} {player.mateIdentifier !== null ? <LinkIcon fontSize={'small'} /> : ''}</Item>
            </Grid>
            <Grid xs={7} sx={{textAlign: 'center'}}>
                <ActionsMenu
                    leagueIdentifier={identifier}
                    profile={profile}
                    mates={league.mates}
                    player={player}
                    handleDelete={handleDelete}
                />
            </Grid>
        </Grid>;
    });

    const handleSubmit = async (event) => {
        event.preventDefault();
        let playerColor = ''
        if (players.length >= colors.length) {
            playerColor = colors[Math.floor(Math.random() * colors.length)];
        } else {
            playerColor = colors[players.length];
        }

        let payload = {
            leagueIdentifier: identifier,
            name: formName,
            color: playerColor
        }

        let response;
        response = await AxiosPost('/players', cookies.accessToken, payload).catch(function (error) {
            if (error.response.status === 400) {
                setFormNameError(true)
            }
        });

        if (response.data.status === 'ok') {
            fetchLeaguePlayers();
            setFormName('');
        }
    }

    const handleClose = () => {
        setDeletePlayerDialog(false);
    }

    const handleContinueDeleting = async (event) => {
        event.preventDefault();
        setLoading(true);
        let payload = {
            leagueIdentifier: identifier,
            playerIdentifier: event.target.value,
        }
        let response;
        response = await AxiosDelete('/players', cookies.accessToken, payload).catch((error) => {
            if (error.response.status === 401) {
                removeCookie('accessToken', {path: '/'});
            }
        });

        if (response.data.status === 'ok') {
            setDeletePlayerDialog(false);
            setLoading(false);
            fetchLeaguePlayers();
        }
    }

    return (
        <Box sx={{position: 'relative'}}>
            <Header identifier={identifier} league={league} activeSeason={activeSeason} setActiveSeason={setActiveSeason} />
            <Typography sx={{fontSize: '1.25rem', fontWeight: 'bold'}}>Gracze ligi</Typography>
            <ConfirmationDialog
                open={deletePlayerDialog}
                onClose={handleClose}
                title={'Usuwanie gracza'}
                content={'Czy napewno usunąć gracza ' + playerToDelete.name + '?'}
                loading={loading}
                onConfirm={handleContinueDeleting}
                value={playerToDelete.identifier}
            />
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={1}>
                    <Grid xs={5}>
                        <Item>Gracz </Item>
                    </Grid>
                    <Grid xs={7}>
                        <Item>Akcje</Item>
                    </Grid>
                </Grid>
                {mappedPlayers || ''}
            </Box>
            <Box
                component={'form'}
                noValidate
                autoComplete={'off'}
                onSubmit={handleSubmit}
            >
                <Grid container spacing={1} sx={{mt: 3}}>
                    <Grid xs={8}>
                        <FormControl fullWidth error={formNameError}>
                            <InputLabel htmlFor="outlined-adornment-amount">Dodaj gracza</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-amount"
                                value={formName}
                                label={'Dodaj gracza'}
                                onChange={handleNameChange}
                            />
                        </FormControl>
                    </Grid>
                    <Grid xs={4}>
                        <FormControl fullWidth>
                            <Button variant={'outlined'} sx={{height: 52.5}} type={'submit'}>Dodaj</Button>
                        </FormControl>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
}