import React, { useState, useCallback, useEffect } from 'react';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import {useCookies} from "react-cookie";
import AisoAxiosGet from "./AisoAxiosGet";
import Divider from "./Feed/Divider";
import Test from "./Feed/BaseElement/Test";

export default function WebSocketDemo() {
    const [cookies, setCookie, removeCookie] = useCookies(['accessToken']);
    const [socketUrl, setSocketUrl] = useState(process.env.REACT_APP_AISO_SOCKET_URL + '/feed?access_token=' + cookies.accessToken);
    const [syncedFeed, setSyncedFeed] = useState([]);
    const [socketFeed, setSocketFeed] = useState([]);
    const { sendMessage, lastMessage, readyState } = useWebSocket(socketUrl);
    const ref = React.useRef();

    const fetchFeed = () => {
        AisoAxiosGet('/feed', cookies.accessToken).then((response) => {
            setSyncedFeed(response.data)
            window.dispatchEvent(new Event('contentLoaded'));
        }).catch((error) => {
            if (error.response.status === 401) {
                removeCookie('accessToken', {path: '/'});
            }
        });
    }

    useEffect(() => {
        fetchFeed();
    }, [])

    useEffect(() => {
        window.dispatchEvent(new Event('contentLoaded'));
        if (lastMessage !== null) {
            let messageArray = JSON.parse(lastMessage.data)

            setSocketFeed((prevState) => {
                const newArray = [...prevState];
                newArray.unshift(messageArray);

                return newArray;
            })
        }
    }, [lastMessage]);

    const connectionStatus = {
        [ReadyState.CONNECTING]: 'Connecting',
        [ReadyState.OPEN]: 'Open',
        [ReadyState.CLOSING]: 'Closing',
        [ReadyState.CLOSED]: 'Closed',
        [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
    } [readyState];

    let lastDate = new Date()

    return (
        <div ref={ref}>
            {
                socketFeed.map((feedEntry) => {
                    return <Test key={feedEntry.identifier} feedEntry={feedEntry}/>
                })
            }
            {
                syncedFeed.sort(function(a, b) {
                    var dateA = new Date(a.publishedAt),
                        dateB = new Date(b.publishedAt);
                    if (dateA < dateB) {
                        return 1;
                    }

                    if (dateA > dateB) {
                        return -1;
                    }

                    return 0;
                }).map((feedEntry) => {
                    let elements = [];
                    let publishedAtDate = new Date(feedEntry.publishedAt)
                    let nowDate = lastDate
                    if (publishedAtDate.getFullYear() < nowDate.getFullYear() ||
                        (publishedAtDate.getFullYear() === nowDate.getFullYear() && publishedAtDate.getMonth() < nowDate.getMonth()) ||
                        (publishedAtDate.getFullYear() === nowDate.getFullYear() && publishedAtDate.getMonth() === nowDate.getMonth() && publishedAtDate.getDate() < nowDate.getDate())
                    ) {
                        elements.push(<Divider key={feedEntry.identifier + '-divider'}>{feedEntry.publishedAt.substring(0, 10)}</Divider>)
                    }

                    if (feedEntry.payload.type === 'TEST') {
                        elements.push(<Test key={feedEntry.identifier} feedEntry={feedEntry}/>)
                    }

                    if (feedEntry.payload.type === 'WELCOME') {
                        elements.push(<Test key={feedEntry.identifier} feedEntry={feedEntry}/>)
                    }

                    lastDate = publishedAtDate

                    return elements
                })
            }
        </div>
    );
};