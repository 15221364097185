import * as React from 'react';
import {Button} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";

export default function QrScanner(props) {
    const [videoDevices, setVideoDevices] = React.useState([]);
    const [device, setDevice] = React.useState('');

    React.useEffect(() => {
        props.reader.listVideoInputDevices()
            .then((videoInputDevices) => {
                setVideoDevices(videoInputDevices);
                setDevice(videoInputDevices[videoInputDevices.length - 1].deviceId);
            })
            .catch((err) => {
                console.error(err);
            });
    }, [])

    React.useEffect(() => {
        if (device !== '') {
            decodeOnce(props.reader, device);
        }
    }, [device]);

    React.useEffect(() => {
        if (props.readerReset) {
            handleNext();
            props.setReaderReset(false);
        }

    }, [props.readerReset])

    let mappedDevices = [];
    if (videoDevices.length > 0) {
        mappedDevices = videoDevices.map(videoDevice => {
            return <MenuItem key={videoDevice.deviceId} value={videoDevice.deviceId}>{videoDevice.label}</MenuItem>;
        });
    }

    const handleNext = () => {
        props.reader.reset();
        decodeOnce(props.reader, device);
    }

    const handleDeviceChange = (event) => {
        props.reader.reset();
        setDevice(event.target.value);
        decodeOnce(props.reader, event.target.value);
    }

    function decodeOnce(codeReader, selectedDeviceId) {
        codeReader.decodeFromInputVideoDevice(selectedDeviceId, 'video').then((result) => {
            props.setReads([...props.reads, JSON.parse(result.text)]);
        }).catch((err) => {
            console.error(err)
        })
    }

    function decodeContinuously(codeReader, selectedDeviceId) {
        codeReader.decodeFromInputVideoDeviceContinuously(selectedDeviceId, 'video', (result, err) => {
            if (result) {
                // properly decoded qr code
                console.log('Found QR code!', result)
                document.getElementById('result').textContent = result.text
            }

            if (err) {

            }
        })
    }

    return (
        <div>
            <Stack spacing={2}>
                <Paper variant="outlined" >
                    <video id="video" height={'300vh'} width={'100%'}></video>
                </Paper>
                <FormControl fullWidth>
                    <InputLabel id="video-device-label">Źródło</InputLabel>
                    <Select
                        labelId="video-device-label"
                        id="video-device"
                        value={device}
                        label="Źródło"
                        onChange={handleDeviceChange}
                    >
                        {mappedDevices}
                    </Select>
                </FormControl>
                {props.allowNext ? <Button variant="outlined" onClick={handleNext}>Następny</Button> : ''}
            </Stack>
        </div>
    );
}