import * as React from 'react';
import {useParams} from "react-router-dom";
import {useCookies} from "react-cookie";
import Box from "@mui/material/Box";
import AxiosGet from "../AxiosGet";
import GamesHistory from "./GetOne/GamesHistory";
import Ranking from "./GetOne/Ranking";
import useAppNavigate from "../App/useAppNavigate";
import RankingChart from "./GetOne/RankingChart";
import SeasonBrowser from "./GetOne/SeasonBrowser";
import Header from "./GetOne/Header";

export default function GetOne() {
    let { identifier } = useParams();
    const [league, setLeague] = React.useState({players: [{identifier: '', name: ''}], seasons: [{ranking: [{playerIdentifier: '', score: 0}], games: [{playerOneIdentifier: '', playerTwoIdentifier: ''}]}]});
    const [currentSeason, setCurrentSeason] = React.useState('');
    const [activeSeason, setActiveSeason] = React.useState('');
    const [cookies, setCookie, removeCookie] = useCookies(['accessToken']);
    const [season, setSeason] = React.useState(null);
    const [seasonStatistics, setSeasonStatistics] = React.useState([]);
    const [seasonChartStatistics, setSeasonChartStatistics] = React.useState([]);
    const [seasonAttributesStatistics, setSeasonAttributesStatistics] = React.useState([]);
    const [loaded, setLoaded] = React.useState({
        'league': false,
        'season': false,
        'seasonStats': false,
        'seasonChartStats': false,
        'seasonAttributesStats': false,
    });
    const navigate = useAppNavigate();

    const changeLoadedState = (newState) => {
        setLoaded(prevState => (
            {...prevState, ...newState}
        ))
    }

    const fetchLeague = () => {
        AxiosGet('/leagues/' + identifier, cookies.accessToken).then((response) => {
            setLeague(response.data);
            changeLoadedState({league: true});
            let activeSeason = response.data.seasons.find(season => season.active === true)
            if (activeSeason === undefined) {
                setCurrentSeason(response.data.seasons.at(-1).identifier);

                return;
            }

            setActiveSeason(activeSeason.identifier);
            setCurrentSeason(activeSeason.identifier);
        }).catch((error) => {
            changeLoadedState({league: true});
            if (error.response.status === 401) {
                removeCookie('accessToken', {path: '/'});
            }
            if (error.response.status === 404) {
                navigate('/leagues');
            }
        });
    }

    const fetchSeason = (seasonIdentifier) => {
        AxiosGet('/seasons/' + seasonIdentifier, cookies.accessToken).then((response) => {
            setSeason(response.data);
            changeLoadedState({season: true});
        }).catch((error) => {
            changeLoadedState({season: true});
            if (error.response.status === 401) {
                removeCookie('accessToken', {path: '/'});
            }
        });
    };

    const fetchSeasonStatistics = (seasonIdentifier) => {
        AxiosGet('/seasons/' + seasonIdentifier + '/statistics', cookies.accessToken).then((response) => {
            setSeasonStatistics(response.data);
            changeLoadedState({seasonStats: true});
        }).catch((error) => {
            changeLoadedState({seasonStats: true});
            if (error.response.status === 401) {
                removeCookie('accessToken', {path: '/'});
            }
        });
    };
    const fetchSeasonChartStatistics = (seasonIdentifier) => {
        AxiosGet('/seasons/' + seasonIdentifier + '/charts', cookies.accessToken).then((response) => {
            setSeasonChartStatistics(response.data);
            changeLoadedState({seasonChartStats: true});
        }).catch((error) => {
            changeLoadedState({seasonChartStats: true});
            if (error.response.status === 401) {
                removeCookie('accessToken', {path: '/'});
            }
        });
    };

    const fetchSeasonAttributesStatistics = (seasonIdentifier) => {
        AxiosGet('/seasons/' + seasonIdentifier + '/attributes-ranking', cookies.accessToken).then((response) => {
            setSeasonAttributesStatistics(response.data);
            changeLoadedState({seasonAttributesStats: true});
        }).catch((error) => {
            changeLoadedState({seasonAttributesStats: true});
            if (error.response.status === 401) {
                removeCookie('accessToken', {path: '/'});
            }
        });
    }

    React.useEffect(() => {
        fetchLeague();
    }, [identifier, activeSeason]);

    React.useEffect(() => {
        window.dispatchEvent(new Event('contentLoading'));
        changeLoadedState({
            season: false, seasonStats: false, seasonChartStats: false, seasonAttributesStats: false
        });
        if (currentSeason === '') {
            return;
        }

        fetchSeason(currentSeason);
        fetchSeasonChartStatistics(currentSeason);
        fetchSeasonStatistics(currentSeason);
        fetchSeasonAttributesStatistics(currentSeason);
    }, [currentSeason]);

    React.useEffect(() => {
        if (Object.values(loaded).every(item => item === true)) {
            window.dispatchEvent(new Event('contentLoaded'));
        }
    }, [loaded]);

    let currentSeasonData = league.seasons.find(season => season.identifier === currentSeason);

    return <Box sx={{position: 'relative'}}>
        <Header identifier={identifier} league={league} activeSeason={activeSeason} setActiveSeason={setActiveSeason} />
        <SeasonBrowser
            league={league}
            seasons={league.seasons}
            season={season}
            currentSeason={currentSeason}
            setCurrentSeason={setCurrentSeason}
            activeSeason={activeSeason}
        />
        <Ranking
            league={league}
            currentSeasonData={currentSeasonData}
            seasonStatistics={seasonStatistics}
            attributesStatistics={seasonAttributesStatistics}
        />
        <RankingChart league={league} seasonChartStatistics={seasonChartStatistics} />
        <GamesHistory
            league={league}
            currentSeasonData={season}
            fetchLeague={fetchLeague}
        />
    </Box>;
}
