import * as React from "react";
import {CategoryScale, Chart, Legend, LinearScale, LineElement, PointElement, Title, Tooltip} from "chart.js";
import {Box, Button, ButtonGroup, Typography} from "@mui/material";

import {Line} from "react-chartjs-2";
import convertHex from "../../../convertHex";


export default function Head2HeadChart(props) {
    const [gamesLabels, setGamesLabels] = React.useState([]);
    const [gamesChartDataset, setGamesChartDataset] = React.useState([]);
    const [scaleMin, setScaleMin] = React.useState(-50);
    const [scaleMax, setScaleMax] = React.useState(50);
    Chart.register(
        CategoryScale,
        LinearScale,
        PointElement,
        LineElement,
        Title,
        Tooltip,
        Legend
    );

    let dailyOptions = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: false,
                text: '',
            },
        },
        scales: {
            y: {
                display: true,
                min: scaleMin,
                max: scaleMax,
            },
            x: {
                ticks: {
                    maxRotation: 0,
                    minRotation: 0
                }
            }
        },
        maintainAspectRatio: false,
    };


    React.useEffect(() => {
        if (props.playerStatistics.seasonHeads.length > 0) {
            let seasonHead = props.playerStatistics.seasonHeads.find((seasonHead) => seasonHead.seasonIdentifier === props.currentSeason);
            if (seasonHead !== undefined) {
                setGamesLabels([...seasonHead.headedGames.map((item, index) => index), seasonHead.headedGames.length]);
                let playersIdentifiers = seasonHead.headedStatistics.map((stat) => stat.opponentIdentifier);
                let sortedGames = seasonHead.headedGames.sort(function(a, b) {
                    var dateA = new Date(a.createdAt),
                        dateB = new Date(b.createdAt);
                    if (dateA < dateB) {
                        return -1;
                    }

                    if (dateA > dateB) {
                        return 1;
                    }

                    return 0;
                }).map((game, index) => {
                    return {
                        index: index + 1,
                        ...game
                    }
                });

                let dataset = playersIdentifiers.map((playerIdentifier) => {
                    let transformedGames = sortedGames.filter((game) => game.headedIdentifier === playerIdentifier).map((game) => {return {
                            index: game.index,
                            opponentIdentifier: playerIdentifier,
                            points: game.scoreChange,
                        }});
                    transformedGames = transformedGames.map((game, index) => {
                        return {
                            index: game.index,
                            opponentIdentifier: game.opponentIdentifier,
                            points: transformedGames.filter((innerGame, innerIndex) => innerIndex < index).reduce(function (acc, obj) { return acc + obj.points; }, 0) + game.points,
                            scoreChange: game.points,
                        }
                    })
                    let pointsMax = Math.ceil(Math.max(...transformedGames.map(o => o.points)) / 10) * 10;
                    setScaleMax(prevState => {
                        if (prevState > pointsMax) {
                            return prevState;
                        }

                        return pointsMax;
                    });

                    let pointsMin = Math.floor(Math.min(...transformedGames.map(o => o.points)) / 10) * 10;
                    setScaleMin(prevState => {
                        if (prevState < pointsMin) {
                            return prevState;
                        }

                        return pointsMin;
                    });

                    let foundPlayer = props.league.players.find((player) => player.identifier === playerIdentifier)
                    return {
                        spanGaps: true,
                        label: foundPlayer.name,
                        data: [0, ...sortedGames.map((sortedGame) => {
                            let foundGame = transformedGames.find((transformedGame) => transformedGame.index === sortedGame.index);
                            if (foundGame !== undefined) {
                                return foundGame.points;
                            }

                            return null;
                        })],
                        borderColor: convertHex(foundPlayer.hexColor),
                        backgroundColor: convertHex(foundPlayer.hexColor, 0.5),

                        pointHoverRadius: 6,
                    }
                });

                setGamesChartDataset(dataset);
            }
        }
    }, [props.playerStatistics, props.currentSeason]);

    let dailyData = {
        labels: gamesLabels,
        datasets: gamesChartDataset,
    };

    return <Box mb={1}>
        <Box display={'flex'}>
            <Box display={'flex'} sx={{width: '100%'}}>
                <Typography sx={{fontSize: '1.25rem', fontWeight: 'bold'}}>Wykres</Typography>
            </Box>
        </Box>
        <Box style={{minHeight: '400px'}}>
            <Line options={dailyOptions} data={dailyData} />
        </Box>
    </Box>;
}
