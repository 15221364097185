import * as React from "react";
import Box from "@mui/material/Box";
import Identicon from "../Identicon";
import {Typography, useTheme} from "@mui/material";
import useAppNavigate from "../../App/useAppNavigate";
import {useParams} from "react-router-dom";
import AxiosGet from "../../AxiosGet";
import {useCookies} from "react-cookie";
import Headed from "./Show/Headed";
import {DataGrid, gridClasses} from "@mui/x-data-grid";
import {TrendingDown, TrendingUp} from "@mui/icons-material";
import AllInclusiveIcon from "@mui/icons-material/AllInclusive";
import {alpha} from "@mui/material/styles";
import Grid from '@mui/material/Unstable_Grid2';
import StatsItem from "./Show/StatsItem";
import Head2HeadChart from "./Show/Head2HeadChart";

export default function Show() {
    let { identifier, playerIdentifier } = useParams();
    const theme = useTheme();
    const [cookies, setCookie, removeCookie] = useCookies(['accessToken']);
    const [league, setLeague] = React.useState({
        players: [{identifier: '', name: ''}],
        seasons: [
            {ranking: [{playerIdentifier: '', score: 0}], games: [{playerOneIdentifier: '', playerTwoIdentifier: ''}]}
        ]
    });
    const [player, setPlayer] = React.useState({
        identifier: '',
        name: '',
        active: true,
        imagesDir: undefined,
    });
    const [playerRanking, setPlayerRanking] = React.useState({
        score: 0,
        wins: 0,
        loses: 0,
        ties: 0,
        streak: 0,
    });
    const [playerStatistics, setPlayerStatistics] = React.useState({
        identifier: '',
        seasonHeads: [{
            headedGames: [],
            headedStatistics: [],
            seasonIdentifier: '',
        }],
    });
    const [currentSeason, setCurrentSeason] = React.useState('');
    const [activeSeason, setActiveSeason] = React.useState('');
    const [loaded, setLoaded] = React.useState({
        league: false,
        playerStatistics: false,
    });

    const navigate = useAppNavigate();

    const changeLoadedState = (newState) => {
        setLoaded(prevState => (
            {...prevState, ...newState}
        ))
    }

    const fetchLeague = () => {
        AxiosGet('/leagues/' + identifier, cookies.accessToken).then((response) => {
            setLeague(response.data);
            changeLoadedState({league: true});
            let playerData = response.data.players.find((player) => player.identifier === playerIdentifier);
            if (playerData !== undefined) {
                setPlayer(playerData);
            }

            let activeSeason = response.data.seasons.find(season => season.active === true)
            if (activeSeason === undefined) {
                setCurrentSeason(response.data.seasons.at(-1).identifier);

                return;
            }

            let playerRankingData = activeSeason.ranking.find(rankingData => rankingData.playerIdentifier === playerIdentifier);
            if (playerRankingData !== undefined) {
                setPlayerRanking(playerRankingData);
            }

            setActiveSeason(activeSeason.identifier);
            setCurrentSeason(activeSeason.identifier);
        }).catch((error) => {
            changeLoadedState({league: true});
            if (error.response.status === 401) {
                removeCookie('accessToken', {path: '/'});
            }
            if (error.response.status === 404) {
                navigate('/leagues');
            }
        });
    }

    const fetchPlayerStatistics = () => {
        AxiosGet('/leagues/' + identifier + '/players/' + playerIdentifier, cookies.accessToken).then((response) => {
            setPlayerStatistics(response.data);
            changeLoadedState({playerStatistics: true});
        }).catch((error) => {
            changeLoadedState({playerStatistics: true});
            if (error.response.status === 401) {
                removeCookie('accessToken', {path: '/'});
            }
            if (error.response.status === 404) {
                navigate('/leagues');
            }
        });
    }

    React.useEffect(() => {
        fetchLeague();
        fetchPlayerStatistics();
    }, [identifier, playerIdentifier]);

    React.useEffect(() => {
        if (Object.values(loaded).every(item => item === true)) {
            window.dispatchEvent(new Event('contentLoaded'));
        }
    }, [loaded]);

    let headedRows = [];
    if (playerStatistics.identifier !== '') {
        let currentSeasonData = playerStatistics.seasonHeads.find((seasonHead) => seasonHead.seasonIdentifier === currentSeason)
        if (currentSeasonData !== undefined) {
            headedRows = currentSeasonData.headedStatistics.map((headedStatistic, index) => {
                let opponentData = league.players.find(
                    (player) => player.identifier === headedStatistic.opponentIdentifier
                );

                return {
                    id: index + 1,
                    name: opponentData.name,
                    scoreChange: headedStatistic.scoreChange,
                    allGames: headedStatistic.wins + headedStatistic.loses + headedStatistic.ties,
                    wins: headedStatistic.wins,
                    loses: headedStatistic.loses,
                    ties: headedStatistic.ties,
                    winLoseRatio: (headedStatistic.wins / headedStatistic.loses).toFixed(2),
                    imagesDir: opponentData.imagesDir,
                }
            });
        }
    }

    let headedColumns = [];
    if (league.tieable) {
        headedColumns = [
            { field: 'id', headerName: '', width: 50, hide: true, align: 'center'},
            { field: 'name', headerName: 'Oponent', minWidth: 100, flex: 1, hideable: false, renderCell: (params) =>
                <Identicon imagesDir={params.row.imagesDir} name={params.row.name} width={16} height={16} mr={0.5}>{params.row.name}</Identicon>
            },
            { field: 'scoreChange', headerName: 'Elo', width: 90, align: 'center'},
            { field: 'allGames', headerName: 'Gry', width: 90, align: 'center'},
            { field: 'wins', headerName: 'Wygrane', width: 90, align: 'center'},
            { field: 'loses', headerName: 'Przegrane', width: 90, align: 'center'},
            { field: 'ties', headerName: 'Remisy', width: 90, align: 'center'},
            { field: 'winLoseRatio', headerName: 'W/L ratio', width: 90, align: 'center', renderCell: (params) =>
                params.row.winLoseRatio === 'Infinity' ? <AllInclusiveIcon fontSize={'small'} />: params.row.winLoseRatio
            },
        ];
    } else {
        headedColumns = [
            { field: 'id', headerName: '', width: 50, hide: true, align: 'center'},
            { field: 'name', headerName: 'Oponent', minWidth: 100, flex: 1, hideable: false, renderCell: (params) =>
                <Identicon imagesDir={params.row.imagesDir} name={params.row.name} width={16} height={16} mr={0.5}>{params.row.name}</Identicon>
            },
            { field: 'scoreChange', headerName: 'Elo', width: 90, align: 'center'},
            { field: 'allGames', headerName: 'Gry', width: 90, align: 'center'},
            { field: 'wins', headerName: 'Wygrane', width: 90, align: 'center'},
            { field: 'loses', headerName: 'Przegrane', width: 90, align: 'center'},
            { field: 'winLoseRatio', headerName: 'W/L ratio', width: 90, align: 'center', renderCell: (params) =>
                params.row.winLoseRatio === 'Infinity' ? <AllInclusiveIcon fontSize={'small'} />: params.row.winLoseRatio
            },
        ];
    }

    const calculateStanding = () => {
        if (player.identifier === '') {
            return 0;
        }

        if (currentSeason === '') {
            return 0;
        }

        let currentSeasonData = league.seasons.find((season) => season.identifier === currentSeason);

        let standing = currentSeasonData.ranking.sort(function(a, b) {
            if (parseInt(a.score) < parseInt(b.score)) {
                return 1;
            }

            if (parseInt(a.score) > parseInt(b.score)) {
                return -1;
            }

            return 0;
        }).map((rank, index) => {
            return {
                standing: index + 1,
                playerIdentifier: rank.playerIdentifier,
            }
        }).find((rank) => rank.playerIdentifier === player.identifier);
        if (standing === undefined) {
            return 0;
        }

        return standing.standing;
    }

    return <>
        <Box display={'flex'} alignItems={'center'} mt={1} mb={1}>
            <Identicon
                imagesDir={player.imagesDir}
                name={player.name}
                width={64}
                height={64}
                mr={0.5}
                onClick={() => navigate('/leagues/' + identifier + '/players/' + playerIdentifier)}
                sx={{cursor: 'pointer'}}
            />
            <Box>
                <Typography sx={{fontSize: '1.5rem', fontWeight: 'bold'}}>
                    {player.name}
                </Typography>
                <Typography cariant={'caption'} gutterBottom sx={{fontSize: '1.0rem', marginTop: -1, color: theme.palette.grey[700]}}>GRACZ</Typography>
            </Box>
        </Box>
        <Box mb={1}>
            <Typography sx={{fontSize: '1.25rem', fontWeight: 'bold'}}>Statystyki ogólne</Typography>
            <Grid container spacing={1}>
                <StatsItem label={'Miejsce'} value={calculateStanding()}/>
                <StatsItem label={'Ranking'} value={<>{playerRanking.score} {
                    playerRanking.streak < 3 && playerRanking.streak > -3 ? '' : playerRanking.streak > 0 ?
                        <TrendingUp sx={{color: 'success.main'}} /> :
                        <TrendingDown sx={{color: 'error.main'}} />
                }</>}/>
                <StatsItem
                    label={'Seria'}
                    value={
                        <Typography sx={{color: playerRanking.streak < 3 && playerRanking.streak > -3 ? '' : playerRanking.streak > 0 ? 'success.main' : 'error.main'}}>
                            {playerRanking.streak}
                        </Typography>
                    }
                />
                <StatsItem label={'Gry'} value={playerRanking.wins + playerRanking.loses + playerRanking.ties}/>
                <StatsItem label={'Wygrane'} value={<Typography sx={{color: 'success.main'}}>{playerRanking.wins}</Typography>}/>
                <StatsItem label={'Przegrane'} value={<Typography sx={{color: 'error.main'}}>{playerRanking.loses}</Typography>}/>
                <StatsItem
                    label={'Win/Loss ratio'}
                    value={<>{playerRanking.loses === 0 ?
                        <AllInclusiveIcon fontSize={'small'} /> :
                        (playerRanking.wins/playerRanking.loses).toFixed(2)
                    }</>}
                />
            </Grid>
        </Box>
        <Box mb={1}>
            <Typography sx={{fontSize: '1.25rem', fontWeight: 'bold'}}>Head2head</Typography>
            <div style={{ height: 315, width: '100%' }}>
                <div style={{ display: 'flex', height: '100%' }}>
                    <Box
                        sx={{
                            width: '100%',
                            '& .even': {
                                backgroundColor: (theme) => theme.palette.grey[100],
                            },
                            '& .under': {
                                // backgroundColor: 'rgb(239,83,80,0.2)',
                                color: 'rgb(198,40,40)',
                            },
                            '& .above': {
                                // backgroundColor: 'rgb(76,175,80,0.2)',
                                color: 'rgb(27,94,32)',
                            },
                            '& .none': {
                                // backgroundColor: 'rgba(3,169,244,0.2)',
                                color: 'rgb(1,87,155)',
                            },
                            [`& .${gridClasses.row}`]: {
                                '&:hover, &.Mui-hovered': {
                                    backgroundColor: alpha(theme.palette.grey[500], 0.2),
                                    '@media (hover: none)': {
                                        backgroundColor: 'transparent',
                                    },
                                }
                            }
                        }}
                    >
                    <DataGrid
                        rows={headedRows}
                        columns={headedColumns}
                        pageSize={5}
                        rowsPerPageOptions={[5]}
                        disableSelectionOnClick={true}
                        rowHeight={36}
                        getCellClassName={(params) => {
                            if (params.field === 'scoreChange' && params.row.scoreChange === 0) {
                                return 'none';
                            }

                            if (params.field === 'scoreChange' && params.row.scoreChange > 0) {
                                return 'above';
                            }

                            if (params.field === 'scoreChange' && params.row.scoreChange < 0) {
                                return 'under';
                            }

                        }}
                        getRowClassName={(params) => {
                            let classModificator = '';
                            if (params.indexRelativeToCurrentPage%2 === 0) {
                                classModificator += ' even';
                            }

                            return classModificator;
                        }}
                        disableColumnMenu={true}
                        initialState={{
                            sorting: {
                                sortModel: [{ field: 'name', sort: 'asc' }],
                            },
                        }}
                    />
                    </Box>
                </div>
            </div>
        </Box>
        <Head2HeadChart playerStatistics={playerStatistics} currentSeason={currentSeason} league={league} />
    </>
}
