import * as React from "react";
import './App.css';
import {
    Routes,
    Route,
    Navigate,
} from "react-router-dom";
import {useCookies} from "react-cookie";
import {default as GamesCreate} from "./Games/Create";
import {default as LeaguesCreate} from "./Leagues/Create";
import {default as LeaguesGetAll} from "./Leagues/GetAll";
import GetOne from "./Leagues/GetOne";
import LeagueEdit from "./Leagues/Edit";
import {default as LeagueInvite} from "./Leagues/Invite";
import Players from "./Leagues/Players";
import {default as PlayersConnect} from "./Leagues/Players/Connect";
import {default as PlayersCreate} from "./Leagues/Players/Create";
import {default as PlayersEdit} from "./Leagues/Players/Edit";
import Layout from "./Layout";
import Login from "./Login";
import Profile from "./Users/Profile";
import ChangePassword from "./Users/Profile/ChangePassword";
import Invite from "./Invite/Invite";
import Edit from "./Users/Profile/Edit";
import Seasons from "./Leagues/Seasons";
import WebSocketDemo from "./WebSocketDemo";
import {useEffect} from "react";
import LiveFeed from "./Feed/LiveFeed";
import AisoAxiosGet from "./AisoAxiosGet";
import useWebSocket from "react-use-websocket";
import {createTheme} from "@mui/material";
import {ThemeProvider} from "@mui/system";
import {Helmet, HelmetProvider} from "react-helmet-async";
import Show from "./Leagues/Players/Show";

const theme = createTheme({
    typography: {
        htmlFontSize: 14,
        fontSize: 12,
        h1: {
            fontSize: '5.1rem'
        },
        h2: {
            fontSize: '3.2rem'
        },
        h3: {
            fontSize: '2.55rem'
        },
        h4: {
            fontSize: '1.8rem'
        },
        h5: {
            fontSize: '1.375rem'
        },
        h6: {
            fontSize: '1.05rem'
        },
        subtitle1: {
            fontSize: '0.85rem'
        },
        subtitle2: {
            fontSize: '0.75rem'
        },
        body1: {
            fontSize: '0.85rem',
        },
        body2: {
            fontSize: '0.75rem'
        },
        button: {
            fontSize: '0.75rem'
        },
        caption: {
            fontSize: '0.65rem',
        },
        overline: {
            fontSize: '0.65rem'
        },
    },
})

export default function App() {
    const [cookies, setCookie, removeCookie] = useCookies(['accessToken']);
    const isAccessTokenExist = cookies.accessToken !== undefined;

    const fetchFeed = (accessToken) => {
        AisoAxiosGet('/feed', accessToken).then((response) => {
            localStorage.setItem('feed', JSON.stringify(response.data));
            window.dispatchEvent(new Event('feedChanged'));
        }).catch((error) => {
            if (error.response.status === 401) {
                removeCookie('accessToken', {path: '/'});
            }
        });
    }

    const {sendMessage, lastMessage, readyState} = useWebSocket(
        process.env.REACT_APP_AISO_SOCKET_URL + '/feed?access_token=' + cookies.accessToken,
        {
            onOpen: () => {
                fetchFeed(cookies.accessToken);
            },
            shouldReconnect: (closeEvent) => true,
        }
    );

    useEffect(() => {
        if (lastMessage !== null) {
            let feed = JSON.parse(localStorage.getItem('feed'));
            feed.unshift(JSON.parse(lastMessage.data))
            localStorage.setItem('feed', JSON.stringify(feed));
            window.dispatchEvent(new Event('newFeedData'));
        }
    }, [lastMessage]);

    useEffect(() => {
        const interval = setInterval(() => {
            sendMessage(JSON.stringify({active: true}));
        }, process.env.REACT_APP_AISO_SOCKET_CONTROL_MESSAGE_INTERVAL);
        return () => clearInterval(interval);
    }, []);

    return (
        <div>
            <HelmetProvider>
                <Helmet>
                    <title>{process.env.REACT_APP_NAME}</title>
                    <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
                </Helmet>
                <ThemeProvider theme={theme}>
                <Routes>
                    <Route path="/" element={isAccessTokenExist ? <Layout /> : <Login />}>
                        <Route index element={<AuthRoute accessTokenExist={isAccessTokenExist}><LiveFeed /></AuthRoute>} />
                        <Route path="leagues" element={<AuthRoute accessTokenExist={isAccessTokenExist}><LeaguesGetAll /></AuthRoute>} />
                        <Route path="leagues/create" element={<AuthRoute accessTokenExist={isAccessTokenExist}><LeaguesCreate /></AuthRoute>} />
                        <Route path="leagues/:identifier" element={<AuthRoute accessTokenExist={isAccessTokenExist}><GetOne /></AuthRoute>} />
                        <Route path="leagues/:identifier/edit" element={<AuthRoute accessTokenExist={isAccessTokenExist}><LeagueEdit /></AuthRoute>} />
                        <Route path="leagues/:identifier/invite" element={<AuthRoute accessTokenExist={isAccessTokenExist}><LeagueInvite /></AuthRoute>} />
                        <Route path="leagues/:identifier/players" element={<AuthRoute accessTokenExist={isAccessTokenExist}><Players /></AuthRoute>} />
                        <Route path="leagues/:identifier/players/create" element={<AuthRoute accessTokenExist={isAccessTokenExist}><PlayersCreate /></AuthRoute>} />
                        <Route path="leagues/:identifier/players/:playerIdentifier" element={
                            <AuthRoute accessTokenExist={isAccessTokenExist}><Show /></AuthRoute>
                        } />
                        <Route path="leagues/:identifier/players/:playerIdentifier/connect" element={
                            <AuthRoute accessTokenExist={isAccessTokenExist}><PlayersConnect /></AuthRoute>
                        } />
                        <Route path="leagues/:identifier/players/:playerIdentifier/edit" element={
                            <AuthRoute accessTokenExist={isAccessTokenExist}><PlayersEdit /></AuthRoute>
                        } />
                        <Route path="leagues/:identifier/seasons" element={<AuthRoute accessTokenExist={isAccessTokenExist}><Seasons /></AuthRoute>} />
                        <Route path="games/create" element={<AuthRoute accessTokenExist={isAccessTokenExist}><GamesCreate /></AuthRoute>} />
                        <Route path="profile" element={<AuthRoute accessTokenExist={isAccessTokenExist}><Profile /></AuthRoute>} />
                        <Route path="profile/edit" element={<AuthRoute accessTokenExist={isAccessTokenExist}><Edit /></AuthRoute>} />
                        <Route path="profile/password" element={<AuthRoute accessTokenExist={isAccessTokenExist}><ChangePassword /></AuthRoute>} />
                        <Route path="websocket" element={<AuthRoute accessTokenExist={isAccessTokenExist}><WebSocketDemo /></AuthRoute>} />
                        <Route path="invite/:hash" element={<AuthRoute accessTokenExist={isAccessTokenExist}><Invite /></AuthRoute>} />
                        <Route path="*" element={<Navigate to="/" />} />
                    </Route>
                </Routes>
                </ThemeProvider>
            </HelmetProvider>
        </div>
    );
}

function AuthRoute(props) {
    if (!props.accessTokenExist) {
        return <Navigate to="/" />
    }

    return props.children;
}

function Home() {
    window.dispatchEvent(new Event('contentLoaded'));

    return (
        <div>
            <h1>Home</h1>
        </div>
    );
}

function About() {
    return (
        <div>
            <h1>About</h1>
        </div>
    );
}
