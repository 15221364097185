import * as React from "react";
import BarChartIcon from "@mui/icons-material/BarChart";
import BaseElement from "../../../BaseElement";

export default function All(props) {
    const player = props.league.players.find((player) => player.identifier === props.feedEntry.payload.playerIdentifier);

    return <BaseElement
        timestamp={props.feedEntry.publishedAt}
        name={props.league.name}
        icon={<BarChartIcon color={'info'} fontSize={'small'} />}
        isSeen={props.feedEntry.isSeen}
    >{player.name} rozegrał(a) {props.feedEntry.payload.gamesCount}. grę.</BaseElement>
}