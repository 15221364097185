import {useLocation, useNavigate} from "react-router-dom";

export default function useAppNavigate() {
    const navigate = useNavigate();
    const currentLocation = useLocation();

    return function (newLocation) {
        if (currentLocation.pathname !== newLocation) {
            window.dispatchEvent(new Event('contentLoading'));
        }

        navigate(newLocation);
    }
}