import axios from "axios";

export default function AxiosGet(endpoint, accessToken, payload = {}) {
    let requestConfig;
    requestConfig = {
        headers: {
            'X-AUTH-TOKEN': accessToken
        },
        params: payload
    };

    return axios.get(process.env.REACT_APP_AISO_API_URL + endpoint, requestConfig);
}