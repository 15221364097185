import * as React from 'react';
import AxiosGet from "../../AxiosGet";
import {useCookies} from "react-cookie";
import {Button, ButtonGroup, FormControl, RadioGroup, TextField} from "@mui/material";
import FormLabel from "@mui/material/FormLabel";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import Stack from "@mui/material/Stack";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

export default function Attributes(props) {
    const [leagueAttributes, setLeagueAttributes] = React.useState([]);

    React.useEffect(() => {
        let attributesPayloadBuild = [];
        let attributesErrorsBuild = [];
        if (props.selectedLeague !== '') {
            setLeagueAttributes(props.attributes);
            attributesErrorsBuild = props.attributes.map(attribute => {
                return {
                    identifier: attribute.identifier,
                    error: false,
                };
            });

            props.setAttributesErrors(attributesErrorsBuild);
            attributesPayloadBuild = props.attributes.map(attribute => {
                return {
                    identifier: attribute.identifier,
                    values: []
                };
            });
            props.setAttributesPayload(attributesPayloadBuild);
        }
    }, [props.selectedLeague, props.attributes]);

    const handleChange = (event) => {
        changeAttributeValue(event.target.name, event.target.value);
    };

    const findAttributeValue = (identifier) => {
        let player = '';
        if (identifier.includes('PLAYER')) {
            let splitIdentifier = identifier.split('.');
            identifier = splitIdentifier[0];
            player = splitIdentifier[1];
        }

        let attribute = leagueAttributes.find(attribute => attribute.identifier === identifier);
        let attributePayload = props.attributesPayload.find(attribute => attribute.identifier === identifier);
        if (attribute.refersTo === 'GAME') {
            if (attribute.type === 'DICTIONARY') {
                if (attributePayload.values.length === 0) {
                    return '';
                }

                return attributePayload.values[0].dictionaryIdentifier;
            }
        }

        if (attribute.refersTo === 'PLAYERS') {
            if (attribute.type === 'RANGE') {
                if (attributePayload.values.length === 0) {
                    return '';
                }

                let attributePayloadValue = attributePayload.values.find(value => value.refersTo === player);
                if (attributePayloadValue === undefined) {
                    return '';
                }

                return attributePayloadValue.value;
            }
        }

        return '';
    };

    const findAttributeError = (identifier) => {
        let attribute = props.attributesErrors.find(attribute => attribute.identifier === identifier);
        if (attribute === undefined) {
            return false;
        }

        return attribute.error;
    };

    const onClickAdd = (identifier, modifier = 1) => {
        let value = findAttributeValue(identifier)
        if (value !== '' && !isNaN(value)) {
            changeAttributeValue(identifier, value + modifier);

            return;
        }

        changeAttributeValue(identifier, modifier);
    }

    const onClickSubtract = (identifier, modifier = 1) => {
        let value = findAttributeValue(identifier)
        if (value !== '' && !isNaN(value) && value >= modifier) {
            changeAttributeValue(identifier, value - modifier);

            return;
        }

        changeAttributeValue(identifier, 0);
    }

    const handleNumberInputChange = (event) => {
        if (!(/[0-9]/.test(event.key) || event.key === 'Backspace' || event.key === 'Delete')) {
            event.preventDefault();
        }

        changeAttributeValue(event.target.name, event.target.value);
    }

    const changeAttributeValue = (identifier, value) => {
        let player = '';
        if (identifier.includes('PLAYER')) {
            let splitIdentifier = identifier.split('.');
            identifier = splitIdentifier[0];
            player = splitIdentifier[1];
        }

        let attribute = leagueAttributes.find(attribute => attribute.identifier === identifier);
        let otherAttributesPayload = props.attributesPayload.filter(attribute => attribute.identifier !== identifier);
        let changedAttribute = {};
        if (attribute.refersTo === 'GAME') {
            if (attribute.type === 'DICTIONARY') {
                changedAttribute = {
                    identifier: attribute.identifier,
                    values: [
                        {
                            dictionaryIdentifier: value,
                            refersTo: attribute.refersTo
                        }
                    ]
                };
            }
        }

        if (attribute.refersTo === 'PLAYERS') {
            let attributePayload = props.attributesPayload.find(attribute => attribute.identifier === identifier);
            let otherAttributeValuesPayload = attributePayload.values.filter(value => value.refersTo !== player);
            if (attribute.type === 'RANGE') {
                changedAttribute = {
                    identifier: attribute.identifier,
                    values: [
                        {
                            value: parseInt(value),
                            refersTo: player
                        },
                        ...otherAttributeValuesPayload
                    ]
                };
            }
        }

        props.setAttributesPayload([changedAttribute, ...otherAttributesPayload]);
    }

    let attributesForm = <></>;
    attributesForm = leagueAttributes.map(attribute => {
        let input = '';
        if (attribute.refersTo === 'GAME') {
            if (attribute.type === 'DICTIONARY') {
                let attributeDictionaryForm = [];
                let attributeDictionary = attribute.dictionary.sort((a,b) => a.order - b.order);
                attributeDictionaryForm = attributeDictionary.map(dictionaryEntry => {
                    return <FormControlLabel key={dictionaryEntry.identifier} value={dictionaryEntry.identifier} control={<Radio />} label={dictionaryEntry.name} />;
                })
                input = <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name={attribute.identifier}
                    value={findAttributeValue(attribute.identifier)}
                    onChange={handleChange}
                >
                    {attributeDictionaryForm}
                </RadioGroup>;
            }

            if (attribute.type === 'RANGE') {
                input = 'range'
            }

            return <FormControl fullWidth key={attribute.identifier} error={findAttributeError(attribute.identifier)}>
                <FormLabel id={attribute.identifier}>{attribute.name}</FormLabel>
                {input}
            </FormControl>
        }

        if (attribute.refersTo === 'PLAYERS') {
            let rangeValues = [];
            let rangeForm = [];
            for (let i = attribute.valueFrom; i <= attribute.valueTo; i++) {
                rangeValues.push(i)
            }
            if (rangeValues.length < 6) {
                rangeForm = rangeValues.map(rangeItem => {
                    return <FormControlLabel key={rangeItem} value={rangeItem} control={<Radio />} label={rangeItem} />;
                })

                return <>
                    <FormControl fullWidth key={attribute.identifier + '.PLAYER_ONE'} error={findAttributeError(attribute.identifier)}>
                        <FormLabel id={attribute.identifier + '.PLAYER_ONE'}>{attribute.name} - gracz 1</FormLabel>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name={attribute.identifier + '.PLAYER_ONE'}
                            value={findAttributeValue(attribute.identifier + '.PLAYER_ONE')}
                            onChange={handleChange}
                        >
                            {rangeForm}
                        </RadioGroup>
                    </FormControl>
                    <FormControl fullWidth key={attribute.identifier + '.PLAYER_TWO'} error={findAttributeError(attribute.identifier)}>
                        <FormLabel id={attribute.identifier + '.PLAYER_TWO'}>{attribute.name} - gracz 2</FormLabel>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name={attribute.identifier + '.PLAYER_TWO'}
                            value={findAttributeValue(attribute.identifier + '.PLAYER_TWO')}
                            onChange={handleChange}
                        >
                            {rangeForm}
                        </RadioGroup>
                    </FormControl>
                </>;
            }

            return <>
                <FormControl key={attribute.identifier + '.PLAYER_ONE'} error={findAttributeError(attribute.identifier)}>
                    <FormLabel id={attribute.identifier + '.PLAYER_ONE'}>{attribute.name} - gracz 1</FormLabel>
                    <Stack spacing={1} direction="row" mt={1}>
                        <Button variant={'contained'} onClick={() => onClickSubtract(attribute.identifier + '.PLAYER_ONE')}><RemoveIcon /></Button>
                        <TextField
                            label={attribute.name}
                            name={attribute.identifier + '.PLAYER_ONE'}
                            variant={'outlined'}
                            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                            value={findAttributeValue(attribute.identifier + '.PLAYER_ONE')}
                            onChange={handleNumberInputChange}
                            type={'number'}
                            sx={{width: 96}}
                        />
                        <Button variant={'contained'} onClick={() => onClickAdd(attribute.identifier + '.PLAYER_ONE')}><AddIcon /></Button>
                    </Stack>
                </FormControl>
                <FormControl fullWidth key={attribute.identifier + '.PLAYER_TWO'} error={findAttributeError(attribute.identifier)}>
                    <FormLabel id={attribute.identifier + '.PLAYER_TWO'}>{attribute.name} - gracz 2</FormLabel>
                    <Stack spacing={1} direction="row" mt={1}>
                        <Button variant={'contained'} onClick={() => onClickSubtract(attribute.identifier + '.PLAYER_TWO')}><RemoveIcon /></Button>
                        <TextField
                            label={attribute.name}
                            name={attribute.identifier + '.PLAYER_TWO'}
                            variant={'outlined'}
                            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                            value={findAttributeValue(attribute.identifier + '.PLAYER_TWO')}
                            onChange={handleNumberInputChange}
                            type={'number'}
                            sx={{width: 96}}
                        />
                        <Button variant={'contained'} onClick={() => onClickAdd(attribute.identifier + '.PLAYER_TWO')}><AddIcon /></Button>
                    </Stack>
                </FormControl>
            </>;
        }
    })

    return (
        <Stack spacing={2}>
            {attributesForm}
        </Stack>
    )
}