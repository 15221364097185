import React, { useState } from 'react';
import Divider from "./Divider";
import Test from "./BaseElement/Test";
import Welcome from "./BaseElement/Welcome";
import Win from "./BaseElement/Game/Win";
import Lose from "./BaseElement/Game/Lose";
import Tie from "./BaseElement/Game/Tie";
import All from "./BaseElement/Season/Statistic/All";
import Wins from "./BaseElement/Season/Statistic/Wins";
import Loses from "./BaseElement/Season/Statistic/Loses";
import Ties from "./BaseElement/Season/Statistic/Ties";
import moment from "moment/moment";
import InfiniteScroll from "react-infinite-scroll-component";
import AisoAxiosGet from "../AisoAxiosGet";
import {useCookies} from "react-cookie";
import {Box, CircularProgress} from "@mui/material";
import Demo from "./BaseElement/Demo";

export default function LiveFeed() {
    const [cookies, setCookie, removeCookie] = useCookies(['accessToken']);
    const [leagues, setLeagues] = useState(JSON.parse(localStorage.getItem('leagues')));
    const [syncedFeed, setSyncedFeed] = useState(JSON.parse(localStorage.getItem('feed')) ?? []);
    const [socketFeed, setSocketFeed] = useState([]);
    const [lastMessage, setLastMessage] = useState(null);
    const [infiniteScrollLastDate, setInfiniteScrollLastDate] = useState(
        syncedFeed.at(-1) !== undefined ? syncedFeed.sort(function(a, b) {
        var dateA = new Date(a.publishedAt),
            dateB = new Date(b.publishedAt);
        if (dateA < dateB) {
            return 1;
        }

        if (dateA > dateB) {
            return -1;
        }

        return 0;
    }).at(-1).publishedAt : null);
    const [infiniteScrollFeed, setInfiniteScrollFeed] = useState([]);
    const [infiniteScrollFeedHasMore, setInfiniteScrollFeedHasMore] = useState(syncedFeed.at(-1) !== undefined);
    const ref = React.useRef();

    const fetchFeed = () => {
        AisoAxiosGet('/feed?last_date_time=' + infiniteScrollLastDate, cookies.accessToken).then((response) => {
            setInfiniteScrollFeed((previousState) => ([...previousState, ...response.data]));
            if (response.data.at(-1) === undefined) {
                setInfiniteScrollFeedHasMore(false);
                return;
            }

            setInfiniteScrollLastDate(response.data.sort(function(a, b) {
                var dateA = new Date(a.publishedAt),
                    dateB = new Date(b.publishedAt);
                if (dateA < dateB) {
                    return 1;
                }

                if (dateA > dateB) {
                    return -1;
                }

                return 0;
            }).at(-1).publishedAt)
        }).catch((error) => {
            if (error.response.status === 401) {
                removeCookie('accessToken', {path: '/'});
            }
        });
    }

    let lastDate = moment();

    const mapFeedEntries = (feed) => {
        return feed.map((feedEntry) => {
            let elements = [];
            let publishedAtDate = moment(feedEntry.publishedAt, 'YYYY-MM-DD HH:mm:ss')
                .utc(true)
                .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
            let nowDate = lastDate
            if (publishedAtDate.format('YYYY') < nowDate.format('YYYY') ||
                (publishedAtDate.format('YYYY') === nowDate.format('YYYY') && publishedAtDate.format('MM') < nowDate.format('MM')) ||
                (publishedAtDate.format('YYYY') === nowDate.format('YYYY') && publishedAtDate.format('MM') === nowDate.format('MM') && publishedAtDate.format('DD') < nowDate.format('DD'))
            ) {
                elements.push(<Divider key={feedEntry.identifier + '-divider'}>{publishedAtDate.format('DD.MM.YYYY')}</Divider>)
            }

            if (feedEntry.payload.type === 'SEASON.STATISTIC.PLAYER.ALL') {
                let league = leagues.find((league) => league.identifier === feedEntry.refersTo.identifier)
                if (league !== undefined) {
                    elements.push(<All key={feedEntry.identifier} feedEntry={feedEntry} league={league}/>)
                }
            }

            if (feedEntry.payload.type === 'SEASON.STATISTIC.PLAYER.WINS') {
                let league = leagues.find((league) => league.identifier === feedEntry.refersTo.identifier)
                if (league !== undefined) {
                    elements.push(<Wins key={feedEntry.identifier} feedEntry={feedEntry} league={league}/>)
                }
            }

            if (feedEntry.payload.type === 'SEASON.STATISTIC.PLAYER.LOSES') {
                let league = leagues.find((league) => league.identifier === feedEntry.refersTo.identifier)
                if (league !== undefined) {
                    elements.push(<Loses key={feedEntry.identifier} feedEntry={feedEntry} league={league}/>)
                }
            }

            if (feedEntry.payload.type === 'SEASON.STATISTIC.PLAYER.TIES') {
                let league = leagues.find((league) => league.identifier === feedEntry.refersTo.identifier)
                if (league !== undefined) {
                    elements.push(<Ties key={feedEntry.identifier} feedEntry={feedEntry} league={league}/>)
                }
            }

            if (feedEntry.payload.type === 'GAME.LOSE') {
                let league = leagues.find((league) => league.identifier === feedEntry.refersTo.identifier)
                if (league !== undefined) {
                    elements.push(<Lose key={feedEntry.identifier} feedEntry={feedEntry} league={league} />)
                }
            }

            if (feedEntry.payload.type === 'GAME.TIE') {
                let league = leagues.find((league) => league.identifier === feedEntry.refersTo.identifier)
                if (league !== undefined) {
                    elements.push(<Tie key={feedEntry.identifier} feedEntry={feedEntry} league={league} />)
                }
            }

            if (feedEntry.payload.type === 'GAME.WIN') {
                let league = leagues.find((league) => league.identifier === feedEntry.refersTo.identifier)
                if (league !== undefined) {
                    elements.push(<Win key={feedEntry.identifier} feedEntry={feedEntry} league={league} />)
                }
            }

            if (feedEntry.payload.type === 'TEST') {
                elements.push(<Test key={feedEntry.identifier} feedEntry={feedEntry}/>)
            }

            if (feedEntry.payload.type === 'DEMO') {
                elements.push(<Demo key={feedEntry.identifier} feedEntry={feedEntry}/>)
            }

            if (feedEntry.payload.type === 'WELCOME') {
                elements.push(<Welcome key={feedEntry.identifier} feedEntry={feedEntry}/>)
            }

            lastDate = publishedAtDate

            return elements
        })
    }

    React.useEffect(() => {
        window.dispatchEvent(new Event('contentLoaded'));
    }, []);

    window.addEventListener('newFeedData', () => {
        let newFeed = JSON.parse(localStorage.getItem('feed'));
        let newFeedData = newFeed.find(newFeedData => {
            let duplicate = syncedFeed.find(feedData => newFeedData.identifier === feedData.identifier)
            if (duplicate !== undefined) {
                return;
            }

            return newFeedData;
        });

        setLastMessage(newFeedData);
    });

    window.addEventListener('feedChanged', () => {
        setSocketFeed([]);
        setSyncedFeed(JSON.parse(localStorage.getItem('feed')));
    })

    let mappedSyncedFeed = []
    if (syncedFeed !== null) {
        mappedSyncedFeed = mapFeedEntries(syncedFeed.sort(function(a, b) {
            var dateA = new Date(a.publishedAt),
                dateB = new Date(b.publishedAt);
            if (dateA < dateB) {
                return 1;
            }

            if (dateA > dateB) {
                return -1;
            }

            return 0;
        }));
    }

    React.useEffect(() => {
        if (lastMessage !== null) {
            setSocketFeed((prevState) => {
                const newArray = [...prevState];
                newArray.unshift(lastMessage);

                return newArray;
            })
        }
    }, [lastMessage])

    // const trigger = useScrollTrigger();
    // const scrollToTop = React.useCallback(() => {
    //     window.scrollTo({
    //         top: 0,
    //         behavior: "smooth",
    //     });
    // }, []);

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div ref={ref}>
            {/*<Box sx={{marginX: -1}}>*/}
            {/*    <Tabs*/}
            {/*        value={value}*/}
            {/*        onChange={handleChange}*/}
            {/*        variant="scrollable"*/}
            {/*        scrollButtons={false}*/}
            {/*        allowScrollButtonsMobile*/}
            {/*    >*/}
            {/*        <Box sx={{marginX: 0.5}}><Item league={leagues[0]} /></Box>*/}
            {/*        <Box sx={{marginX: 0.5}}><Item league={leagues[1]} /></Box>*/}
            {/*        <Box sx={{marginX: 0.5}}><Item league={leagues[2]} /></Box>*/}
            {/*        <Box sx={{marginX: 0.5}}><Item league={leagues[3]} /></Box>*/}
            {/*    </Tabs>*/}
            {/*</Box>*/}
            {mapFeedEntries(socketFeed)}
            {mappedSyncedFeed}
            <InfiniteScroll
                dataLength={infiniteScrollFeed.length}
                next={() => fetchFeed()}
                hasMore={(document.body.clientHeight > window.innerHeight && infiniteScrollFeedHasMore)}
                loader={
                    <Box sx={{ display: 'flex', overflow: 'hidden', justifyContent: 'center', marginY: 2}}><CircularProgress /></Box>
                }
                endMessage={<></>}
            >
                {mapFeedEntries(infiniteScrollFeed)}
            </InfiniteScroll>
            {/*<Zoom in={trigger}>*/}
            {/*    <Box*/}
            {/*        role="presentation"*/}
            {/*        sx={{*/}
            {/*            position: "fixed",*/}
            {/*            bottom: 100,*/}
            {/*            left: '50%',*/}
            {/*            marginLeft: '-20px',*/}
            {/*            zIndex: 1,*/}
            {/*        }}*/}
            {/*    >*/}
            {/*        <Fab*/}
            {/*            onClick={scrollToTop}*/}
            {/*            color="primary"*/}
            {/*            size="small"*/}
            {/*            aria-label="scroll back to top"*/}
            {/*        >*/}
            {/*            <KeyboardArrowUp />*/}
            {/*        </Fab>*/}
            {/*    </Box>*/}
            {/*</Zoom>*/}
        </div>
    );
}