import * as React from 'react';
import AxiosGet from "../AxiosGet";
import {useCookies} from "react-cookie";
import ContextMenu from "./GetAll/ContextMenu";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Item from "./GetAll/Item";

export default function GetAll() {
    const [cookies, setCookie, removeCookie] = useCookies(['currentLeague', 'accessToken']);
    const [currentLeague] = React.useState(localStorage.getItem('currentLeague'));
    const [leagues, setLeagues] = React.useState([]);

    React.useEffect(() => {
        AxiosGet('/leagues', cookies.accessToken).then((response) => {
            let pinnedLeague = response.data.find(league => league.identifier === currentLeague);
            let otherLeagues = response.data.filter(league => league.identifier !== currentLeague);
            let sortedLeagues = [];
            if (pinnedLeague !== undefined) {
                sortedLeagues.push(pinnedLeague)
            }

            otherLeagues.sort((a, b) => {
                const nameA = a.name.toUpperCase();
                const nameB = b.name.toUpperCase();
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }

                return 0;
            });

            otherLeagues.forEach(league => sortedLeagues.push(league));

            setLeagues(sortedLeagues);
            localStorage.setItem('leagues', JSON.stringify(response.data));
            window.dispatchEvent(new Event('leaguesChanged'));
            window.dispatchEvent(new Event('contentLoaded'));
        }).catch((error) => {
            if (error.response.status === 401) {
                removeCookie('accessToken', {path: '/'});
            }
        });
    }, [cookies.accessToken, removeCookie, currentLeague]);

    function findCurrentTop(league) {
        if (league.seasons.length === 0) {
            return '';
        }
        let season = league.seasons.find(season => season.active === true)
        if (season === undefined || season.ranking.length === 0) {
            return '';
        }

        let sortedRanking = season.ranking.sort(function(a, b) {
            if (parseInt(a.score) < parseInt(b.score)) {
                return 1;
            }

            if (parseInt(a.score) > parseInt(b.score)) {
                return -1;
            }

            return 0;
        })

        let topPlayer = league.players.find(player => player.identifier === sortedRanking[0].playerIdentifier)

        return topPlayer.name;
    }

    let leaguesRows = [];
    if (leagues.length > 0) {
        leaguesRows = leagues.map((league, index) => {
            return {
                index: index,
                identifier: league.identifier,
                name: league.name,
                imagesDir: league.imagesDir,
                playersCount: league.players.filter(player => player.active === true).length,
                seasonsCount: league.seasons.length,
                isStarred: league.identifier === currentLeague,
                currentTop: findCurrentTop(league),
            }
        });
    }

    return (
        <Box sx={{position: 'relative'}}>
            <h2>Ligi </h2>
            <ContextMenu />
            <Grid container rowSpacing={1}>
                {leaguesRows.map(
                    leagueRow => <Grid item xs={12} key={leagueRow.identifier}><Item league={leagueRow} /></Grid>
                )}
            </Grid>
        </Box>
    );
}