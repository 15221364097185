import * as React from "react";
import {Box, Button, Fab, FormControl, InputLabel, OutlinedInput, Typography} from "@mui/material";
import AxiosGet from "../AxiosGet";
import {useParams} from "react-router-dom";
import {useCookies} from "react-cookie";
import useAppNavigate from "../App/useAppNavigate";
import Header from "./GetOne/Header";
import Stack from "@mui/material/Stack";
import SaveIcon from "@mui/icons-material/Save";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import AxiosPost from "../AxiosPost";
import AxiosPut from "../AxiosPut";

export default function Edit() {
    let { identifier } = useParams();
    const [cookies, setCookie, removeCookie] = useCookies(['accessToken']);
    const [league, setLeague] = React.useState([]);
    const [currentSeason, setCurrentSeason] = React.useState('');
    const [activeSeason, setActiveSeason] = React.useState('');
    const [leagueName, setLeagueName] = React.useState('');
    const [leagueNameError, setLeagueNameError] = React.useState(false);
    const [leagueTieable, setLeagueTieable] = React.useState('');
    const [leagueTieableError, setLeagueTieableError] = React.useState(false);
    const navigate = useAppNavigate();

    const fetchLeague = () => {
        AxiosGet('/leagues/' + identifier, cookies.accessToken).then((response) => {
            setLeague(response.data);
            setLeagueName(response.data.name);
            setLeagueTieable(response.data.tieable);
            let activeSeason = response.data.seasons.find(season => season.active === true)
            if (activeSeason === undefined) {
                setActiveSeason('');
                window.dispatchEvent(new Event('contentLoaded'));

                return;
            }

            setActiveSeason(activeSeason.identifier);
            window.dispatchEvent(new Event('contentLoaded'));
        }).catch((error) => {
            if (error.response.status === 401) {
                removeCookie('accessToken', {path: '/'});
            }
            if (error.response.status === 404) {
                navigate('/leagues');
            }
        });
    }

    React.useEffect(() => {
        fetchLeague();
    }, [identifier]);

    const handleLeagueNameChange = (event) => {
        setLeagueName(event.target.value);
        setLeagueNameError(false);
    };

    const handleLeagueTieableChange = (event) => {
        setLeagueTieable(event.target.value);
        setLeagueTieableError(false);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (leagueName === '') {
            setLeagueNameError(true);
        }

        if (leagueTieable === '') {
            setLeagueTieableError(true);
        }

        let payload = {
            name: leagueName,
            tieable: leagueTieable,
        };

        AxiosPut('/leagues/' + identifier, cookies.accessToken, payload)
            .then((response) => {
                navigate('/leagues/' + identifier);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    return <Box sx={{position: 'relative'}}>
        <Header identifier={identifier} league={league} activeSeason={activeSeason} setActiveSeason={setActiveSeason} />
        <Typography sx={{fontSize: '1.25rem', fontWeight: 'bold'}}>Ustawienia</Typography>
        <Typography sx={{fontSize: '1rem', fontWeight: 'bold', marginBottom: 2}}>Liga</Typography>
        <form onSubmit={handleSubmit}>
            <Stack spacing={2}>
                <FormControl fullWidth error={leagueNameError}>
                    <InputLabel htmlFor="outlined-adornment-amount">Nazwa ligi</InputLabel>
                    <OutlinedInput
                        id="outlined-adornment-amount"
                        value={leagueName}
                        label="Nazwa ligi"
                        onChange={handleLeagueNameChange}
                    />
                </FormControl>
                <FormControl fullWidth error={leagueTieableError}>
                    <InputLabel id="demo-simple-select-label1">Remisy</InputLabel>
                    <Select
                        labelId="tieable"
                        id="demo-simple-select1"
                        label="Remisy"
                        value={leagueTieable}
                        onChange={handleLeagueTieableChange}
                        disabled={activeSeason !== ''}
                    >
                        <MenuItem value={true}>Tak</MenuItem>
                        <MenuItem value={false}>Nie</MenuItem>
                    </Select>
                </FormControl>
                <Button
                    type={'submit'}
                    variant={'outlined'}
                    sx={{display: { xs: 'none', sm: 'none', md: 'flex' },}}
                    size={'large'}
                    startIcon={<SaveIcon />}
                >Zapisz ustawienia</Button>
                <Fab type={'submit'} color={'primary'} aria-label={'add'} sx={{
                    margin: 0,
                    top: 'auto',
                    right: 20,
                    bottom: 80,
                    left: 'auto',
                    position: 'fixed',
                    display: { sm: 'flex', md: 'none' },
                }}
                >
                    <SaveIcon />
                </Fab>
            </Stack>
            {/*<Typography sx={{fontSize: '1rem', fontWeight: 'bold', marginTop: 2, marginBottom: 2}}>Sezony</Typography>*/}
        </form>
    </Box>
}