import * as React from 'react';
import {useParams} from "react-router-dom";
import {Box, Typography} from "@mui/material";
import {useCookies} from "react-cookie";
import AxiosGet from "../AxiosGet";
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import Grid from '@mui/material/Grid';
import useAppNavigate from "../App/useAppNavigate";
import Header from "./GetOne/Header";

export default function Seasons() {
    const { identifier } = useParams();
    const [cookies, setCookie, removeCookie] = useCookies(['accessToken']);
    const [league, setLeague] = React.useState({});
    const [finishedSeasons, setFinishedSeasons] = React.useState([]);
    const [seasonStats, setSeasonStats] = React.useState({});
    const navigate = useAppNavigate();
    const [activeSeason, setActiveSeason] = React.useState('');

    const fetchLeague = () => {
        AxiosGet('/leagues/' + identifier, cookies.accessToken).then((response) => {
            setLeague(response.data);
            setActiveSeason(response.data.seasons.find((season) => season.active === true));
            let seasons = response.data.seasons.filter(season => season.active === false).sort(function(a, b) {
                var dateA = new Date(a.finishAt),
                    dateB = new Date(b.finishAt);
                if (dateA < dateB) {
                    return 1;
                }

                if (dateA > dateB) {
                    return -1;
                }

                return 0;
            })

            setFinishedSeasons(seasons);
            window.dispatchEvent(new Event('contentLoaded'));
        }).catch((error) => {
            if (error.response.status === 401) {
                removeCookie('accessToken', {path: '/'});
            }
            if (error.response.status === 404) {
                navigate('/leagues');
            }
        });
    }

    async function fetchSeason(seasonIdentifier) {
        return await AxiosGet('/seasons/' + seasonIdentifier, cookies.accessToken)
            .catch((error) => {
            if (error.response.status === 401) {
                removeCookie('accessToken', {path: '/'});
            }
            if (error.response.status === 404) {
                navigate('/leagues');
            }
        });
    }

    React.useEffect(() => {
        fetchLeague();
    }, [identifier])

    let seasonsCount = finishedSeasons.length + 1;
    let seasons = finishedSeasons.map((finishedSeason, index) => {
        let createdAt = new Date(finishedSeason.createdAt);
        let createdAtString = createdAt.toISOString().split('T')[0];
        let finishedAt = new Date(finishedSeason.finishAt);
        let finishedAtString = finishedAt.toISOString().split('T')[0];
        let standings = finishedSeason.ranking.sort(function(a, b) {
            if (parseInt(a.score) < parseInt(b.score)) {
                return 1;
            }

            if (parseInt(a.score) > parseInt(b.score)) {
                return -1;
            }

            return 0;
        }).slice(0, 3);
        let standingsView = [];
        standingsView = standings.map((standing, standingIndex) => {
            let player = league.players.find((player) => player.identifier === standing.playerIdentifier);
            if (standingIndex === 0) {
                return <Grid container alignItems={'center'} paddingBottom={1}>
                    <Grid item xs={2} sx={{textAlign: 'center'}}>
                        <WorkspacePremiumIcon sx={{color: '#AF9500'}} />
                    </Grid>
                    <Grid item xs={7}>
                        {player.name}
                    </Grid>
                    <Grid item xs={3} sx={{textAlign: 'center'}}>
                        {standing.score}
                    </Grid>
                </Grid>
            }
            if (standingIndex === 1) {
                return <Grid container alignItems={'center'} paddingBottom={1}>
                    <Grid item xs={2} sx={{textAlign: 'center'}}>
                        <WorkspacePremiumIcon sx={{color: '#B4B4B4'}} />
                    </Grid>
                    <Grid item xs={7}>
                        {player.name}
                    </Grid>
                    <Grid item xs={3} sx={{textAlign: 'center'}}>
                        {standing.score}
                    </Grid>
                </Grid>
            }
            if (standingIndex === 2) {
                return <Grid container alignItems={'center'} paddingBottom={1}>
                    <Grid item xs={2} sx={{textAlign: 'center'}}>
                        <WorkspacePremiumIcon sx={{color: '#6A3805'}} />
                    </Grid>
                    <Grid item xs={7}>
                        {player.name}
                    </Grid>
                    <Grid item xs={3} sx={{textAlign: 'center'}}>
                        {standing.score}
                    </Grid>
                </Grid>
            }
        })

        return <Box key={finishedSeason.identifier}>
            <h2>Sezon {seasonsCount - (index + 1)}</h2>
            <h3>{createdAtString} - {finishedAtString}</h3>
            <Grid container alignItems={'center'} paddingBottom={1}>
                <Grid item xs={2} sx={{textAlign: 'center'}}>

                </Grid>
                <Grid item xs={7}>
                    Gracz
                </Grid>
                <Grid item xs={3} sx={{textAlign: 'center'}}>
                    Punkty
                </Grid>
            </Grid>
            {standingsView}
        </Box>
    })

    return <Box sx={{position: 'relative'}}>
        <Header identifier={identifier} league={league} activeSeason={activeSeason} setActiveSeason={setActiveSeason} />
        <Typography sx={{fontSize: '1.25rem', fontWeight: 'bold'}}>Poprzednie sezony</Typography>
        {seasons}
    </Box>
}