import * as React from "react";
import {Box, Button, IconButton} from "@mui/material";
import LinkIcon from "@mui/icons-material/Link";
import hasRole from "../../ContextMenu/hasRole";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import useAppNavigate from "../../App/useAppNavigate";


export default function ActionsMenu(props) {
    const navigate = useAppNavigate();

    return <>
        <Box sx={{display: { xs: 'inline', sm: 'none' },}}>
            {
                hasRole(props.profile.userIdentifier, props.mates, 'ROLE_ADMIN') && props.player.mateIdentifier === null ? <>
                    <IconButton
                        color={'secondary'}
                        component={'label'}
                        size={'small'}
                        onClick={() => navigate('/leagues/' + props.leagueIdentifier + '/players/' + props.player.identifier + '/connect')}
                    >
                        <LinkIcon />
                    </IconButton>&nbsp;
                </> : ''
            }
            {
                hasRole(props.profile.userIdentifier, props.mates, 'ROLE_ADMIN') ? <>
                    <IconButton
                        color={'secondary'}
                        component={'label'}
                        size={'small'}
                        onClick={() => navigate('/leagues/' + props.leagueIdentifier + '/players/' + props.player.identifier + '/edit')}
                    >
                        <EditIcon />
                    </IconButton>&nbsp;
                </> : ''
            }
            {
                hasRole(props.profile.userIdentifier, props.mates, 'ROLE_ADMIN') ? <>
                    <IconButton
                        color={'error'}
                        component={'label'}
                        size={'small'}
                        onClick={() => props.handleDelete(props.player.identifier)}
                    >
                        <DeleteForeverIcon />
                    </IconButton>
                </> : ''
            }
        </Box>
        <Box sx={{display: { xs: 'none', sm: 'inline' },}}>
        {
            hasRole(props.profile.userIdentifier, props.mates, 'ROLE_ADMIN') && props.player.mateIdentifier === null ? <>
                <Button
                    variant={'outlined'}
                    color={'secondary'}
                    startIcon={<LinkIcon />}
                    onClick={() => navigate('/leagues/' + props.leagueIdentifier + '/players/' + props.player.identifier + '/connect')}
                >Powiąż</Button>&nbsp;
            </> : ''
        }
        {
            hasRole(props.profile.userIdentifier, props.mates, 'ROLE_ADMIN') ? <>
                <Button
                    variant={'outlined'}
                    color={'secondary'}
                    startIcon={<EditIcon />}
                    onClick={() => navigate('/leagues/' + props.leagueIdentifier + '/players/' + props.player.identifier + '/edit')}
                >Edytuj</Button>&nbsp;
            </>: ''
        }
        {
            hasRole(props.profile.userIdentifier, props.mates, 'ROLE_ADMIN') ?
                <Button
                    value={props.player.identifier}
                    variant={'outlined'}
                    color={'error'}
                    onClick={props.handleDelete}
                    startIcon={<DeleteForeverIcon />}
                >Usuń</Button>
                : ''
        }
        </Box>
    </>
}