import * as React from "react";
import HomeIcon from "@mui/icons-material/Home";
import BaseElement from "../BaseElement";

export default function Test(props) {
    return <BaseElement
        timestamp={props.feedEntry.publishedAt}
        name={process.env.REACT_APP_NAME}
        title={'Witaj!'}
        icon={<HomeIcon color={'info'} fontSize={'small'} />}
        isSeen={props.feedEntry.isSeen}
    >TESTOWA WIADOMOŚĆ</BaseElement>
}