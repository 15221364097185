import * as React from 'react';
import {Box, Typography, useTheme} from "@mui/material";
import {DataGrid, gridClasses } from "@mui/x-data-grid";
import AllInclusiveIcon from "@mui/icons-material/AllInclusive";
import { alpha } from '@mui/material/styles';
import {TrendingDown, TrendingUp} from "@mui/icons-material";
import Identicon from "../Identicon";
import useAppNavigate from "../../App/useAppNavigate";

export default function Ranking(props) {
    const [profile] = React.useState(JSON.parse(localStorage.getItem('profile')));
    const theme = useTheme();
    const navigate = useAppNavigate();
    let rankingRows = [];
    if (props.league.seasons.length > 0 && props.currentSeasonData !== undefined && props.currentSeasonData.ranking.length > 0) {
        rankingRows = props.currentSeasonData.ranking.filter((rank) => rank.wins + rank.loses + rank.ties > 0).sort((a,b) => b.score - a.score)
            .map((rank, index) => {
                let filteredPlayer = props.league.players.find((player) => {
                    return player.identifier === rank.playerIdentifier;
                });
                if (filteredPlayer === undefined || filteredPlayer.active === false) {
                    return false;
                }
                let filteredMate = props.league.mates.find(mate => mate.identifier === filteredPlayer.mateIdentifier);

                let filteredPlayerStatistics = props.seasonStatistics.filter((statistic) => {
                    return statistic.playerIdentifier === rank.playerIdentifier;
                })
                let dailyScoreChange = 0;
                if (filteredPlayerStatistics.length > 0 && 'dailyScoreChange' in filteredPlayerStatistics[0]) {

                    dailyScoreChange = filteredPlayerStatistics[0].dailyScoreChange;
                }

                let playerStats = {
                    id: index+1,
                    playerIdentifier: rank.playerIdentifier,
                    profileIdentifier: filteredMate !== undefined ? filteredMate.userIdentifier : null,
                    name: filteredPlayer.name || '',
                    score: rank.score,
                    winLoseRatio: (rank.wins/rank.loses).toFixed(2),
                    wins: rank.wins,
                    loses: rank.loses,
                    ties: rank.ties,
                    allGames: rank.wins + rank.loses + rank.ties,
                    streak: rank.streak,
                    dailyScoreChange: dailyScoreChange,
                    imagesDir: filteredPlayer.imagesDir,
                };

                let playerAttributesStatsRaw = props.attributesStatistics.find((playerAttributesStats) => playerAttributesStats.playerIdentifier === rank.playerIdentifier);
                let mappedPlayerAttributesStats = [];
                if (playerAttributesStatsRaw !== undefined) {
                    mappedPlayerAttributesStats = playerAttributesStatsRaw.attributes.map(attribute => {
                        if (attribute.values[0].leagueAttributeDictionaryIdentifier === null) {
                            return [{[attribute.leagueAttributeIdentifier]: attribute.values[0].value}];
                        }

                        return attribute.values.map((dictionary) => {
                            return {[dictionary.leagueAttributeDictionaryIdentifier]: dictionary.value};
                        })
                    }).flat(1).reduce((acc, obj) => {
                        let key = Object.keys(obj)[0];
                        acc[key] = obj[key];

                        return acc;
                    }, {})
                }

                return {...playerStats, ...mappedPlayerAttributesStats};
            }).filter((rankingRow) => {
                if (rankingRow !== false) {
                    return rankingRow;
                }
            });
    }

    let rankingColumns = [];
    if (props.league.tieable) {
        rankingColumns = [
            { field: 'id', headerName: 'Ranking', width: 70, hideable: false, align: 'center', renderCell: (params) => {
                let previousRow = rankingRows[params.row.id-2]
                if (previousRow !== undefined && previousRow.score === params.row.score) {
                    return ''
                }

                return params.row.id;
            }},
            { field: 'name', headerName: 'Gracz', minWidth: 100, flex: 1, hideable: false, renderCell: (params) =>
                <Identicon
                    imagesDir={params.row.imagesDir}
                    name={params.row.name}
                    width={16}
                    height={16}
                    mr={0.5}
                    onClick={() => navigate('/leagues/' + props.league.identifier + '/players/' + params.row.playerIdentifier)}
                >{params.row.name}</Identicon>
            },
            { field: 'score', headerName: 'Elo', width: 100, renderCell: (params) =>
                <>
                    {params.row.score} {params.row.dailyScoreChange > 0 || params.row.dailyScoreChange < 0 ?
                    <Box sx={{color: params.row.dailyScoreChange > 0 ? 'success.main' : 'error.main'}}>
                        &nbsp;({params.row.dailyScoreChange > 0 ? '+' : ''}{params.row.dailyScoreChange})
                    </Box>: ''} {params.row.streak < 3 && params.row.streak > -3 ? '' : params.row.streak > 0 ?
                    <TrendingUp sx={{color: 'success.main'}} /> :
                    <TrendingDown sx={{color: 'error.main'}} />}
                </>
            },
            { field: 'interval', headerName: 'Interwał', width: 90, align: 'center', renderCell: (params) => {
                let previousRow = rankingRows[params.row.id-2]
                if (previousRow === undefined) {
                    return '-';
                }

                return params.row.score - previousRow.score;
            }},
            { field: 'difference', headerName: 'Strata', width: 90, align: 'center', renderCell: (params) => {
                let previousRows = rankingRows.filter((row, index) => index < params.row.id-1);
                console.log(previousRows)

                return params.row.score;
            }},
            { field: 'allGames', headerName: 'Gry', width: 90, align: 'center'},
            { field: 'wins', headerName: 'Wygrane', width: 90, align: 'center'},
            { field: 'loses', headerName: 'Przegrane', width: 90, align: 'center'},
            { field: 'ties', headerName: 'Remisy', width: 90, align: 'center'},
            { field: 'winLoseRatio', headerName: 'W/L ratio', width: 90, align: 'center', renderCell: (params) =>
                params.row.winLoseRatio === 'Infinity' ? <AllInclusiveIcon fontSize={'small'} />: params.row.winLoseRatio
            },
        ]
    } else {
        rankingColumns = [
            { field: 'id', headerName: 'Ranking', width: 70, hideable: false, align: 'center', renderCell: (params) => {
                let previousRow = rankingRows[params.row.id-2]
                if (previousRow !== undefined && previousRow.score === params.row.score) {
                    return ''
                }

                return params.row.id;
            }},
            { field: 'name', headerName: 'Gracz', minWidth: 100, flex: 1, hideable: false, renderCell: (params) =>
                <Identicon
                    imagesDir={params.row.imagesDir}
                    name={params.row.name}
                    width={16}
                    height={16}
                    mr={0.5}
                    onClick={() => navigate('/leagues/' + props.league.identifier + '/players/' + params.row.playerIdentifier)}
                >{params.row.name}</Identicon>
            },
            { field: 'score', headerName: 'Elo', width: 100, renderCell: (params) =>
                <>
                    {params.row.score} {params.row.dailyScoreChange > 0 || params.row.dailyScoreChange < 0 ?
                    <Box sx={{color: params.row.dailyScoreChange > 0 ? 'success.main' : 'error.main'}}>
                        &nbsp;({params.row.dailyScoreChange > 0 ? '+' : ''}{params.row.dailyScoreChange})
                    </Box>: ''} {params.row.streak < 3 && params.row.streak > -3 ? '' : params.row.streak > 0 ?
                    <TrendingUp sx={{color: 'success.main'}} /> :
                    <TrendingDown sx={{color: 'error.main'}} />}
                </>
            },
            { field: 'interval', headerName: 'Interwał', width: 80, align: 'center', renderCell: (params) => {
                let previousRow = rankingRows[params.row.id-2]
                if (previousRow === undefined) {
                    return '-';
                }

                return params.row.score - previousRow.score;
            }},
            { field: 'difference', headerName: 'Strata', width: 80, align: 'center', renderCell: (params) => {
                let firstRow = rankingRows[0];
                if (params.row.id === 1) {
                    return '-';
                }

                return params.row.score - firstRow.score;
            }},
            { field: 'allGames', headerName: 'Gry', width: 80, align: 'center'},
            { field: 'wins', headerName: 'Wygrane', width: 80, align: 'center'},
            { field: 'loses', headerName: 'Przegrane', width: 80, align: 'center'},
            { field: 'winLoseRatio', headerName: 'W/L ratio', width: 80, align: 'center', renderCell: (params) =>
                params.row.winLoseRatio === 'Infinity' ? <AllInclusiveIcon fontSize={'small'} />: params.row.winLoseRatio
            },
        ]
    }

    let attributesColumns = [];
    if (props.league.attributes !== undefined) {
        attributesColumns = props.league.attributes.map((attribute) => {
            if (attribute.type === 'RANGE') {
                return {field: attribute.identifier, headerName: attribute.name, width: 90, align: 'center', type: 'number', renderCell: (params) =>
                        params.row[attribute.identifier] === undefined ? 0 : params.row[attribute.identifier]
                };
            }

            return attribute.dictionary.map(dictionaryData => {
                return {field: dictionaryData.identifier, headerName: dictionaryData.name, width: 90, align: 'center', type: 'number', renderCell: (params) =>
                        params.row[dictionaryData.identifier] === undefined ? 0 : params.row[dictionaryData.identifier]
                }
            })
        }).flat(1);
    }
    rankingColumns = [...rankingColumns, ...attributesColumns];

    return <Box mb={1}>
        <Typography sx={{fontSize: '1.25rem', fontWeight: 'bold'}}>Drabinka</Typography>
        <div style={{ height: 315, width: '100%' }}>
            <div style={{ display: 'flex', height: '100%' }}>
                <Box
                    sx={{
                        width: '100%',
                        '& .you': {
                            fontWeight: 'bold',
                        },
                        '& .even': {
                            backgroundColor: (theme) => theme.palette.grey[100],
                        },
                        [`& .${gridClasses.row}`]: {
                            '&:hover, &.Mui-hovered': {
                                backgroundColor: alpha(theme.palette.grey[500], 0.2),
                                '@media (hover: none)': {
                                    backgroundColor: 'transparent',
                                },
                            }
                        }
                    }}
                >
                    <DataGrid
                        rows={rankingRows}
                        columns={rankingColumns}
                        pageSize={5}
                        rowsPerPageOptions={[5]}
                        disableSelectionOnClick={true}
                        rowHeight={36}
                        getRowClassName={(params) => {
                            let classModificator = '';
                            if (params.row.profileIdentifier === profile.userIdentifier) {
                                classModificator += ' you';
                            }

                            if (params.row.id%2 === 0) {
                                classModificator += ' even';
                            }

                            return classModificator;
                        }}
                        disableColumnMenu={true}
                    />
                </Box>
            </div>
        </div>
    </Box>
}
