import * as React from "react";
import Container from "@mui/material/Container";
import {Outlet} from "react-router-dom";
import {
    AppBar, Badge,
    BottomNavigation,
    BottomNavigationAction,
    Box,
    Button, Link,
    Paper,
    Toolbar, Tooltip,
    Typography
} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import Person4Icon from '@mui/icons-material/Person4';
import Zoom from '@mui/material/Zoom';
import {useCookies} from "react-cookie";
import Fabs from "./Layout/Fabs";
import LogoutIcon from '@mui/icons-material/Logout';
import useAppNavigate from "./App/useAppNavigate";
import MainLoader from "./App/MainLoader";
import HouseIcon from '@mui/icons-material/House';
import {useState} from "react";
import AxiosPut from "./AisoAxiosPut";
import ConfirmationDialog from "./App/ConfirmationDialog";

export default function Layout() {
    const [cookies, setCookie, removeCookie] = useCookies(['accessToken']);
    const [currentLeague, setCurrentLeague] = useState(localStorage.getItem('currentLeague'));
    const [syncedFeed, setSyncedFeed] = useState(JSON.parse(localStorage.getItem('feed')) ?? []);
    const [unseenFeedEntries, setUnseenFeedEntries] = useState(0);
    const navigate = useAppNavigate();
    const [open, setOpen] = useState(false);
    const [isContentLoading, setIsContentLoading] = useState(true);
    const [logoutDialog, setLogoutDialog] = useState(false);
    const [logoutLoading, setLogoutLoading] = useState(false);
    const divRef = React.useRef();

    const handleTooltipClose = () => {
        setOpen(false);
    };

    const handleTooltipOpen = () => {
        setOpen(true);
    };

    const handleLeagueClick = event => {
        switch (event.detail) {
            case 1: {
                if (currentLeague !== null) {
                    navigate('/leagues/' + currentLeague);
                } else {
                    handleTooltipOpen();
                    navigate('/leagues');
                }

                break;
            }
            case 2: {
                navigate('/leagues');

                break;
            }
            default: {
                break;
            }
        }
    };

    const handleHomeClick = () => {
        navigate('/');
        let unSeenFeedEntriesIdentifiers = syncedFeed.filter((feedEntry) => feedEntry.isSeen === false).map(feedEntry => feedEntry.identifier)
        if (unSeenFeedEntriesIdentifiers.length > 0) {
            AxiosPut('/feed', cookies.accessToken, unSeenFeedEntriesIdentifiers).then((response) => {
                let newFeed = syncedFeed.map((feedEntry) => {
                    if (unSeenFeedEntriesIdentifiers.includes(feedEntry.identifier)) {
                        feedEntry['isSeen'] = true;

                        return feedEntry
                    }

                    return feedEntry
                })
                localStorage.setItem('feed', JSON.stringify(newFeed));
                setUnseenFeedEntries(newFeed.filter((syncedFeedData) => syncedFeedData.isSeen === false).length)
                setSyncedFeed(newFeed);
            }).catch((error) => {
                if (error.response.status === 401) {
                    removeCookie('accessToken', {path: '/'});
                }
            });
        }
    }

    window.addEventListener('newCurrentLeague', () => {
        setCurrentLeague(localStorage.getItem('currentLeague'));
    });

    const handleLogout = () => {
        setLogoutLoading(true);
        removeCookie('accessToken',{path:'/'});
        navigate('/');
    }

    window.addEventListener('contentLoading', () => {
        setIsContentLoading(true);
    });

    window.addEventListener('contentLoaded', () => {
        setIsContentLoading(false);
    });

    window.addEventListener('feedChanged', () => {
        let feedData = JSON.parse(localStorage.getItem('feed')) ?? [];
        setUnseenFeedEntries(feedData.filter((syncedFeedData) => syncedFeedData.isSeen === false).length)
        setSyncedFeed(feedData);
    });

    window.addEventListener('newFeedData', () => {
        let feedData = JSON.parse(localStorage.getItem('feed')) ?? [];
        setUnseenFeedEntries(feedData.filter((syncedFeedData) => syncedFeedData.isSeen === false).length)
        setSyncedFeed(feedData);
    });

    React.useState(() => {
        window.onpopstate = () => {
            setIsContentLoading(true);
        };
    }, []);

    const handleLogoutDialog = () => {
        setLogoutDialog(!logoutDialog);
    }

    return (
        <div>
            <AppBar
                position={'fixed'}
                color={'default'}
                elevation={0}
                sx={{ borderBottom: (theme) => `1px solid ${theme.palette.divider}`, zIndex: 1200}}
            >
                <Toolbar sx={{flexWrap: 'wrap', paddingX: {xs: 1, sm: 1, md: 2}}}>
                    <Typography variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }}>
                        <Link
                            variant={'inherit'}
                            color={'inherit'}
                            sx={{cursor: 'pointer'}}
                            onClick={() => handleHomeClick()}
                            underline={'none'}
                            display={'inline'}
                            pr={1}
                            pb={1}
                        >
                            {process.env.REACT_APP_NAME}
                        </Link>
                    </Typography>
                    <nav>
                        <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                            <Button
                                variant="button"
                                color="text.primary"
                                onClick={() => {navigate('/')}}
                                sx={{ my: 1, mx: 1.5 }}
                            >
                                <HouseIcon />&nbsp;Główna
                            </Button>
                            <Button
                                variant="button"
                                color="text.primary"
                                onClick={() => {navigate('/leagues')}}
                                sx={{ my: 1, mx: 1.5 }}
                            >
                                <EmojiEventsIcon />&nbsp;Ligi
                            </Button>
                            <Button
                                variant="button"
                                color="text.primary"
                                onClick={() => {navigate('/profile')}}
                                sx={{ my: 1, mx: 1.5 }}
                            >
                                <Person4Icon />&nbsp;Profil
                            </Button>
                            <Button variant="button" sx={{ my: 1, mx: 1.5 }} onClick={handleLogout}>
                                <LogoutIcon />&nbsp;Wyloguj
                            </Button>
                        </Box>
                        <Box sx={{ display: { xs: 'block', md: 'none' } }}>
                            <Button variant="button" sx={{ my: 1 }} onClick={handleLogoutDialog}>
                                <LogoutIcon />
                            </Button>
                        </Box>
                    </nav>
                </Toolbar>
            </AppBar>
            <Container maxWidth={'md'} sx={{mb: {xs: 18, xl: 10}, mt: {xs: 8, sm: 9}, paddingX: {xs: 0.5, sm: 0.5, md: 1}}} ref={divRef} >
                <ConfirmationDialog
                    open={logoutDialog}
                    onClose={handleLogoutDialog}
                    title={'Wylogowanie'}
                    content={'Czy na pewno wylogować z aplikacji?'}
                    loading={logoutLoading}
                    onConfirm={handleLogout}
                    value={''}
                />
                <MainLoader isLoading={isContentLoading} />
                <Outlet />
            </Container>
            <Fabs />
            <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, display: { sm: 'block', md: 'none' }, zIndex: 1200}} elevation={3}>
                <BottomNavigation showLabels>
                    <BottomNavigationAction
                        label={'Główna'}
                        icon={<Badge badgeContent={unseenFeedEntries} color={'primary'} max={99}><HouseIcon /></Badge>}
                        onClick={() => handleHomeClick()}
                    />
                    {currentLeague === null
                        ? <Tooltip
                            PopperProps={{
                                disablePortal: true,
                            }}
                            onClose={handleTooltipClose}
                            open={open}
                            title="Brak przypiętej ligi."
                            TransitionComponent={Zoom}
                        >
                            <BottomNavigationAction
                                label="Ligi"
                                icon={<EmojiEventsIcon/>}
                                onClick={() => navigate('/leagues')}
                            />
                        </Tooltip>
                        : <Tooltip
                            PopperProps={{
                                disablePortal: true,
                            }}
                            onClose={handleTooltipClose}
                            open={open}
                            title="Brak przypiętej ligi."
                            TransitionComponent={Zoom}
                        >
                            <BottomNavigationAction label="Liga" icon={<EmojiEventsIcon/>} onClick={handleLeagueClick} />
                        </Tooltip>
                    }
                    <BottomNavigationAction label={'Profil'} icon={<Person4Icon />} onClick={() => navigate('/profile')} />
                </BottomNavigation>
            </Paper>
        </div>
    );
}