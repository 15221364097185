import * as React from "react";
import BaseElement from "../../BaseElement";
import {Typography} from "@mui/material";
import SportsEsportsIcon from "@mui/icons-material/SportsEsports";

export default function Lose(props) {
    const playerOne = props.league.players.find((player) => player.identifier === props.feedEntry.payload.playerOneIdentifier);
    const playerTwo = props.league.players.find((player) => player.identifier === props.feedEntry.payload.playerTwoIdentifier);

    let playerOneScore = '';
    let playerTwoScore = '';
    if (props.feedEntry.payload.scoreChange === 0) {
        playerOneScore = <Typography variant={'inherit'} display={'inline'} color={'info.main'}>({props.feedEntry.payload.scoreChange})</Typography>;
        playerTwoScore = <Typography variant={'inherit'} display={'inline'} color={'info.main'}>({props.feedEntry.payload.scoreChange})</Typography>;
    } else {
        playerOneScore = <Typography variant={'inherit'} display={'inline'} color={'error'}>(-{props.feedEntry.payload.scoreChange})</Typography>;
        playerTwoScore = <Typography variant={'inherit'} display={'inline'} color={'success.main'}>(+{props.feedEntry.payload.scoreChange})</Typography>;
    }

    return <BaseElement
        timestamp={props.feedEntry.publishedAt}
        name={props.league.name}
        icon={<SportsEsportsIcon color={'error'} fontSize={'small'} sx={{transform: 'rotate(-45deg)'}} />}
        isSeen={props.feedEntry.isSeen}
    >
        {playerOne.name} {playerOneScore} przegrał(a) z {playerTwo.name} {playerTwoScore}.
    </BaseElement>
}