import * as React from 'react';
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import {Button, Fab, Link, OutlinedInput} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import SaveIcon from "@mui/icons-material/Save";
import {useCookies} from "react-cookie";
import AxiosPost from "../AxiosPost";
import FormLabel from "@mui/material/FormLabel";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import AxiosGet from "../AxiosGet";
import useAppNavigate from "../App/useAppNavigate";

export default function Create() {
    const [cookies, setCookie, removeCookie] = useCookies(['accessToken']);
    const [leagueName, setLeagueName] = React.useState('');
    const [leagueNameError, setLeagueNameError] = React.useState(false);
    const [leagueTieable, setLeagueTieable] = React.useState('');
    const [leagueTieableError, setLeagueTieableError] = React.useState(false);
    const [asPlayer, setAsPlayer] = React.useState(true);
    const navigate = useAppNavigate();

    const handleLeagueNameChange = (event) => {
        setLeagueName(event.target.value);
        setLeagueNameError(false);
    };

    const handleLeagueTieableChange = (event) => {
        setLeagueTieable(event.target.value);
        setLeagueTieableError(false);
    };

    const handleAsPlayerChange = (event) => {
        setAsPlayer(Boolean(event.target.value));
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (leagueName === '') {
            setLeagueNameError(true);
        }

        if (leagueTieable === '') {
            setLeagueTieableError(true);
        }

        if (leagueNameError || leagueTieableError) {
            return;
        }

        let payload = {
            name: leagueName,
            tieable: leagueTieable,
            asPlayer: asPlayer,
            attributes: [],
        };

        let response;
        response = await AxiosPost('/leagues', cookies.accessToken, payload)
            .catch((error) => {
                console.log(error);
            });
        if (response.data.status === 'ok') {
            setLeagueName('');
            setLeagueTieable('');
            AxiosGet('/leagues', cookies.accessToken).then((response) => {
                localStorage.setItem('leagues', JSON.stringify(response.data));
                window.dispatchEvent(new Event('leaguesChanged'));
            }).catch((error) => {
                if (error.response.status === 401) {
                    removeCookie('accessToken', {path: '/'});
                }
            });
            navigate('/leagues/' + response.data.leagueIdentifier);
        }
    }

    React.useEffect(() => {
        window.dispatchEvent(new Event('contentLoaded'));
    })

    return <Box sx={{ minWidth: 120}}>
        <h1>Utwórz ligę</h1>
        <form onSubmit={handleSubmit}>
            <Stack spacing={2}>
                <FormControl fullWidth error={leagueNameError}>
                    <InputLabel id="demo-simple-select-label1">Nazwa</InputLabel>
                    <OutlinedInput
                        id="league-name"
                        label="Nazwa"
                        value={leagueName}
                        onChange={handleLeagueNameChange}
                    />
                </FormControl>
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label1">Typ</InputLabel>
                    <Select
                        labelId="type"
                        id="demo-simple-select1"
                        label="Typ"
                        defaultValue={true}
                        disabled={true}
                    >
                        <MenuItem value={true}>ELO ranking</MenuItem>
                    </Select>
                </FormControl>
                <FormControl fullWidth error={leagueTieableError}>
                    <InputLabel id="demo-simple-select-label1">Remisy</InputLabel>
                    <Select
                        labelId="tieable"
                        id="demo-simple-select1"
                        label="Remisy"
                        value={leagueTieable}
                        onChange={handleLeagueTieableChange}
                    >
                        <MenuItem value={true}>Tak</MenuItem>
                        <MenuItem value={false}>Nie</MenuItem>
                    </Select>
                </FormControl>
                <FormControl sx={{mt: 2}}>
                    <FormLabel id="demo-row-radio-buttons-group-label">Dodaj siebie jako gracza</FormLabel>
                    <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={asPlayer}
                        onChange={handleAsPlayerChange}
                    >
                        <FormControlLabel value={true} control={<Radio />} label="Tak" />
                        <FormControlLabel value={false} control={<Radio />} label="Nie" />
                    </RadioGroup>
                </FormControl>
                <Button
                    type={'submit'}
                    variant={'outlined'}
                    sx={{display: { xs: 'none', sm: 'none', md: 'flex' },}}
                    size={'large'}
                    startIcon={<SaveIcon />}
                >Utwórz ligę</Button>
                <Box sx={{ '& > :not(style)': { m: 1 } }}>
                    <Fab color="primary" aria-label="add" sx={{
                        margin: 0,
                        top: 'auto',
                        right: 20,
                        bottom: 80,
                        left: 'auto',
                        position: 'fixed',
                        display: { sm: 'flex', md: 'none' },
                    }} variant="outlined" type="submit">
                        <SaveIcon />
                    </Fab>
                </Box>
            </Stack>
        </form>
    </Box>;
}