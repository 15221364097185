import * as React from 'react';
import {Line} from "react-chartjs-2";
import {Box, Slider} from "@mui/material";
import convertHex from "../../../convertHex";
import moment from "moment/moment";

export default function Games(props) {
    const [profile] = React.useState(JSON.parse(localStorage.getItem('profile')));
    const [gamesChartRange, setGamesChartRange] = React.useState([0, 10]);
    const [gamesChartRangeMax, setGamesChartRangeMax] = React.useState(0);
    const [gamesChartRangeMin, setGamesChartRangeMin] = React.useState(10);
    const minDistance = 10;

    const handleChange = (event, newValue, activeThumb) => {
        if (!Array.isArray(newValue)) {
            return;
        }

        if (activeThumb === 0) {
            setGamesChartRange([Math.min(newValue[0], gamesChartRange[1] - minDistance), gamesChartRange[1]]);
        } else {
            setGamesChartRange([gamesChartRange[0], Math.max(newValue[1], gamesChartRange[0] + minDistance)]);
        }
    };

    let playersIdentifiers = [];
    if (props.seasonChartStatistics.games !== undefined) {
        playersIdentifiers = [...new Set(props.seasonChartStatistics.games.map(item => item.playerIdentifier))]
    }

    let gamesPoints = [];
    if (props.seasonChartStatistics.games !== undefined) {
        gamesPoints = [...new Set(props.seasonChartStatistics.games.map(item => item.points))];
    }

    let gamesOptions = {
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: false,
                text: '',
            },
        },
        scales: {
            y: {
                display: true,
                min: 900,
                max: 1100,
            }
        },
        maintainAspectRatio: false,
    };

    gamesOptions.scales.y.max = gamesPoints.length > 0 ? Math.ceil(Math.max(...gamesPoints)/10) * 10: 1100;
    gamesOptions.scales.y.min = gamesPoints.length > 0 ? Math.floor(Math.min(...gamesPoints)/10) * 10: 900;

    let gamesChartDataset = [];
    if (props.seasonChartStatistics.games !== undefined) {
        gamesChartDataset = playersIdentifiers.map(playerIdentifier => {
            let filteredData = props.seasonChartStatistics.games.filter(seasonChartStatistic => seasonChartStatistic.playerIdentifier === playerIdentifier);
            let filteredPlayer = props.league.players.find(player => player.identifier === playerIdentifier);
            if (filteredPlayer === undefined || filteredPlayer.active === false) {
                return false;
            }

            let filteredMate = props.league.mates.find(mate => mate.identifier === filteredPlayer.mateIdentifier);

            return {
                spanGaps: true,
                label: filteredPlayer.name,
                data: filteredData.map(x => x.points).slice(gamesChartRange[0], gamesChartRange[1]+1).map((item, index, items) => {
                    if (index > 0 && items[index-1] === item && index < items.length-1) {
                        return null;
                    }

                    return item;
                }),
                borderColor: convertHex(filteredPlayer.hexColor),
                backgroundColor: convertHex(filteredPlayer.hexColor, 0.5),
                borderWidth: filteredMate !== undefined && filteredMate.userIdentifier === profile.userIdentifier ? 4 : 2,
                pointRadius: function (context) {
                    let equalsPrevValue = false;
                    let equalsNextValue = false;
                    var index = context.dataIndex;
                    var value = context.dataset.data[index];
                    if (index > 0 && filteredData[index-1].points === value) {
                        equalsPrevValue = true;
                    }
                    if (index+1 < filteredData.length && filteredData[index+1].points === value) {
                        equalsNextValue = true;
                    }
                    return equalsPrevValue && equalsNextValue ? 0 :
                        filteredMate !== undefined && filteredMate.userIdentifier === profile.userIdentifier ? 3 : 2;
                },
                pointHoverRadius: 6,
            }
        }).filter((rankingRow) => rankingRow !== false);
    }

    let gamesLabels = [];
    if (props.seasonChartStatistics.games !== undefined) {
        gamesLabels = [...new Set(props.seasonChartStatistics.games.map((item) => item.createdAt))].map((item, index) => index);
        if (gamesChartRange === [0, 0] && gamesLabels.length > 0) {
            setGamesChartRange([gamesLabels[0], gamesLabels[gamesLabels.length - 1]])
        }
    }

    React.useEffect(() => {
        if (gamesLabels.length > 0 && gamesChartRangeMin !== gamesLabels[0] && gamesChartRangeMax !== gamesLabels[gamesLabels.length - 1]) {
            setGamesChartRangeMin(gamesLabels[0]);
            setGamesChartRangeMax(gamesLabels[gamesLabels.length - 1]);
            setGamesChartRange([gamesLabels[0], gamesLabels[gamesLabels.length - 1]])
        }
    }, [gamesLabels])

    const gamesData = {
        labels: gamesLabels.slice(gamesChartRange[0], gamesChartRange[1]+1),
        datasets: gamesChartDataset,
    };

    function valuetext(value) {
        return `${value}`;
    }

    function valueLabel(value) {
        if (props.seasonChartStatistics.length === 0) {
            return value;
        }

        let game = props.seasonChartStatistics.games[value]
        if (game === undefined) {
            return value;
        }

        return moment(game.createdAt, 'YYYY-MM-DD HH:mm:ss')
            .utc(true)
            .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
            .format('HH:mm DD.MM.YYYY') + ' (' + value + ')';
    }

    return <Box>
        <Box style={{minHeight: '400px'}}>
            <Line options={gamesOptions} data={gamesData} />
        </Box>
        <Box ml={5} mr={5}>
            <Slider
                value={gamesChartRange}
                onChange={handleChange}
                valueLabelDisplay={'auto'}
                getAriaValueText={valuetext}
                valueLabelFormat={valueLabel}
                disableSwap
                min={gamesChartRangeMin}
                max={gamesChartRangeMax}
                disabled={gamesChartRangeMax < minDistance}
            />
        </Box>
    </Box>
}