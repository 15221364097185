import * as React from 'react';
import {CircularProgress, Dialog, DialogContent, Fade} from "@mui/material";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Fade ref={ref} {...props} />;
});

export default function MainLoader(props) {
    return <Dialog
        open={props.isLoading}
        aria-labelledby="loader"
        aria-describedby="main-loader"
        disableAutoFocus
        PaperProps={{
            style: {
                backgroundColor: 'transparent',
                boxShadow: 'none',
            },
        }}
        TransitionComponent={Transition}
        sx={{zIndex: 1100,}}
    >
        <DialogContent>
            <CircularProgress />
        </DialogContent>
    </Dialog>
}