import * as React from "react";
import {Box, Button, Fab, FormControl, InputLabel, OutlinedInput, Typography} from "@mui/material";
import Stack from "@mui/material/Stack";
import { TwitterPicker } from 'react-color';
import basicColors from "../../colors";
import SaveIcon from "@mui/icons-material/Save";
import {useParams} from "react-router-dom";
import AxiosPost from "../../AxiosPost";
import {useCookies} from "react-cookie";
import Header from "../GetOne/Header";

export default function Create() {
    let { identifier } = useParams();
    const [cookies, setCookie, removeCookie] = useCookies(['accessToken']);
    const [formName, setFormName] = React.useState('');
    const [formNameError, setFormNameError] = React.useState(false);
    const [color, setColor] = React.useState(basicColors()[0]);
    const [league] = React.useState(
        JSON.parse(localStorage.getItem('leagues')).find((league) => league.identifier === identifier)
    );
    const [activeSeason, setActiveSeason] = React.useState(
        league.seasons.find((season) => season.active === true)?.identifier ?? ''
    );

    const handleNameChange = (event) => {
        setFormName(event.target.value);
        setFormNameError(false);
    }

    const handleColorChange = (color) => {
        setColor(color.hex);
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (formName === '') {
            setFormNameError(true);

            return;
        }

        let payload = {
            leagueIdentifier: identifier,
            name: formName,
            color: color
        }

        let response;
        response = await AxiosPost('/players', cookies.accessToken, payload).catch(function (error) {
            if (error.response.status === 400) {
                setFormNameError(true)
            }
        });

        if (response.data.status === 'ok') {
            window.location.href = '/leagues/' + identifier + '/players';
        }
    }

    React.useEffect(() => {
        window.dispatchEvent(new Event('contentLoaded'));
    })

    return <Box sx={{position: 'relative'}}>
        <Header identifier={identifier} league={league} activeSeason={activeSeason} setActiveSeason={setActiveSeason} />
        <Typography sx={{fontSize: '1.25rem', fontWeight: 'bold'}}>Utwórz gracza</Typography>
        <form onSubmit={handleSubmit}>
            <Stack spacing={2}>
                <FormControl fullWidth error={formNameError}>
                    <InputLabel htmlFor="outlined-adornment-amount">Dodaj gracza</InputLabel>
                    <OutlinedInput
                        id="outlined-adornment-amount"
                        value={formName}
                        label="Dodaj gracza"
                        onChange={handleNameChange}
                    />
                </FormControl>
                <TwitterPicker
                    color={color}
                    width={'100%'}
                    onChangeComplete={handleColorChange}
                    triangle={'hide'}
                    colors={basicColors()}
                />
                <Button
                    type={'submit'}
                    variant={'outlined'}
                    sx={{display: { xs: 'none', sm: 'none', md: 'flex' },}}
                    size={'large'}
                    startIcon={<SaveIcon />}
                >Utwórz gracza</Button>
            </Stack>
            <Fab type={'submit'} color={'primary'} aria-label={'add'} sx={{
                margin: 0,
                top: 'auto',
                right: 20,
                bottom: 80,
                left: 'auto',
                position: 'fixed',
                display: { sm: 'flex', md: 'none' },
            }}>
                <SaveIcon />
            </Fab>
        </form>
    </Box>
}