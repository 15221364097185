import * as React from 'react';
import ContextMenu from "./ContextMenu";
import Box from "@mui/material/Box";
import Identicon from "../Identicon";
import {Typography, useTheme} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import useAppNavigate from "../../App/useAppNavigate";

export default function Header(props) {
    const theme = useTheme();
    const [currentLeague, setCurrentLeague] = React.useState(localStorage.getItem('currentLeague'));
    const [profile] = React.useState(JSON.parse(localStorage.getItem('profile')));
    const navigate = useAppNavigate();

    const handleCurrentLeague = () => {
        localStorage.setItem('currentLeague', props.identifier);
        window.dispatchEvent(new Event('newCurrentLeague'));
        setCurrentLeague(props.identifier);
    }

    const handleClearCurrentLeague = () => {
        localStorage.removeItem('currentLeague')
        window.dispatchEvent(new Event('newCurrentLeague'));
        setCurrentLeague(null);
    }

    return <>
        <ContextMenu
            leagueIdentifier={props.identifier}
            userIdentifier={profile.userIdentifier}
            seasons={props.league.seasons}
            activeSeason={props.activeSeason}
            setActiveSeason={props.setActiveSeason}
            mates={props.league.mates}
        />
        <Box display={'flex'} alignItems={'center'} mt={1} mb={1}>
            <Identicon
                imagesDir={props.league.imagesDir}
                name={props.league.name}
                width={64}
                height={64}
                mr={0.5}
                onClick={() => navigate('/leagues/' + props.identifier)}
                sx={{cursor: 'pointer'}}
            />
            <Box>
                <Typography sx={{fontSize: '1.5rem', fontWeight: 'bold'}}>
                    {props.league.name}&nbsp;
                    {
                        props.identifier === currentLeague ?
                            <IconButton onClick={handleClearCurrentLeague} color={'primary'}><StarIcon /></IconButton> :
                            <IconButton onClick={handleCurrentLeague} color={'primary'}><StarBorderIcon /></IconButton>
                    }
                </Typography>
                <Typography cariant={'caption'} gutterBottom sx={{fontSize: '1.0rem', marginTop: -1, color: theme.palette.grey[700]}}>LIGA</Typography>
            </Box>
        </Box>
    </>;
}