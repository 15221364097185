import * as React from "react";
import HomeIcon from "@mui/icons-material/Home";
import BaseElement from "../BaseElement";
import {Typography} from "@mui/material";

export default function Welcome(props) {
    return <BaseElement
        timestamp={props.feedEntry.publishedAt}
        name={process.env.REACT_APP_NAME}
        title={'Witaj!'}
        icon={<HomeIcon color={'info'} fontSize={'small'} />}
        isSeen={props.feedEntry.isSeen}
    >
        <p>Gratulujemy rejestracji <Typography component={'span'} noWrap>w aplikacji</Typography> <Typography component={'span'} noWrap>{process.env.REACT_APP_NAME}</Typography>!
            Jesteśmy podekscytowani, <Typography component={'span'} noWrap>że będziesz</Typography> mógł korzystać z naszej platformy, aby rejestrować swoje rozgrywki <Typography component={'span'} noWrap>w prywatnej</Typography> lidze.</p>

        <p>Z naszym prostym interfejsem <Typography component={'span'} noWrap>i narzędziami</Typography>, będziesz mieć dostęp do dokładnych statystyk swoich meczy,
            <Typography component={'span'} noWrap>a także</Typography> możliwość śledzenia postępów <Typography component={'span'} noWrap>i osiągnięć</Typography> swojej drużyny.</p>

        <p>Jeśli potrzebujesz pomocy lub masz jakiekolwiek pytania, nie wahaj się skontaktować się <Typography component={'span'} noWrap>z nami.</Typography> Jesteśmy tutaj, aby Ci pomóc.</p>

        Z poważaniem,<br />Zespół {process.env.REACT_APP_NAME}.</BaseElement>
}