import * as React from "react";
import AddIcon from "@mui/icons-material/Add";
import useAppNavigate from "../App/useAppNavigate";
import FabCollapsed from "../App/FabCollapsed";

export default function Fabs() {
    const [leagues, setLeagues] = React.useState(JSON.parse(localStorage.getItem('leagues') ?? '[]'));
    const navigate = useAppNavigate();

    window.addEventListener('leaguesChanged', () => {
        setLeagues(localStorage.getItem('leagues'));
    });

    return <>
        {
            window.location.pathname !== '/games/create'
            && window.location.pathname !== '/leagues'
            && leagues.length > 0
            && window.location.pathname !== '/leagues/create'
            && window.location.pathname !== '/profile/edit'
            && window.location.pathname !== '/profile/password'
            && !window.location.pathname.includes('/invite/')
            && !(window.location.pathname.includes('/leagues/') && window.location.pathname.includes('/invite'))
            && !(window.location.pathname.includes('/leagues/') && window.location.pathname.includes('/players'))
            && !(window.location.pathname.includes('/leagues/') && window.location.pathname.includes('/edit')) ?
                <FabCollapsed icon={<AddIcon />} name={'Dodaj grę'} onClick={() => {navigate('/games/create')}} /> : ''
        }
        {
            leagues.length === 0 && window.location.pathname !== '/leagues/create' || window.location.pathname === '/leagues' ?
                <FabCollapsed icon={<AddIcon />} name={'Utwórz ligę'} onClick={() => {navigate('/leagues/create')}} /> : ''
        }
    </>
}