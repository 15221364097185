import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import moment from "moment/moment";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {useEffect} from "react";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, FormControl,
    IconButton, InputLabel, OutlinedInput,
    Typography,
    useTheme
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import CancelIcon from "@mui/icons-material/Cancel";
import LoadingButton from "@mui/lab/LoadingButton";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Stack from "@mui/material/Stack";
import AxiosPut from "../../AxiosPut";
import {useCookies} from "react-cookie";
import hasRole from "../../ContextMenu/hasRole";

export default function SeasonBrowser(props) {
    const theme = useTheme();
    const [cookies, setCookie, removeCookie] = useCookies(['accessToken']);
    const [profile] = React.useState(JSON.parse(localStorage.getItem('profile')));
    const [currentSeasonIndex, setCurrentSeasonIndex] = React.useState([]);
    const [sortedSeasons, setSortedSeasons] = React.useState([]);
    const [seasonNameChangeDialog, setSeasonNameChangeDialog] = React.useState(false);
    const [seasonName, setSeasonName] = React.useState('');
    const [seasonNameError, setSeasonNameError] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    useEffect(() => {
        setSortedSeasons(props.seasons.sort(function(a, b) {
            var dateA = new Date(a.startAt),
                dateB = new Date(b.startAt);
            if (dateA < dateB) {
                return -1;
            }

            if (dateA > dateB) {
                return 1;
            }

            return 0;
        }));
    }, [props.seasons]);

    useEffect(() => {
        setCurrentSeasonIndex(findSeasonIndex());
    }, [sortedSeasons, props.currentSeason]);

    const findSeasonIndex = () => {
        if (sortedSeasons === []) {
            return;
        }

        return sortedSeasons.findIndex((season) => season.identifier === props.currentSeason)
    }

    const calculateSeasonIndex = (offset) => {
        if (currentSeasonIndex === 0 && sortedSeasons.length === 1) {
            return 0;
        }

        return currentSeasonIndex + offset;
    }

    const handleSeasonNameChangeDialog = () => {
        setSeasonNameChangeDialog(!seasonNameChangeDialog);
    }

    const handleClose = () => {
        setSeasonNameChangeDialog(false);
        setSeasonNameError(false);
        setLoading(false);
    }

    const formatSeasonDates = () => {
        let formatedDates = '';
        if (props.season === null) {
            return formatedDates;
        }

        formatedDates = moment(props.season.startAt, 'YYYY-MM-DD HH:mm:ss')
            .utc(true)
            .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
            .format('DD.MM.YYYY') + ' - ';

        if (props.season.finishAt !== null) {
            formatedDates = formatedDates + moment(props.season.finishAt, 'YYYY-MM-DD HH:mm:ss')
                .utc(true)
                .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
                .format('DD.MM.YYYY');
        }

        return formatedDates;
    }

    const handleSeasonNameChange = (event) => {
        setSeasonName(event.target.value)
    }

    const handleConfirm = (event) => {
        event.preventDefault();
        if (seasonName === '') {
            setSeasonNameError(true);

            return;
        }

        setLoading(true);
        let payload = {
            name: seasonName,
        }

        AxiosPut('/seasons/' + event.target.value, cookies.accessToken, payload)
            .then((response) => {
                setLoading(false);
                setSeasonNameChangeDialog(false);
                setSortedSeasons((prevState) => {
                    console.log(sortedSeasons)
                    console.log(prevState)
                    let data = prevState;
                    console.log(data[currentSeasonIndex])
                    data[currentSeasonIndex].name = payload.name

                    return data;
                })
            })
            .catch((error) => {
                console.log(error);
            });
    }

    return (
        <Box mb={1} sx={{display: 'flex'}}>
            <Box sx={{flexShrink: 0}} display={'flex'} alignItems={'center'}>
                <Button
                    variant={'contained'}
                    size={'small'}
                    sx={{minWidth: 48}}
                    disabled={currentSeasonIndex === 0}
                    onClick={() => props.setCurrentSeason(sortedSeasons[calculateSeasonIndex(-1)].identifier)}
                >
                    <ChevronLeftIcon />
                </Button>
            </Box>
            <Box sx={{width: '100%', overflow: 'hidden'}} display={'flex'} alignItems={'center'} p={0.5}>
                <Box sx={{width: '100%', overflow: 'hidden'}} display={'block'}>
                <Typography sx={{fontSize: '1rem'}} display={'block'} noWrap>
                    {
                        props.season !== null &&
                        sortedSeasons !== [] &&
                        sortedSeasons[currentSeasonIndex] !== undefined &&
                        sortedSeasons[currentSeasonIndex].name !== null ?
                            sortedSeasons[currentSeasonIndex].name :
                            'Sezon ' + formatSeasonDates()
                    }
                </Typography>
                <Typography variant={'caption'} display={'block'} sx={{marginTop: -0.5, color: theme.palette.grey[700]}} noWrap>
                    SEZON {
                        props.season !== null &&
                        sortedSeasons !== [] &&
                        sortedSeasons[currentSeasonIndex] !== undefined &&
                        sortedSeasons[currentSeasonIndex].name !== null ?
                            formatSeasonDates() : ''
                    }
                </Typography>
                </Box>
            </Box>
            <Box sx={{flexShrink: 0}} display={'flex'} alignItems={'center'}>
                {
                    props.season !== null &&
                    sortedSeasons !== [] &&
                    sortedSeasons[currentSeasonIndex] !== undefined &&
                    sortedSeasons[currentSeasonIndex].name === null &&
                    hasRole(profile.userIdentifier, props.league.mates, 'ROLE_ADMIN') ?
                        <IconButton color={'primary'} onClick={handleSeasonNameChangeDialog} sx={{marginRight: 0.5}}><EditIcon /></IconButton> :
                    ''
                }
                <Button
                    variant={'contained'}
                    size={'small'}
                    sx={{minWidth: 48}}
                    disabled={currentSeasonIndex + 1 === sortedSeasons.length}
                    onClick={() => props.setCurrentSeason(sortedSeasons[calculateSeasonIndex(1)].identifier)}
                >
                    <ChevronRightIcon />
                </Button>
            </Box>
            <Dialog
                open={seasonNameChangeDialog}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Dodaj nazwę sezonu
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Sezon {formatSeasonDates()}
                    </DialogContentText>
                    <form >
                        <Stack spacing={2} sx={{marginTop: 2}}>
                            <FormControl fullWidth error={seasonNameError}>
                                <InputLabel htmlFor="outlined-adornment-amount">Nazwa sezonu</InputLabel>
                                <OutlinedInput
                                    id="outlined-adornment-amount"
                                    value={seasonName}
                                    label={'Nazwa sezonu'}
                                    onChange={handleSeasonNameChange}
                                />
                            </FormControl>
                        </Stack>
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} startIcon={<CancelIcon />}>Anuluj</Button>
                    <LoadingButton
                        loading={loading}
                        loadingPosition={'start'}
                        startIcon={<CheckCircleIcon />}
                        onClick={handleConfirm}
                        autoFocus
                        value={props.season !== null ? props.season.identifier : ''}
                    >
                        Kontynuuj
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </Box>
    );
}
