import * as React from 'react';
import {useParams} from "react-router-dom";
import QrScanner from "../../QrScanner";
import AxiosGet from "../../AxiosGet";
import {BrowserMultiFormatReader} from "@zxing/library";
import {useCookies} from "react-cookie";
import {
    Alert,
    AlertTitle,
    Fade,
    LinearProgress,
    Popover
} from "@mui/material";
import Box from "@mui/material/Box";
import AxiosPut from "../../AxiosPut";
import ConfirmationDialog from "../../App/ConfirmationDialog";
import useAppNavigate from "../../App/useAppNavigate";

export default function Connect() {
    let { identifier, playerIdentifier } = useParams();
    const [cookies, setCookie, removeCookie] = useCookies(['accessToken']);
    const reader = new BrowserMultiFormatReader();
    const [reads, setReads] = React.useState([]);
    const [readerReset, setReaderReset] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [buttonLoading, setButtonLoading] = React.useState(false);
    const [error, setError] = React.useState(false);
    const [connectAttemptSuccess, setConnectAttemptSuccess] = React.useState(false);
    const [connectAttemptSuccessData, setConnectAttemptSuccessData] = React.useState({player: '', user: ''});
    const divRef = React.useRef();
    const navigate = useAppNavigate();

    React.useEffect(() => {
        if (reads.length > 0) {
            setLoading(true);
            if (reads[0].type !== 'PROFILE') {
                setError(true);

                return;
            }

            AxiosGet(
                '/players/connect',
                cookies.accessToken,
                {
                    leagueIdentifier: identifier,
                    playerIdentifier: playerIdentifier,
                    userIdentifier: reads[0].identifier,
                }
            )
            .then((response) => {
                if (response.data.status !== 'ok') {
                    setError(true);

                    return;
                }

                setConnectAttemptSuccessData(response.data);
                setConnectAttemptSuccess(true);
            })
            .catch((response) => {
                setError(true);
            });
        }
    }, [reads]);

    React.useEffect(() => {
        window.dispatchEvent(new Event('contentLoaded'));
    })

    const handleContinueConnecting = async () => {
        let payload = {
            leagueIdentifier: identifier,
            playerIdentifier: playerIdentifier,
            userIdentifier: reads[0].identifier,
        };
        setButtonLoading(true);
        let response;
        response = await AxiosPut('/players/connect', cookies.accessToken, payload)
            .catch((response) => {
                setConnectAttemptSuccess(false);
                setButtonLoading(true);
                setError(true);
            });
        if (response.data.status === 'ok') {
            reader.reset();
            setButtonLoading(false);
            navigate('/leagues/' + identifier);
        }
    }

    const handleClose = () => {
        setError(false);
        setLoading(false);
        setReads([]);
        setReaderReset(true);
        setConnectAttemptSuccess(false);
    }

    React.useEffect(() => {
        return () => {
            reader.reset();
        }
    }, [window.location]);

    return <Box ref={divRef}>
        <ConfirmationDialog
            open={connectAttemptSuccess}
            onClose={handleClose}
            title={'Wiązanie konta'}
            content={'Czy napewno powiązać profil gracza ' + connectAttemptSuccessData.player + ' z kontem ' + connectAttemptSuccessData.user +'?'}
            loading={buttonLoading}
            onConfirm={handleContinueConnecting}
            value={''}
        />
        <Popover
            anchorEl={error}
            anchorOrigin={{
                vertical: 'center',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'center',
                horizontal: 'center',
            }}
            open={error}
        >
            <Alert onClose={handleClose} severity="error">
                <AlertTitle>Błąd.</AlertTitle>
                Wystąpił błąd.
            </Alert>
        </Popover>
        <h1>Łączenie</h1>
        <QrScanner reader={reader} allowNext={false} {...{reads, setReads, readerReset, setReaderReset}} />
        <Box sx={{ height: 40 }}>
            <Fade
                in={loading}
                style={{
                    transitionDelay: loading ? '800ms' : '0ms',
                }}
                unmountOnExit
            >
                <LinearProgress />
            </Fade>
        </Box>
    </Box>
}