import * as React from 'react';
import QRCode from "react-qr-code";
import Stack from "@mui/material/Stack";
import {Box, FormControl, InputLabel, OutlinedInput} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import ContextMenu from "./Profile/ContextMenu";

export default function Profile() {
    const [profile] = React.useState(JSON.parse(localStorage.getItem('profile')));
    let qrCodeValue = JSON.stringify({
        type: 'PROFILE',
        identifier: profile.userIdentifier,
    });

    React.useEffect(() => {
        window.dispatchEvent(new Event('contentLoaded'));
    })

    return <Box sx={{position: 'relative'}}>
        <h1>Profil</h1>
        <ContextMenu />
        <Stack spacing={2}>
            <Grid container justifyContent="center">
                <QRCode value={qrCodeValue} />
            </Grid>
            <FormControl fullWidth>
                <InputLabel htmlFor="outlined-adornment-amount">E-mail</InputLabel>
                <OutlinedInput
                    id="outlined-adornment-amount"
                    value={profile.userEmail}
                    label="E-mail"
                    disabled={true}
                />
            </FormControl>
            <FormControl fullWidth>
                <InputLabel htmlFor="outlined-adornment-amount">Nazwa użytkownika</InputLabel>
                <OutlinedInput
                    id="outlined-adornment-amount"
                    value={profile.userName}
                    label="Nazwa użytkownika"
                    disabled={true}
                />
            </FormControl>
        </Stack>
    </Box>
}