import axios from "axios";

export default function AxiosPut(endpoint, accessToken, payload) {
    let requestConfig;
    requestConfig = {
        headers: {
            'X-AUTH-TOKEN': accessToken
        }
    };

    return axios.put(process.env.REACT_APP_API_URL + endpoint, payload, requestConfig);
}