import * as React from 'react';
import {
    Box,
    Button,
    Dialog, DialogActions,
    DialogContent, DialogContentText,
    DialogTitle,
    Fab,
    FormControl,
    InputLabel,
    OutlinedInput
} from "@mui/material";
import {useParams} from "react-router-dom";
import AxiosGet from "../AxiosGet";
import {useCookies} from "react-cookie";
import Stack from "@mui/material/Stack";
import basicColors from "../colors";
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import AxiosPut from "../AxiosPut";
import useAppNavigate from "../App/useAppNavigate";

export default function Invite() {
    let { hash } = useParams();
    const [profile] = React.useState(JSON.parse(localStorage.getItem('profile')));
    const [cookies, setCookie, removeCookie] = useCookies(['accessToken']);
    const [type, setType] = React.useState('');
    const [target, setTarget] = React.useState({});
    const [targetName, setTargetName] = React.useState('');
    const [name, setName] = React.useState(profile.userName);
    const [nameError, setNameError] = React.useState(false);
    const [colors] = React.useState(basicColors());
    const [inviteError, setInviteError] = React.useState(false);
    const [inviteErrorMessage, setInviteErrorMessage] = React.useState('');
    const navigate = useAppNavigate();

    React.useEffect(() => {
        AxiosGet('/invite/' + hash, cookies.accessToken).then((response) => {
            setType(response.data.type);
            setTarget(response.data.target);
            setTargetName(response.data.target.name);
        }).catch((error) => {
            if (error.response.status === 404) {
                setInviteError(true);
                setInviteErrorMessage('Nie znaleziono zaproszenia.');
            }
        });
    }, [])

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (name === '') {
            setNameError(true);

            return;
        }

        let playerColor = '';
        if (target.players >= colors.length) {
            playerColor = colors[Math.floor(Math.random() * colors.length)];
        } else {
            playerColor = colors[target.players];
        }

        let payload = {
            inviteString: hash,
            name: name,
            color: playerColor,
        }

        let response;
        response = await AxiosPut('/invite', cookies.accessToken, payload)
            .catch((error) => {
                if (error.response.status === 400) {
                    setInviteErrorMessage(error.response.data.message);
                    setInviteError(true);

                    return;
                }

                setInviteErrorMessage('Nieznany błąd');
                setInviteError(true);
            });
        if (response.data.status === 'ok') {
            AxiosGet('/leagues', cookies.accessToken).then((response) => {
                localStorage.setItem('leagues', JSON.stringify(response.data));
                window.dispatchEvent(new Event('leaguesChanged'));
            }).catch((error) => {
                if (error.response.status === 401) {
                    removeCookie('accessToken', {path: '/'});
                }
            });
            navigate('/leagues/' + target.identifier);
        }
    }

    const handleClose = () => {
        setInviteError(false);
        window.location.href = '/';
    }

    const handleNameChange = (event) => {
        setName(event.target.value);
        setNameError(false);
    }

    React.useEffect(() => {
        window.dispatchEvent(new Event('contentLoaded'));
    })

    return <Box>
        <h1>Zaproszenie</h1>
        <Dialog
            open={inviteError}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {"Błąd."}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {inviteErrorMessage}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} autoFocus>
                    Powrót
                </Button>
            </DialogActions>
        </Dialog>
        <form onSubmit={handleSubmit}>
            <Stack spacing={2}>
                <FormControl fullWidth>
                    <InputLabel htmlFor="outlined-adornment-amount">Liga</InputLabel>
                    <OutlinedInput
                        id="outlined-adornment-amount"
                        value={targetName}
                        label={'Liga'}
                        disabled={true}
                    />
                </FormControl>
                <FormControl fullWidth error={nameError}>
                    <InputLabel htmlFor="name">Twoja nazwa</InputLabel>
                    <OutlinedInput
                        id="name"
                        value={name}
                        label="Twoja nazwa"
                        onChange={handleNameChange}
                    />
                </FormControl>
                <Button
                    type={'submit'}
                    variant={'outlined'}
                    size={'large'}
                    startIcon={<CheckIcon />}
                >Dołącz</Button>
                <Button
                    variant={'outlined'}
                    size={'large'}
                    startIcon={<CloseIcon />}
                    onClick={() => {window.location.href = '/'}}
                    color={'error'}
                >Anuluj</Button>
            </Stack>
            <Fab type={'submit'} color={'primary'} aria-label={'add'} sx={{
                margin: 0,
                top: 'auto',
                right: 20,
                bottom: 80,
                left: 'auto',
                position: 'fixed',
                display: { sm: 'flex', md: 'none' },
            }}>
                <CheckIcon />
            </Fab>
        </form>
    </Box>
}