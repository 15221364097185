import * as React from 'react';
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import {Typography, useTheme} from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import useAppNavigate from "../../App/useAppNavigate";
import Box from "@mui/material/Box";
import Identicon from "../Identicon";

export default function Item(props) {
    const theme = useTheme();
    const navigate = useAppNavigate();
    return <Paper
        variant={'outlined'}
        onClick={() => navigate('/leagues/' + props.league.identifier)}
        sx={{
            p: 0.5,
            ...(props.league.index%2 && {backgroundColor: theme.palette.grey[100]}),
            cursor: 'pointer',
        }}
    >
        <Grid container rowSpacing={0.5} sx={{}}>
            <Grid item xs={10}>
                <Box display={'flex'} alignItems={'center'}>
                    <Identicon imagesDir={props.league.imagesDir} name={props.league.name} width={32} height={32} mr={0.5} />
                    <Box sx={{height: 44}}>
                        <Typography variant="h6" display="block" noWrap>
                            {props.league.name}
                        </Typography>
                        <Typography variant="caption" display="block" gutterBottom sx={{marginTop: -0.5, color: theme.palette.grey[700]}}>
                            LIGA
                        </Typography>
                    </Box>
                </Box>
            </Grid>
            <Grid item xs={2}>
                <Typography variant="body1" display="block" align={'right'}>
                    {props.league.isStarred ? <StarIcon color={'primary'} />: ''}
                </Typography>
            </Grid>
            <Grid item xs={4}>
                <Typography variant="body1" display="block">
                    {props.league.playersCount}
                </Typography>
                <Typography variant="caption" display="block" gutterBottom sx={{color: theme.palette.grey[700]}}>
                    GRACZE
                </Typography>
            </Grid>
            <Grid item xs={4}>
                <Typography variant="body1" display="block">
                    {props.league.seasonsCount}
                </Typography>
                <Typography variant="caption" display="block" gutterBottom sx={{color: theme.palette.grey[700]}}>
                    SEZONY
                </Typography>
            </Grid>
            {props.league.currentTop !== '' ?
                <Grid item xs={4}>
                    <Typography variant="body1" display="block" noWrap>
                        {props.league.currentTop}
                    </Typography>
                    <Typography variant="caption" display="block" gutterBottom sx={{color: theme.palette.grey[700]}}>
                        TOP 1
                    </Typography>
                </Grid> : ''
            }
        </Grid>
    </Paper>
}