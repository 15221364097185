import * as React from "react";
import {styled} from '@mui/material/styles';
import Fab from '@mui/material/Fab';
import {Collapse, Fade} from "@mui/material";

const StyledFab = styled(Fab)(() => ({
    margin: 0,
    top: 'auto',
    right: 20,
    bottom: 80,
    left: 'auto',
    position: 'fixed',
    height: 56,
}));

export default function FabCollapsed(props) {
    const [open, setOpen] = React.useState(false);
    const [extendedOpen, setExtendedOpen] = React.useState(true);
    const [collapseOpen, setCollapseOpen] = React.useState(false);
    const [lastScrollTop, setLastScrollTop] = React.useState(0);

    React.useEffect(() => {
        function handleScroll() {
            const currentScrollTop = window.scrollY;

            if (currentScrollTop > lastScrollTop) {
                setTimeout(() => setExtendedOpen(false), 50);
                setOpen(true);
                setCollapseOpen(true);
            } else {
                setTimeout(() => setOpen(false), 50);
                setExtendedOpen(true);
                setTimeout(() => setCollapseOpen(false), 100);
            }

            setLastScrollTop(currentScrollTop);
        }

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [lastScrollTop]);

    return (
        <>
            <Fade in={extendedOpen} mountOnEnter unmountOnExit>
                <StyledFab
                    color={'primary'}
                    aria-label={'add'}
                    onClick={props.onClick}
                    variant={'extended'}
                >
                    {props.icon}<Collapse orientation={'horizontal'} in={!collapseOpen} sx={{whiteSpace: 'nowrap',}}
                                          timeout={{
                                              appear: 20000,
                                              enter: 120,
                                              exit: 120
                                          }}>&nbsp;{props.name}</Collapse>
                </StyledFab>
            </Fade>
            <Fade in={open} mountOnEnter unmountOnExit>
                <StyledFab
                    color={'primary'}
                    aria-label={'add'}
                    onClick={props.onClick}
                >
                    {props.icon}
                </StyledFab>
            </Fade>
        </>
    );
}