import * as React from "react";
import {IconButton, Menu, MenuList, Popover} from "@mui/material";
import Paper from "@mui/material/Paper";
import MenuIcon from '@mui/icons-material/Menu';

export default function ContextMenu(props) {
    const menuRef = React.useRef();

    return <>
        <IconButton color="primary" component="label" sx={{position: 'absolute', top: '0px', right: '0px'}} ref={menuRef} onClick={props.handleContextMenu}>
            <MenuIcon />
        </IconButton>
        <Popover
            anchorEl={props.contextMenu}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            onClose={props.handleClose}
            open={Boolean(props.contextMenu)}
        >
            <Paper sx={{ width: 250, maxWidth: '100%' }}>
                <MenuList>
                    {props.children}
                </MenuList>
            </Paper>
        </Popover>
    </>
}