import * as React from "react";
import BarChartIcon from "@mui/icons-material/BarChart";
import BaseElement from "../../../BaseElement";
import {Typography} from "@mui/material";

export default function Ties(props) {
    const player = props.league.players.find((player) => player.identifier === props.feedEntry.payload.playerIdentifier);

    return <BaseElement
        timestamp={props.feedEntry.publishedAt}
        name={props.league.name}
        icon={<BarChartIcon color={'info'} fontSize={'small'} />}
        isSeen={props.feedEntry.isSeen}
    >{player.name} zremisował(a) <Typography variant={'inherit'} display={'inline'} color={'info.main'}>{props.feedEntry.payload.gamesCount}.</Typography> grę.</BaseElement>
}