import * as React from 'react';
import {Box, Button, Fab, FormControl, InputLabel, OutlinedInput} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import AxiosPut from "../../AxiosPut";
import {useCookies} from "react-cookie";
import Stack from "@mui/material/Stack";
import useAppNavigate from "../../App/useAppNavigate";

export default function Edit() {
    const [profile] = React.useState(JSON.parse(localStorage.getItem('profile')));
    const [cookies, setCookie, removeCookie] = useCookies(['accessToken']);
    const [userName, setUserName] = React.useState(profile.userName);
    const [userNameError, setUserNameError] = React.useState(false);
    const navigate = useAppNavigate();

    const handleNameChange = (event) => {
        setUserName(event.target.value);
        setUserNameError(false);
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (userName === '') {
            setUserNameError(true);
        }

        if (userNameError) {
            return;
        }

        let payload = {
            name: userName,
        }

        window.dispatchEvent(new Event('contentLoading'));
        AxiosPut('/profile', cookies.accessToken, payload)
            .then((response) => {
                profile.userName = userName;
                localStorage.setItem('profile', JSON.stringify(profile));
                navigate('/profile');
            }).catch((error) => {
                if (error.response.status === 401) {
                    removeCookie('accessToken', {path: '/'});
                }
            });
    }

    React.useEffect(() => {
        window.dispatchEvent(new Event('contentLoaded'));
    })

    return <Box>
        <h1>Profil - edytuj</h1>
        <form onSubmit={handleSubmit}>
            <Stack spacing={2}>
                <FormControl fullWidth>
                    <InputLabel htmlFor="outlined-adornment-amount">Nazwa użytkownika</InputLabel>
                    <OutlinedInput
                        id="outlined-adornment-amount"
                        value={userName}
                        label="Nazwa użytkownika"
                        onChange={handleNameChange}
                    />
                </FormControl>
                <Button
                    type={'submit'}
                    variant={'outlined'}
                    sx={{display: { xs: 'none', sm: 'none', md: 'flex' },}}
                    size={'large'}
                    startIcon={<SaveIcon />}
                    disabled={profile.userName === userName}
                >Zapisz profil</Button>
            </Stack>
            <Fab type={'submit'} color={'primary'} aria-label={'add'} sx={{
                margin: 0,
                top: 'auto',
                right: 20,
                bottom: 80,
                left: 'auto',
                position: 'fixed',
                display: { sm: 'flex', md: 'none' },
            }}
                 disabled={profile.userName === userName}>
                <SaveIcon />
            </Fab>
        </form>
    </Box>
}