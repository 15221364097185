import * as React from "react";
import {default as BaseContextMenu} from "../../App/ContextMenu";
import MenuItem from "@mui/material/MenuItem";
import {Link, ListItemText} from "@mui/material";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import useAppNavigate from "../../App/useAppNavigate";

export default function ContextMenu(props) {
    const [contextMenu, setContextMenu] = React.useState(null);
    const navigate = useAppNavigate();

    const handleClose = () => {
        setContextMenu(null);
    }

    const handleContextMenu = (event) => {
        if (contextMenu === null) {
            setContextMenu(event.currentTarget);

            return;
        }

        setContextMenu(null);
    }

    return <BaseContextMenu
        contextMenu={contextMenu}
        handleClose={handleClose}
        handleContextMenu={handleContextMenu}
    >
        <MenuItem component={Link} onClick={() => {navigate('/leagues/create')}}>
            <EmojiEventsIcon />
            <ListItemText>&nbsp;Utwórz ligę</ListItemText>
        </MenuItem>
    </BaseContextMenu>
}