import * as React from 'react';
import {Box, Button, ButtonGroup, Typography} from "@mui/material";
import Games from "./RankingChart/Games";
import Daily from "./RankingChart/Daily";
import {CategoryScale, Chart, Legend, LinearScale, LineElement, PointElement, Title, Tooltip} from "chart.js";

export default function RankingChart(props) {
    const [chartVisible, setChartVisible] = React.useState(0);
    Chart.register(
        CategoryScale,
        LinearScale,
        PointElement,
        LineElement,
        Title,
        Tooltip,
        Legend
    );

    const handleDailyVisible = (event) => {
        event.preventDefault();
        setChartVisible(0)
    }

    const handleGameVisible = (event) => {
        event.preventDefault();
        setChartVisible(1)
    }

    return <Box mb={1}>
        <Box display={'flex'}>
            <Box display={'flex'} sx={{width: '100%'}}>
                <Typography sx={{fontSize: '1.25rem', fontWeight: 'bold'}}>Wykres</Typography>
            </Box>
            <Box display={'flex'} alignItems={'center'} justifyContent={'flex-end'}>
                <ButtonGroup size={'small'}>
                    <Button variant={chartVisible === 0 ? 'contained' : 'outlined'} onClick={handleDailyVisible}>Dzienny</Button>
                    <Button variant={chartVisible === 1 ? 'contained' : 'outlined'} onClick={handleGameVisible}>Gier</Button>
                </ButtonGroup>
            </Box>
        </Box>
        <Box sx={{display: chartVisible === 0 ? 'block' : 'none'}}>
            <Daily league={props.league} seasonChartStatistics={props.seasonChartStatistics} />
        </Box>
        <Box sx={{display: chartVisible === 1 ? 'block' : 'none'}}>
            <Games league={props.league} seasonChartStatistics={props.seasonChartStatistics} />
        </Box>
    </Box>
}