const basicColors = () => {
    return [
        '#0693E3',
        '#FF6900',
        '#FCB900',
        '#7BDCB5',
        '#00D084',
        '#8ED1FC',
        '#ABB8C3',
        '#EB144C',
        '#F78DA7',
        '#9900EF'
    ];
}

export default basicColors;