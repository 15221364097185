import * as React from "react";
import {default as BaseContextMenu} from "../../App/ContextMenu";
import MenuItem from "@mui/material/MenuItem";
import {Link, ListItemText} from "@mui/material";
import PasswordIcon from '@mui/icons-material/Password';
import EditIcon from '@mui/icons-material/Edit';
import useAppNavigate from "../../App/useAppNavigate";

export default function ContextMenu() {
    const [contextMenu, setContextMenu] = React.useState(null);
    const navigate = useAppNavigate();

    const handleClose = () => {
        setContextMenu(null);
    }

    const handleContextMenu = (event) => {
        if (contextMenu === null) {
            setContextMenu(event.currentTarget);

            return;
        }

        setContextMenu(null);
    }

    return <BaseContextMenu
        contextMenu={contextMenu}
        handleClose={handleClose}
        handleContextMenu={handleContextMenu}
    >
        <MenuItem component={Link} onClick={() => {
            handleClose();
            navigate('/profile/edit')
        }}>
            <EditIcon />
            <ListItemText>&nbsp;Edytuj profil</ListItemText>
        </MenuItem>
        <MenuItem component={Link} onClick={() => {
            handleClose();
            navigate('/profile/password');
        }}>
            <PasswordIcon />
            <ListItemText>&nbsp;Zmień hasło</ListItemText>
        </MenuItem>
    </BaseContextMenu>
}